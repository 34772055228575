import React, { useState, useEffect, useRef } from "react";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { toast } from "sonner";
import { useCookies } from "react-cookie";
import lottie from "lottie-web";

function extractIdsFromURL(url) {
  // DecodeUrl (convert special characters)
  const decodedURL = decodeURIComponent(url);

  // Get project and entityId directly from URL
  const projectIdMatch = decodedURL.match(/projects\/([^\/\?&]+)/);
  const entityIdMatch = decodedURL.match(/[?&]entityId=([^&]+)/);
  var projectId = null;
  var entityId = null;

  if (projectIdMatch && entityIdMatch) {
    projectId = "b." + projectIdMatch[1];
    entityId = entityIdMatch[1];
  }

  return { projectId, entityId };
}

const ConfirmModelUpdate = ({
  modelURL,
  modelID,
  modelYear,
  record,
  historyID,
  setshowAcceptDesingWindow,
  refreshTable
}) => {
  const [actionResult, setActionResult] = useState(null); // Estado para el resultado de la acción
  // const { apsAccessToken } = useApsRefreshToken();
  const api = useAxiosPrivate();
  const [cookies] = useCookies(["access_token"]);
  const elements = record.map((item) => ({
    elementId: item.elementId,
    parameters: item.parameters,
  }));

  const lottieContainer = useRef(null);
  const lottieInstance = useRef(null); // Nueva referencia para la instancia de Lottie

  useEffect(() => {
    // Cargar la animación de Lottie cuando el componente se monta
    lottieInstance.current = lottie.loadAnimation({
      container: lottieContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: false,
      path: "https://lottie.host/75a81f97-6839-47f7-8afd-ed9cd1136ec9/wH0XrFuw8j.json",
    });

    // Controlar la reproducción de la animación
    if (actionResult === "inProgress") {
      lottieInstance.current.play();
    } else {
      lottieInstance.current.stop();
    }
    return () => {
      // Limpiar la instancia de Lottie cuando el componente se desmonta
      lottieInstance.current.destroy();
    };
  }, [actionResult]);

  const handleYesClick = async () => {
    try {
      setActionResult("inProgress");
      lottieInstance.current.play();

      // Obtener el token de acceso
      const token_response = await api.get(`/aps/token`, {
        headers: { authorization: cookies.access_token },
      });

      var apsAccessToken = token_response.data.access_token;

      // Obtener los GUIDs
      const guidsResponse = await api.post(
        `${process.env.REACT_APP_BASE_URL}/aps/getGuids`,
        {
          projectId: extractIdsFromURL(modelURL).projectId,
          accessToken: apsAccessToken,
          urn: extractIdsFromURL(modelURL).entityId,
        }
      );

      const { modelGuid, projectGuid } = guidsResponse.data.data;

      // Preparar el objeto JSON para la solicitud
      const jsonObject = {
        modelData: {
          modelGuid: modelGuid,
          projectGuid: projectGuid,
          adskToken: cookies.aps_token.token,
          projectId: extractIdsFromURL(modelURL).projectId,
          itemId: extractIdsFromURL(modelURL).entityId,
        },
        elements: elements,
      };

      // Enviar los datos para generar el trabajo
      const response = await api.post(
        `${process.env.REACT_APP_BASE_URL}/aps/generateWorkItem`,
        {
          accessToken: apsAccessToken,
          data: {
            activityId: `modParams.ModParamsActivity${modelYear}+paramAct`,
            arguments: {
              params: {
                url: `data:application/json,${JSON.stringify(jsonObject)}`,
              },
              adsk3LeggedToken: cookies.aps_token.token,
              onComplete: {
                verb: "post",
                url: `${process.env.REACT_APP_BASE_URL}/aps/completeWorkItem/${modelID}/${historyID}`,
              },
            },
          },
          headers: {
            authorization: cookies.access_token,
          },
        }
      );

      // Actualizar el historial
      let status = "inprogress";
      console.log({ historyID, modelID });
      const historyResponse = await api.post(
        `${process.env.REACT_APP_BASE_URL}/history/updateHistoryParams`,
        {
          modelID,
          historyID,
          status,
        }
      );

      console.log({ workItemResponse: response, historyResponse });
      console.log("Sending new model data:", { modelURL, record, historyID });

      setTimeout(() => {
        lottieInstance.current.destroy();
        setActionResult("success");
        setshowAcceptDesingWindow(false);
      }, 2200);

      toast.success('Success!')
      refreshTable()

    } catch (error) {
      toast.error("This didn't work.")

      console.error("Error en handleYesClick:", error);
      setActionResult("error");
    }
  };

  const handleNoClick = () => {
    setActionResult("cancelled"); // Agrego un estado para manejar el caso de cancelación si es necesario.
    setshowAcceptDesingWindow(false)
  };

  return (
    <div className="flex h-full bg-white">
      <div className="h-full w-full flex items-center justify-center">
        {actionResult === "inProgress" ? (
          <div className="h-64 w-full" ref={lottieContainer}></div>
        ) : actionResult === "success" ? (
          <div className="bg-[#00ff82]  h-32   w-full p-4">
            <p className="text-center text-xl font-semibold mb-4 text-[#104cf8] mt-7">
              Successful update!
            </p>
          </div>
        ) : actionResult === "error" ? (
          <div className="bg-[#ED494A]  h-26   w-full p-4 animate-pulse	">
            <p className="text-center text-4xl font-semibold mb-4 text-white mt-7 ">
              ERROR!
            </p>
          </div>
        ) : (
          <div className="bg-white p-4  ">
            <p className="text-center text-3xl font-semibold mb-4">Do you want to send this survey?
 </p>
            <div className="flex justify-center gap-4">
              <button
                className="w-1/2 h-12 px-4 py-1 font-bold bg-[#084BF1] hover:bg-blue-600 rounded-md text-[#00ff82]"
                onClick={handleYesClick}
              >
                Upgrade
              </button>
              <button
                className="w-1/2 h-12 px-4 py-1 border-2 border-[#21FD80] hover:bg-[#21FD80] rounded-md text-black"
                onClick={handleNoClick}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ConfirmModelUpdate;
