import React, { useState, useEffect } from "react";
import { BadgeDelta, Title, ProgressCircle } from "@tremor/react";

const ElectricalStateChart = ({ data, zones }) => {
  const [zoneData, setZoneData] = useState([]);
  const [currentElectricalState, setCurrentElectricalState] = useState(0);
  const [deltaType, setDeltaType] = useState(0); // Porcentaje de aumento
  // Obtener el año actual
  const currentYear = new Date().getFullYear();
  useEffect(() => {
    let filteredData = data;

    if (zones.length > 0) {
      filteredData = data.filter((item) => zones.includes(item.zoneID));
    }

    const chartData = [];
    for (let month = 1; month <= 12; month++) {
      const electricalStates = filteredData.map((item) => {
        const yearData = item.historyState[currentYear.toString()];
        const monthData = yearData ? yearData[`${month}`] : null;
                return monthData ? monthData["ElectricalState"] : null;
      });
      const filteredStates = electricalStates.filter((value) => value !== null);
      const sum = filteredStates.reduce((acc, val) => acc + val, 0);

      const averageElectricalState =
        electricalStates.reduce((acc, val) => acc + val, 0) ? sum/filteredStates.length : 0;

      // Comprobar si averageElectricalState es NaN y asignar un valor predeterminado
      if (isNaN(averageElectricalState)) {
        chartData.push({
          month: `${month}`,
          electricalState: 0, // Valor predeterminado si es NaN
        });
      } else {
        chartData.push({
          month: `${month}`,
          electricalState: averageElectricalState * 100,
        });
      }
    }

    setZoneData(chartData);

    const currentMonth = new Date().getMonth() + 1;
    const currentMonthData = chartData.find(
      (item) => item.month === currentMonth.toString()
    );
    if (currentMonthData) {
      setCurrentElectricalState(currentMonthData.electricalState.toFixed());
    }

    // Calcular el porcentaje de aumento
    const prevMonth = currentMonth === 1 ? 12 : currentMonth - 1; // Mes anterior
    const prevMonthData = chartData.find((item) => item.month === prevMonth.toString());
    if (prevMonthData) {
      const delta = currentMonthData.electricalState - prevMonthData.electricalState;
      setDeltaType(delta.toFixed());
    }
  }, [data, zones]);

  return (
    <div className=" h-fit w-full z-10 m-1">
    <div class="shadow border-2 border-blue-700 bg-white rounded-xl   h-fit w-full ">
    <div className="  p-1 h-44">
        <div className="flex flex-row justify-center items-center">
          <Title className="ml-2">Electrical</Title>
          <div className={`p-1 bg-yellow`}>
            <BadgeDelta>{deltaType}%</BadgeDelta>
          </div>
        </div>
        <ProgressCircle
          value={parseFloat(currentElectricalState)}
          size="lg"
          strokeWidth="12"
          className="mt-1"
          color="yellow"
          showAnimation={true}
        >
          <span className="dark:text-white text-2xl">
            {currentElectricalState}%
          </span>
        </ProgressCircle>
      </div>
    </div>
    </div>
  );
};

export default ElectricalStateChart;
