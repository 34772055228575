import { useContext, useDebugValue, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import AuthContext from "../context/AuthProvider";

const useAuth = () => {
    const { auth } = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();

    useDebugValue(auth, auth => auth?.name ? "Logged In" : "Logged Out");

    const [shouldRedirect, setShouldRedirect] = useState(false);

    useEffect(() => {
        let timeout;

        if (!auth?.name && (location.pathname !== "/" && location.pathname !== "/register" && location.pathname !== "/callback") ) {
            // Si el usuario no está autenticado y no está en la página de inicio, establecemos shouldRedirect en true después de 2 segundos
            timeout = setTimeout(() => {
                setShouldRedirect(true);
            }, 7000); // 2000 milisegundos = 2 segundos
        }

        return () => {
            // Limpiamos el timeout si el componente se desmonta antes de que se cumplan los 2 segundos
            clearTimeout(timeout);
        };
    }, [auth, location.pathname]);

    useEffect(() => {
        if (shouldRedirect) {
            navigate("/");
        }
    }, [shouldRedirect, navigate]);

    return useContext(AuthContext);
}

export default useAuth;
