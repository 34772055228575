import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import ConfirmModelUpdate from "./ConfirmModelUpdate";
import ConfirmModelDelete from "./ConfirmModelDelete";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import Lightbox from "yet-another-react-lightbox";
import { toast } from "sonner";
import "yet-another-react-lightbox/styles.css";
import Rating from "react-rating-stars-component";
import { Badge } from "@tremor/react";
import useImageLoader from '../../../../hooks/useImageLoader'; // Asegúrate de ajustar la ruta al lugar correcto


function getStatusMessage(status, filterselected) {
  let message;
  switch (filterselected) {
    case "pending":
      message = "Pending";
      break;
    case "approved":
      message = "Approved";
      break;
    case "failed":
      message = "Error processing";
      break;
    case "inprogress":
      message = "Processing";
      break;
    case "canceled":
      message = "Canceled";
      break;
    default:
      message = "Error";
      break;
  }

  return status === filterselected ? message : "Error";
}
function getBadgeColor(status) {
  switch(status) {
      case 'Canceled':
          return 'red';
      case 'Pending':
          return 'orange';
      case 'Processing':
          return 'blue';
      case 'Approved':
          return 'green';
      default:
          return 'gray'; // Un color por defecto
  }
}

const TableRow = ({
  user,
  date,
  record,
  historyID,
  status,
  modelURL,
  modelID,
  modelYear,
  filterselected,
  refreshTable,
}) => {
  const [showRecordDetails, setShowRecordDetails] = useState(false);
  const [formattedDate, setFormattedDate] = useState("");
  const api = useAxiosPrivate();
  const [cookies] = useCookies(["access_token"]);

  const [showAcceptDesingWindow, setshowAcceptDesingWindow] = useState(false); // Agrega un estado para controlar la apertura del contenido personalizado
  const [showDeleteDesingWindow, setshowDeleteDesingWindow] = useState(false); // Agrega un estado para controlar la apertura del contenido personalizado

  // Manejar imageURLs
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  const elementImageURLs = useImageLoader(record);
  

  const handleToggleDetails = () => {
    setShowRecordDetails(!showRecordDetails);
  };

  const fixedColumns = [
    { label: "Category", param: "category" },
    { label: "Condition", param: "ESTADO" },
    { label: "Comments", param: "Comments" },
    { label: "Image", param: "ImageURL" },
  ];

  const handleAccept = (rowData) => {
    console.log(cookies.aps_token);
    if (cookies.aps_token != undefined) {
      if (showDeleteDesingWindow) {
        setshowDeleteDesingWindow(false);
      }
      setshowAcceptDesingWindow(true); // Cuando se aprueba, muestra el contenido personalizado
    } else {
      toast.error("You need to log in with your Autodesk account.");
    }
    // Verifica si el token existe
    //console.log("Approved:", rowData);
  };

  const handleCancel = (rowData) => {
    //console.log("Cancelled:", rowData);
    if (showAcceptDesingWindow) {
      setshowAcceptDesingWindow(false);
      setshowDeleteDesingWindow(true); // Cuando se aprueba, cierra el contenido personalizado
    } else if (showDeleteDesingWindow) {
      setshowDeleteDesingWindow(false); // Cuando se aprueba, cierra el contenido personalizado
    } else {
      setshowDeleteDesingWindow(true); // Cuando se aprueba, cierra el contenido personalizado
    }
  };

  useEffect(() => {
    // Función para formatear la fecha en "hace cuanto fue"
    const formatRelativeDate = (inputDate) => {
      const currentDate = new Date();
      const diffInMilliseconds = currentDate - new Date(inputDate);
      const secondsAgo = Math.floor(diffInMilliseconds / 1000);

      if (secondsAgo < 60) {
        return `${secondsAgo} second(s) ago`;
      } else if (secondsAgo < 3600) {
        const minutesAgo = Math.floor(secondsAgo / 60);
        return `${minutesAgo} minute(s) ago`;
      } else if (secondsAgo < 86400) {
        const hoursAgo = Math.floor(secondsAgo / 3600);
        return `${hoursAgo} hour(s) ago`;
      } else {
        const daysAgo = Math.floor(secondsAgo / 86400);
        return `${daysAgo} day(s) ago`;
      }
    };

    setFormattedDate(formatRelativeDate(date));
  }, [date]);

  const openLightbox = (imageUrl) => {
    setCurrentImage(imageUrl);
    setLightboxOpen(true);
  };
  console.log(record)
  return (
    <>
      {status === filterselected && (
        <tr className="hover:bg-[#a4baef] dark:hover:bg-gray-900 transition duration-300 text-black text-center bg-white">
          <td className="text-sm">    <Badge size="md" color="fuchsia">
{user}</Badge></td>
          <td className="text-sm">{formattedDate}</td>
          <td className=" ">
            <div
              className=" bg-white rounded-xl border border-zinc-950 flex  w-22 md:w-32 h-6 cursor-pointer"
              onClick={handleToggleDetails}
            >
              {showRecordDetails ? (
                <div className="flex flex-row w-full item-center justify-between ">
                  <div className="text-xs md:text-sm flex mt-1  md:mt-0 ml-5 md:ml-10">
                    Hide{" "}
                  </div>
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.0001 6.375L8.59009 7.785L13.1701 12.375L8.59009 16.965L10.0001 18.375L16.0001 12.375L10.0001 6.375Z"
                      fill="black"
                    />
                  </svg>
                </div>
              ) : (
                <div className="flex flex-row w-full item-center justify-between bg-gray-100 rounded-xl">
                  <div className="flex flex-row mt-1 md:mt-0">
                    <div className="flex text-xs md:text-sm ml-1 md:ml-6">
                      {record ? record.length : 0}{" "}
                      {/* Verifica si record está definido */}
                    </div>
                    <div className="ml-1 flex text-xs md:text-sm ">
                      {" "}
                      element {record && record.length !== 1 ? "s" : ""}
                    </div>
                  </div>
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18 10.08L16.59 8.67004L12 13.25L7.41 8.67004L6 10.08L12 16.08L18 10.08Z"
                      fill="black"
                    />
                  </svg>
                </div>
              )}
            </div>
          </td>
          <td className="">
    <p>
        <Badge color={getBadgeColor(getStatusMessage(status, filterselected))}>
            {getStatusMessage(status, filterselected)}
        </Badge>
    </p>
</td>

          <td className=" ">
            <div className="flex flex-row h-fit p-1 w-full items-center justify-center">
              {status !== "approved" && status !== "inprogress" && (
                <>
                  <div
                    onClick={() =>
                      handleAccept({ user, status, date, historyID })
                    }
                    className=" flex justify-center items-center cursor-pointer"
                    style={{ width: "50px", height: "50px" }} // Añade un tamaño fijo para el contenedor
                  >
                    <svg
                      width="49"
                      height="49"
                      viewBox="0 0 49 49"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.861 32.0654H30.861V20.0654H38.861L24.861 6.06537L10.861 20.0654H18.861V32.0654ZM10.861 36.0654H38.861V40.0654H10.861V36.0654Z"
                        fill="#212121"
                      />
                    </svg>
                  </div>
                  <div
                    onClick={() =>
                      handleCancel({ user, status, date, historyID })
                    }
                    className=" flex justify-center items-center cursor-pointer"
                    style={{ width: "50px", height: "50px" }} // Añade un tamaño fijo para el contenedor
                  >
                    <svg
                      width="46"
                      height="46"
                      viewBox="0 0 50 50"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.486 38.0654C12.486 40.2654 14.286 42.0654 16.486 42.0654H32.486C34.686 42.0654 36.486 40.2654 36.486 38.0654V14.0654H12.486V38.0654ZM38.486 8.06537H31.486L29.486 6.06537H19.486L17.486 8.06537H10.486V12.0654H38.486V8.06537Z"
                        fill="#212121"
                      />
                    </svg>
                  </div>
                </>
              )}
            </div>
          </td>
        </tr>
      )}

      {showRecordDetails && status === filterselected && (
        <tr className="shadow-xl">
          <td colSpan="5">
            <table className="w-full overflow-auto bg-[#d9d9d9]">
              <thead className="w-fit">
                <tr className="bg-[#d9d9d9]">
                  {fixedColumns.map((column, index) => (
                    <th
                      key={index}
                      className="whitespace-nowrap text-xs md:text-md text-center py-1"
                      style={
                        index === fixedColumns.length - 1
                          ? { width: "20%" }
                          : {}
                      }
                    >
                      {column.label}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {record.map((item, rowIndex) => (
                  <tr
                    key={rowIndex}
                    className=" text-xs md:text-md  bg-[#d9d9d9]"
                  >
                    {fixedColumns.map((column, colIndex) => (
                      <td
                        key={colIndex}
                        className="whitespace-nowrap text-center border-b border-t overflow-x-auto border-2"
                        style={
                          colIndex === fixedColumns.length - 1
                            ? { width: "15%" }
                            : {}
                        }
                      >
                        {column.param === "ESTADO" ? (
                          <div className="flex justify-center aling-center h-8 mt-4"
              
                          >
                            <Rating
                         
                              value={
                                item.parameters.find(
                                  (param) => param.name === column.param
                                )?.value || 0
                              }
                              count={5}
                              size={27}
                              activeColor="#1D4ED8"

                              edit={false} // Solo lectura
                            />
                          </div>
                        ) : column.param === "Comments" ? (
                          <p>
                            {
                              item.parameters.find(
                                (param) => param.name === column.param
                              )?.value
                            }
                          </p>
                        ) : column.param === "ImageURL" ? (
                          <div className="flex justify-center items-center">
                          {/* Añadido para centrar la imagen o el SVG */}
                          {elementImageURLs[
                            item.parameters.find((param) => param.name === column.param)?.value
                          ] ? (
                            <img
                              src={
                                elementImageURLs[
                                  item.parameters.find((param) => param.name === column.param)?.value
                                ]
                              }
                              alt="Imagen"
                              className="h-12 w-16"
                              onClick={() =>
                                openLightbox(
                                  elementImageURLs[
                                    item.parameters.find((param) => param.name === column.param)?.value
                                  ]
                                )
                              }
                              style={{ cursor: "pointer" }}
                            />
                          ) : (
                            <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M42 38.0327V10.0327C42 7.83271 40.2 6.03271 38 6.03271H10C7.8 6.03271 6 7.83271 6 10.0327V38.0327C6 40.2327 7.8 42.0327 10 42.0327H38C40.2 42.0327 42 40.2327 42 38.0327ZM17 27.0327L22 33.0527L29 24.0327L38 36.0327H10L17 27.0327Z" fill="#060606"/>
                            </svg>
                          )}
                        </div>
                        ) : (
                          <span>
                          <Badge size="sm" color="orange">
                              {item[column.param].startsWith('Revit') ? item[column.param].substring(5) : item[column.param]}
                          </Badge>
                      </span>
                      
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
                <tr className="w-26 flex p-1 bg-[#d9d9d9]">
                  <td>
                    <div className="flex px-4 py-1 md:px-4 md:py-1 bg-blue-700 rounded justify-center items-center cursor-pointer">
                      <div className="text-green-400">View</div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      )}

      {showAcceptDesingWindow && status === filterselected && (
        <tr className="shadow-xl">
          <td colSpan="5">
            <div>
              <ConfirmModelUpdate
                modelURL={modelURL}
                modelID={modelID}
                modelYear={modelYear}
                record={record}
                historyID={historyID}
                setshowAcceptDesingWindow={setshowAcceptDesingWindow}
                refreshTable={refreshTable}
              />
            </div>
          </td>
        </tr>
      )}
      {showDeleteDesingWindow && status === filterselected && (
        <tr className="shadow-xl">
          <td colSpan="5">
            <ConfirmModelDelete
              setshowDeleteDesingWindow={setshowDeleteDesingWindow}
              modelID={modelID}
              historyID={historyID}
              refreshTable={refreshTable}
            />
          </td>
        </tr>
      )}
      {/* Lightbox */}
      {lightboxOpen && (
        <Lightbox
          open={lightboxOpen}
          close={() => setLightboxOpen(false)}
          slides={[{ src: currentImage }]}
        />
      )}
    </>
  );
};

export default TableRow;
