import React, { useState, useEffect } from "react";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import useFetchImageURL from '../../../../hooks/useFetchImageURL'; // Asegúrate de que la ruta sea correcta

const CardModel = ({ model, history }) => {
  const [isHovered, setIsHovered] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const imageURL = useFetchImageURL(model); // Llamando directamente al gancho personalizado

  const cardClasses = `m-1 flex justify-center items-center shadow-xl ${
    isHovered
      ? "border-2 border-blue-500 rounded-2xl transform scale-105 transition-transform duration-300"
      : "border rounded-2xl"
  }`;

  return (
    <div
      className={cardClasses}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="w-60 h-72 p-4 bg-white rounded-2xl shadow flex-col justify-center items-center inline-flex">
        <div className="self-stretch h-40 flex-col justify-center items-center gap-6 flex">
          <img src={imageURL} alt="card image" className="w-48 h-48" />
        </div>

        <div className="self-stretch h-28 flex-col justify-start items-center gap-6 flex">
          <div className="self-stretch h-20 flex-col justify-start items-start gap-2 flex">
            <div
              className={`text-md font-bold line-clamp-1 ${
                history
                  ? "text-green-700"
                  : "text-red-500 dark:text-red-500"
              } mt-2 overflow-hidden whitespace-nowrap overflow-ellipsis`}
            >
              {model.name}
            </div>
            <div className="text-zinc-950 text-xs font-normal line-clamp-2">
              {model.address.description}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardModel;
