import React, { useEffect, useState, useRef, useContext } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import lottie from "lottie-web";
import { Viewer } from "../../apsComponents/viewer";
import { ForgeContext } from "../../../context/ForgeContext";

import { useParamsContext } from "../../../context/ParamsContext";

const ViewerPageApproval = () => {
  const { setData, coloredIds } = useContext(ForgeContext);
  const axiosPrivate = useAxiosPrivate();
  const [accessToken, setAccessToken] = useState(null);
  const [selectProps, setSelectProps] = useState([]);
  const [decodedModelURL, setDecodedModelURL] = useState("");
  const [selectedView, setSelectedView] = useState(0);
  const [isViewMenuOpen, setIsViewMenuOpen] = useState(true);
  const { paramsData } = useParamsContext();

  const [urn, setUrn] = useState(null);
  const [viewerLoading, setViewerLoading] = useState(true);
  const api = axiosPrivate;
  const lottieContainer = useRef(null);
 // Función para formatear la fecha
 const formatFecha = (dateString) => {
  const date = new Date(dateString);
  const año = date.getFullYear();
  const mes = String(date.getMonth() + 1).padStart(2, '0');
  const dia = String(date.getDate()).padStart(2, '0');
  const hora = String(date.getHours()).padStart(2, '0');
  const minuto = String(date.getMinutes()).padStart(2, '0');
  return `${año} / ${mes} / ${dia} - ${hora}:${minuto}`;
};
  // Animacion antes de que carge el viewer
  useEffect(() => {
    lottie.loadAnimation({
      container: lottieContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: "https://lottie.host/78201082-d6e0-4d1b-a355-7c041088cee9/zuGM9i7AQB.json",
    });
  }, [viewerLoading]);

  // Para no duplicar la instancia del viewer
  const handleViewerReady = (viewer) => {
    handleViewerReady(viewer);
  };

  // Obtener la URL actual del navegador y decodificarla
  useEffect(() => {
    const currentURL = window.location.pathname;
    const encodedModelURL = currentURL.substring("/approvalUpdate/".length);
    const decodedURL = decodeURIComponent(encodedModelURL);
    setDecodedModelURL(decodedURL); // Almacenar en el estado
  }, []);

  //Consigo el accesToken de autodesk
  useEffect(() => {
    console.log("Entro a useEffect the getToken");

    const getToken = async () => {
      try {
        const response = await api.get(`/aps/token`);
        setAccessToken(response.data.access_token);
      } catch (err) {
        console.log(err);
      }
    };
    getToken();
  }, [decodedModelURL]);

  // Consigo la URN
  useEffect(() => {
    console.log("Entro a useEffect the urn");
    const getVersionUrn = async () => {
      try {
        console.log(accessToken);
        const response = await api.post(`/aps/getTip`, {
          modelURL: decodedModelURL,
          accessToken,
        });
        setUrn(response.data);
        setViewerLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    if (accessToken) {
      console.log("solicitud para conseguir la urn");
      getVersionUrn();
    }
  }, [accessToken]);

  const toggleViewMenu = () => {
    setIsViewMenuOpen(!isViewMenuOpen);
    // setViewerWidth(isViewMenuOpen ? "100%" : "66%");
  };

  const handleElementClick = (element) => {
    const stateParam = element.parameters.find((param) =>
      [
        "Condition",
        "State",
        "Estado",
        "state",
        "Condicion",
        "MC-Estado",
      ].includes(param.name)
    );

    if (stateParam) {
      const ObjectdelElement = {
        dbId: element.dbId,
        State: stateParam.value,
      };
      setData([ObjectdelElement], "coloredIds");
    } else {
      const ObjectdelElement = {
        dbId: element.dbId,
        State: 0,
      };
      setData([ObjectdelElement], "coloredIds");
    }
    console.log(selectProps);
  };



  console.log(paramsData);
  return (
    <div>
      {/* Componente para revisar cambios  */}
      <div
        className={`${
          isViewMenuOpen ? "flex " : "hidden"
        } flex  top-16  w-full h-full md:left-14 md:w-72 md:mt-10  md:h-5/6   fixed z-30   bg-white rounded shadow-xl flex-col`}
      >
        <div className="h-full p-2 ">
          <div className="flex justify-between items-center mb-4"></div>
          {paramsData && (
            <div>
              <div> user : {paramsData.user}</div>
              <div> date : {formatFecha(paramsData.date)}</div>
            
              <div className="overflow-auto max-h-[42vh] h-[42vh]">
                {paramsData.record.map((record, index) => (
                  <div
                    key={index}
                    className="w-full  flex flex-col text-center overflow-auto border border-gray-300 dark:border-gray-700 text-sm cursor-pointer relative"
                    onClick={() => handleElementClick(record)}

                  >
                    <div>Sabemos que es un : {record.category}</div>
                    <div>Sabemos que es un : {record.name}</div>
                    <div>Sabemos que es un : {record.dbId}</div>
                    {/* <div>Parametros actualizados : {record.parameters[0].dbId}</div> */}
                  </div>
                ))}
              </div>
              <div>
                <div className=" border-2"> Aca va la imagen del elemento si la tiene</div>
                </div>
                <div>
                  Aca van pas propiedades actuales del elemento que selecciona o la comparacion

                  </div>
                  <button className="border-2 bg-red-600">boton para mandar a desing si aprueba</button>

                  <button
                className="text-zinc-950 text-lg font-normal rounded border border-green-400 justify-center items-center w-full "
                onClick={toggleViewMenu}
              >
                Cancel
              </button>
            </div>
          )}
        </div>
      </div>
      {/* Boton para abrir el componente para revisar cambios  */}
      <div
        className={`flex flex-row z-30 ${
          isViewMenuOpen  ? "hidden" : "block"
        }`}>


<div className="z-30 flex fixed bottom-14 right-5 md:static md:mt-0 md:top-14 md:ml-8  md:mt-6 cursor-pointer ">
          <div
            className="text-green-400 text-lg font-bold bg-blue-700 rounded justify-center items-center gap-2.5 inline-flex w-40 h-11 "
            onClick={toggleViewMenu}
          >
            Open menu
          </div>
        </div>

      </div>
      {/* viewer  */}
      <div className="relative flex-grow p-1 pl-2 pr-2 rounded-lg min-h-max">
          <div className="w-full h-full min-h-[90vh] rounded-lg relative flex flex-col">      {urn !== null && !viewerLoading ? (
        <Viewer
          urn={urn ? `urn:${btoa(urn).replace("/", "_").replace("=", "")}` : ""}
          selectedIndex={selectedView}
          setData={setData}
          runtime={{ accessToken }}
          onSelectionChange={({ viewer, ids }) => {
            let allProperties = []; // Array para acumular las propiedades de todos los ids
            setData([], "resetProps"); // Reseteo previo de las propiedades

            // Utiliza Promise.all para asegurar que todas las propiedades se recopilan antes de llamar a setData
            Promise.all(
              ids.map(
                (id) =>
                  new Promise((resolve, reject) => {
                    viewer.getProperties(
                      id,
                      (properties) => {
                        allProperties.push(properties); // Acumula las propiedades en el array
                        resolve();
                      },
                      () => reject() // Manejo de errores
                    );
                  })
              )
            ).then(() => {
              setSelectProps(allProperties);
              setData(allProperties, "selectionProps"); // Pasa todas las propiedades acumuladas
            });
          }}
          coloredIds={coloredIds}
        />
        
      ) : (
        <div className="fixed inset-0 flex flex-col justify-center items-center  z-50">
          <div ref={lottieContainer} style={{ height: 120, width: 120 }}></div>
          <h2 className="text-2xl m font-extrabold text-gray-900 dark:text-gray-300 ">
            Loading...
          </h2>
        </div>
      )}
    </div>
    </div>
    </div>

  );
};

export default ViewerPageApproval;
