import { Link } from "react-router-dom"

const Comparation = () => {
    return (
        <section>
            <h1>Comparation Page</h1>
            <br />
            <p>Aqui aparecen los modelos para comparar</p>
            <div className="flexGrow">
                <Link to="/home">Home</Link>
            </div>
        </section>
    )
}

export default Comparation
