import React, { useState, useRef, useEffect } from "react";
import useAuth from "../hooks/useAuth";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import {
  faHome,
  faChartBar,
  faPalette,
  faUser,
  faShoePrints,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import useLogout from "../hooks/useLogout";
import logo from "../components/utils/img/icons/logoblack.png";
import dashboard from "./utils/img/icons/dashboard.svg"
import operate from "./utils/img/icons/operate.svg"
import home from "./utils/img/icons/home.svg"
import singout from "./utils/img/icons/singout.svg"

export const Header = () => {
  const { auth } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const logout = useLogout();
  const isLoggedIn = Object.keys(auth).length > 0;
  const menuRef = useRef(null);

  const signOut = async () => {
    await logout();
    navigate("/");
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(true);
    console.log(isMenuOpen)
  };
  

  const handleDocumentClick = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      console.log("presiono click")
      closeMenu();
    }
  };

  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener("click", handleDocumentClick);
    }
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [isMenuOpen]);
  

  const menuItems = [
    { to: "/home", text: "Home", icon: home },
    { to: "/dashboard", text: "Dashboard", icon: dashboard },
    { to: "/desingAutomation", text: "Operate", icon: operate },
  ];

  return (
    <header className={`bg-[#1b1646] z-30 relative`}>
      <div className="flex flex-col justify-between items-center z-30">
        <div className="flex w-full justify-between items-center bg-[#1b1646] z-30">
          <button
            onClick={() => navigate(-1)}
            className="text-gray-200 hover:text-gray-600 focus:outline-none ml-3 z-30"
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>
          <Link to="/home" className="flex items-center ml-3">
            <img src={logo} className="h-16" alt="Voyansi Logo" />
          </Link>
          <div className="flex items-center justify-center">
            <button
              onClick={toggleMenu}
              type="button"
              className=" z-30 inline-flex items-center mr-4 ml-4 mb-2 text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              aria-expanded={isMenuOpen ? "true" : "false"}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className={`w-6 h-6 ${isMenuOpen ? "hidden" : ""}`}
                fill="white"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12 a1 1 0 1100 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <svg
                className={`w-6 h-6 ${isMenuOpen ? "" : "hidden"}`}
                fill="white"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
        </div>

        {isMenuOpen && (
          <div
            ref={menuRef}
            className="absolute top-full right-0 mt-2 mr-4 w-36 rounded-lg shadow flex flex-col justify-start items-start bg-white text-black z-30"
          >
            {menuItems.map((item, index) => (
              <Link
                to={item.to}
                key={index}
                className="h-12 w-full border-b border-zinc-300 flex items-center gap-2 text-lg hover:bg-[#050505] z-30 justify-start"
                onClick={toggleMenu}
              >
                <div className="flex items-center text-black hover:text-[#00ff82] h-full w-full ">
                  <div className="w-fit h-fit">
                    <img
                      src={item.icon}
                      className={`w-6 h-6 ml-2 mr-2 ${
                        isMenuOpen ? "hover:text-[#050505]" : "text-[#050505]"
                      } hover:text-[#050505]`}
                    />
                  </div>
                  {item.text}
                </div>
              </Link>
            ))}

            {isLoggedIn && (
              <div className="w-full flex flex-row justify-start items-start hover:bg-[#050505] hover:text-white cursor-pointer">
                <img
                  src={singout}
                  className="w-6 h-6 ml-2 mt-1 "
                />

                <div
                  onClick={() => {
                    signOut();
                    toggleMenu();
                  }}
                  className="px-2 py-1 flex items-center gap-2 text-lg hover:text-white z-30 w-full"
                >
                  Sign Out
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </header>
  );
};
