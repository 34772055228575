import React, { useState, useEffect } from "react";
import { Toaster, toast } from 'sonner'
import { useNavigate } from "react-router-dom";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import useAuth from "../../../hooks/useAuth";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import ViewerInput from "../manager/createModelComponents/ViewerInput";
import LayoutInput from "../manager/createModelComponents/LayoutInput";

const CREATEMODEL_URL = "/models";

const CreateModel = () => {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const companyID = auth?.companyID;
  const navigate = useNavigate();

  const [address, setAddress] = useState("");
  const [zone, setZone] = useState([]);
  const [selectedZone, setSelectedZone] = useState(""); // Estado para almacenar la zona seleccionada

  const [formData, setFormData] = useState({
    name: "",
    thumbnail: "noImage.png",
    address: {},
    viewerType: "forge",
    modelURL: "",
    projectID: "",
    modelID: "",
    storage: "",
    revitYear: "",
    companyID: companyID,
    zoneID: selectedZone, // Usamos el estado de la zona seleccionada
    layout: {
      leftPanel: "Score",
      rightPanel: "Stats",
      bottomSide1: "",
      bottomSide2: "",
    },
  });
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getZones = async () => {
      try {
        const response = await axiosPrivate.get(
          `/zones/?companyID=${companyID}`,
          {
            signal: controller.signal,
          }
        );
        isMounted && setZone(response.data); // Asigna la respuesta como una matriz
      } catch (err) {
        console.error(err);
      }
    };

    getZones();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  const handleSelectAddress = async (address) => {
    const countryTerm = address.value.terms.find(
      (term) => term.types && term.types.includes("country")
    );
    const country = countryTerm ? countryTerm.value : "";
    const description = address.value.description;
    const place_id = address.value.place_id;
    let location;

    try {
      const results = await geocodeByPlaceId(place_id);
      location = results[0].geometry.location; // { lat: ..., lng: ... }
    } catch (error) {
      console.log(error);
    }

    // Actualiza el estado del modelo con la dirección seleccionada
    setFormData((formData) => ({
      ...formData,
      address: {
        description: description,
        place_id: place_id,
        country: country || description.split(",").pop().trim(),
        lat: location ? location.lat() : null,
        lng: location ? location.lng() : null,
      },
    }));

    setAddress(address);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleLayoutChange = (newLayout) => {
    setFormData({
      ...formData,
      layout: newLayout,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!companyID || companyID.trim() === "") {
      alert(
        "There is a problem with your session. Please log in again and contact support if the issue persists."
      );
      return;
    }
  
    try {
      const response = await axiosPrivate.post(CREATEMODEL_URL, formData);
  
      if (response.status === 201) {
        toast.success('Model created');
        setTimeout(function() {
          navigate("/home")
        }, 1200); // 1000 milisegundos = 1 segundo

        console.log("Nuevo modelo creado con éxito");
      } else {
        toast.error('Error')
        setTimeout(function() {
          navigate("/home")
        }, 1200); // 1000 milisegundos = 1 segundo
        console.error("Error al crear el nuevo modelo");
      }
      console.log(JSON.stringify(response?.data));
    } catch (error) {
      toast.error('Error')
      setTimeout(function() {
        navigate("/home")
      }, 1200); // 1000 milisegundos = 1 segundo      console.error(error);
    }
  };
  

  const handleZoneChange = (e) => {
    const selectedZoneID = e.target.value;
    setSelectedZone(selectedZoneID); // Actualizamos el estado de la zona seleccionada
    setFormData({
      ...formData,
      zoneID: selectedZoneID, // Actualizamos el valor de zona en formData
    });
  };

  // Define tus estilos personalizados para el modo noche y el modo día
  const customStyles = {
    control: (provided) => ({
      ...provided,
      // Utiliza clases de Tailwind CSS para cambiar los estilos según el modo nocturno o diurno
      backgroundColor: "dark:bg-gray-600 light:bg-white",
      color: "dark:text-white light:text-black",
      border: "dark:border-white light:border-black",
    }),
    input: (provided) => ({
      ...provided,
      // Utiliza clases de Tailwind CSS para el color del texto de entrada
      color: "text-blue",
    }),
    option: (provided) => ({
      ...provided,
      // Utiliza clases de Tailwind CSS para cambiar el fondo y el color del texto de las opciones
      backgroundColor: "dark:bg-black light:bg-white",
      color: "dark:text-white light:text-black",
    }),
    singleValue: (provided) => ({
      ...provided,
      // Utiliza clases de Tailwind CSS para el color del valor seleccionado
      color: "text-blue",
    }),
  };
  return (
    <section className="flex justify-center items-center ">
      <div className="dark:text-gray-200 p-6 rounded  w-full max-w-md">
        <h1 className="text-2xl font-bold mb-4">Create Model</h1>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="address"
              className="block text-gray-700 dark:text-gray-200 "
            >
              Address:
            </label>
            <GooglePlacesAutocomplete
              apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
              selectProps={{
                value: address,
                onChange: handleSelectAddress,
                styles: customStyles,
              }}
              className="dark:bg-gray-700"
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="name"
              className="block text-gray-700 dark:text-gray-200"
            >
              Name:
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              className="border rounded py-2 px-3 w-full dark:bg-gray-700"
              required
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="viewerType"
              className="block text-gray-700 dark:text-gray-200"
            >
              Viewer Type:
            </label>
            <select
              id="viewerType"
              name="viewerType"
              value={formData.viewerType}
              onChange={handleInputChange}
              className="border rounded py-2 px-3 w-full dark:bg-gray-700"
            >
              <option value="forge">Forge Viewer</option>
              <option value="ifc">IFC Viewer</option>
            </select>
          </div>

          {/* Select para la zona */}
          <div className="mb-4">
            <label
              htmlFor="zone"
              className="block text-gray-700 dark:text-gray-200"
            >
              Zone:
            </label>
            <select
              id="zone"
              name="zone"
              value={selectedZone}
              onChange={handleZoneChange}
              className="border rounded py-2 px-3 w-full dark:bg-gray-700"
            >
              <option value="">Select Zone</option>
              {Array.isArray(zone) &&
                zone.map((zoneItem) => (
                  <option key={zoneItem._id} value={zoneItem._id}>
                    {zoneItem.name}
                  </option>
                ))}
            </select>
          </div>

          <ViewerInput
            formData={formData}
            viewerType={formData.viewerType}
            onModelURLChange={(e) => {
              setFormData({
                ...formData,
                modelURL: e.target.value,
              });
            }}
            onStorageChange={(e) => {
              setFormData({
                ...formData,
                storage: e.target.value,
              });
            }}
          />

          <LayoutInput
            layout={formData.layout}
            onLayoutChange={handleLayoutChange}
          />
          <div className="flex justify-center">
            {/* Elemento "Create Model" que envía el formulario */}
            <label htmlFor="submitButton" className="relative">
              <span className="absolute top-0 left-0 mt-1 ml-1 h-full w-full rounded bg-black"></span>
              <span className="fold-bold relative inline-block h-full w-full rounded border-2 border-black bg-white  px-3 py-1 text-base font-bold text-black transition duration-100 hover:bg-yellow-400 hover:text-gray-900 dark:bg-gray-400">
                Create Model
              </span>
              {/* Botón de envío del formulario, oculto para el usuario */}
              <button
                id="submitButton"
                type="submit"
                style={{ display: "none" }}
              ></button>
            </label>
          </div>
        </form>
      </div>
    </section>
  );
};

export default CreateModel;
