const ViewerInput = ({ formData, viewerType, onModelURLChange, onStorageChange }) => {
    return (
      <div>
        {viewerType === "forge" && (
          <>
            <label htmlFor="modelURL" className="block text-gray-700 dark:text-gray-200">
              Model URL:
            </label>
            <input
              type="text"
              id="modelURL"
              name="modelURL"
              onChange={onModelURLChange}
              value={formData.modelURL} // Usar el valor directamente de formData
              className="border rounded py-2 px-3 w-full dark:bg-gray-700"
              required
            />
          </>
        )}
  
        {viewerType === "ifc" && (
          <>
            <label htmlFor="storage" className="block text-gray-700 dark:text-gray-200">
              Storage:
            </label>
            <input
              type="text"
              id="storage"
              name="storage"
              onChange={onStorageChange}
              value={formData.storage} // Usar el valor directamente de formData
              className="border rounded py-2 px-3 w-full dark:bg-gray-700"
              required
            />
          </>
        )}
      </div>
    );
  };
  export default ViewerInput;
