import React, { createContext, useContext, useState, useEffect } from "react";
import useAuth from "../hooks/useAuth";

const HistoryContext = createContext();

export const useHistoryContext = () => {
  return useContext(HistoryContext);
};

export const HistoryProvider = ({ children }) => {
  const [companyHistory, setHistoryData] = useState(null);
  const { persist } = useAuth();
  // Cargar los datos de Session Storage al inicializar el componente
  useEffect(() => {
    if (persist) {
      const storedHistoryData = sessionStorage.getItem("companyHistory");
      if (storedHistoryData) {
        setHistoryData(JSON.parse(storedHistoryData));
      }
    }
  }, [persist]);

  // Guardar los datos en Session Storage cada vez que cambien
  useEffect(() => {
    if (companyHistory && persist) {
      sessionStorage.setItem("companyHistory", JSON.stringify(companyHistory));
    }
  }, [companyHistory, persist]);

  const setCompanyHistory = (data) => {
    setHistoryData(data);
  };

  return (
    <HistoryContext.Provider value={{ companyHistory, setCompanyHistory }}>
      {children}
    </HistoryContext.Provider>
  );
};

export default HistoryContext; // Agrega esta línea para exportar el contexto
