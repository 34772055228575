import React from "react";

const LayoutInput = ({ layout, onLayoutChange }) => {
  const leftPanelOptions = ["Score", "Stats","pampa1","pampa2"];
  const rightPanelOptions = ["Score", "Stats","pampa1","pampa2"];
  const bottomSide1Options = ["GoogleMapsWithReview", "GoogleMaps", "Panellum"];
  const bottomSide2Options = ["GoogleMapsWithReview", "GoogleMaps", "Panellum"];

  const handleLayoutChange = (e) => {
    const { name, value } = e.target;
    onLayoutChange({
      ...layout,
      [name]: value,
    });
  };

  return (
    <div className=" p-2">
      <label className="block text-gray-700 dark:text-gray-200">Layout:</label>
      <div className="mb-4">
        <label className="block text-gray-700 dark:text-gray-200">Left Panel:</label>
        <select
          name="leftPanel"
          defaultValue={layout.leftPanel}
          onChange={handleLayoutChange}
          className="border rounded py-2 px-3 w-full dark:bg-gray-700"
        >
          {leftPanelOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 dark:text-gray-200">Right Panel:</label>
        <select
          name="rightPanel"
          defaultValue={layout.rightPanel}
          onChange={handleLayoutChange}
          className="border rounded py-2 px-3 w-full dark:bg-gray-700"
        >
          {rightPanelOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 dark:text-gray-200">Bottom Side 1:</label>
        <select
          name="bottomSide1"
          defaultValue={layout.bottomSide1}
          onChange={handleLayoutChange}
          className="border rounded py-2 px-3 w-full dark:bg-gray-700"
        >
          <option value="" disabled>
            Seleccionar una opción
          </option>
          {bottomSide1Options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 dark:text-gray-200">Bottom Side 2:</label>
        <select
          name="bottomSide2"
          defaultValue={layout.bottomSide2}
          onChange={handleLayoutChange}
          className="border rounded py-2 px-3 w-full dark:bg-gray-700"
        >
          <option value="" disabled>
            Seleccionar una opción
          </option>
          {bottomSide2Options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default LayoutInput;
