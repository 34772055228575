import { axiosPrivate } from "../api/axios";
import { useEffect } from "react";
import useRefreshToken from "./useRefreshToken";
import useAuth from "./useAuth";

// Hook personalizado que configura Axios para manejar la autenticación y autorización.
const useAxiosPrivate = () => {
    // Obtener la función de refresco del token y la información de autenticación.
    const refresh = useRefreshToken();
    const { auth } = useAuth();
    useEffect(() => {
        // Configurar un interceptor de solicitud para agregar el token de acceso a las solicitudes.
        const requestIntercept = axiosPrivate.interceptors.request.use(
            config => {
                if (!config.headers['Authorization']) {
                    config.headers['Authorization'] = `Bearer ${auth?.accessToken}`;
                }
                // console.log("Solicitud interceptada:", config); 
                return config;
            }, (error) => {
                console.error("Error de solicitud:", error); 
                return Promise.reject(error);
            }
        );

        // Configurar un interceptor de respuesta para manejar la renovación del token de acceso.
        const responseIntercept = axiosPrivate.interceptors.response.use(
            response => {
                // console.log("Respuesta interceptada:", response); 
                return response;
            },
            async (error) => {
                console.log(error)
                const prevRequest = error?.config;
                // Verificar si la respuesta es un código de estado 403 (prohibido) y si la solicitud no se ha vuelto a intentar.
                if (error?.response?.status === 403 && !prevRequest?.sent) {
                    prevRequest.sent = true;
                    // Obtener un nuevo token de acceso llamando a la función de refresco.
                    const newAccessToken = await refresh();
                    prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                    // console.log("Token de acceso actualizado:", newAccessToken); 
                    // console.log("Reintentando solicitud original:", prevRequest); 
                    return axiosPrivate(prevRequest);
                }
                console.error("Error de respuesta:", error); 
                return Promise.reject(error);
            }
        );

        // Función de limpieza que elimina los interceptores cuando el componente se desmonta.
        return () => {
            axiosPrivate.interceptors.request.eject(requestIntercept);
            axiosPrivate.interceptors.response.eject(responseIntercept);
        }
    }, [auth, refresh]); // Ejecutar el efecto cuando cambie la información de autenticación o refresco.

    // Devolver la instancia de Axios configurada para su uso en la aplicación.
    return axiosPrivate;
}

export default useAxiosPrivate;
