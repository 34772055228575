import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import useAuth from "../../../hooks/useAuth";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

const CreateZone = () => {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  const companyID = auth?.companyID;
  const [zones, setZones] = useState([]);
  const [models, setModels] = useState([]);
  const [createZoneData, setCreateZoneData] = useState({
    name: "",
    selectedModel: "",
  });
  const [editZoneData, setEditZoneData] = useState({
    name: "",
    selectedModel: "",
  });
  const [editingZoneId, setEditingZoneId] = useState(null);
  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(true); // Estado para habilitar/deshabilitar el botón de creación

  const controller = new AbortController();

  const getZones = async () => {
    try {
      const response = await axiosPrivate.get(
        `/zones/?companyID=${companyID}`,
        {
          signal: controller.signal,
        }
      );
      if (!controller.signal.aborted) {
        setZones(response.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getModels = async () => {
    try {
      const response = await axiosPrivate.get(`/models?companyID=${companyID}`);
      setModels(response.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    let isMounted = true;
    getZones();
    getModels();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [companyID]);

  const handleCreateInputChange = (e) => {
    const { name, value } = e.target;
    setCreateZoneData({
      ...createZoneData,
      [name]: value,
    });

    // Habilitar el botón de creación solo si el campo 'name' no está vacío
    setIsCreateButtonDisabled(value.trim() === "");
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditZoneData({
      ...editZoneData,
      [name]: value,
    });
  };

  const createZone = async () => {
    try {
      await axiosPrivate.post("/zones", {
        companyID,
        name: createZoneData.name,
      });
      setCreateZoneData({ name: "", selectedModel: "" });
      getZones();
      toast.success("Zone created");
    } catch (err) {
      console.error(err);
      toast.error("Error");
    }
  };

  const editZone = async (zoneId) => {
    try {
      await axiosPrivate.patch(`/zones`, {
        name: editZoneData.name,
        zoneID: zoneId,
      });
      setEditingZoneId(null);
      getZones();
      toast.success("Zone edited");
    } catch (err) {
      console.error(err);
      toast.error("Error");
    }
  };

  const deleteZone = async (zoneId) => {
    try {
      await axiosPrivate.delete(`/zones/?zoneID=${zoneId}`);
      getZones();
      toast.success("Zone deleted");
    } catch (err) {
      toast.error("Error");
      console.error(err);
    }
  };

  const enableEdit = (zoneId) => {
    setEditingZoneId(zoneId);
    const zoneToEdit = zones.find((zone) => zone._id === zoneId);
    if (zoneToEdit) {
      setEditZoneData({
        name: zoneToEdit.name,
        selectedModel: zoneToEdit.modelID,
      });
    }
  };

  return (
    <section className="flex justify-center h-fit">
      <div className="dark:text-gray-200 rounded w-full max-w-3xl flex flex-col items-center justify-center">
        <h2 className="text-lg font-semibold mb-4">Zones</h2>
        <form>
          <div className="">
            <input
              type="text"
              name="name"
              placeholder="Nombre de la zona"
              value={createZoneData.name}
              onChange={handleCreateInputChange}
              className="w-full px-3 py-2 border rounded-md dark:text-gray-200 dark:bg-gray-600"
              style={{ maxWidth: "450px" }}
            />
          </div>

          <div className="text-center">
            <button onClick={createZone} disabled={isCreateButtonDisabled}>
              <span
                className={`inline-block w-full rounded border-2 ${
                  isCreateButtonDisabled
                    ? "bg-gray-300 text-gray-700"
                    : "border-black bg-white dark:bg-yellow-400"
                } px-4 py-2 text-base font-bold ${
                  isCreateButtonDisabled ? "text-gray-400" : "text-black"
                } transition duration-100 hover:bg-yellow-400 hover:text-gray-900 dark:bg-transparent`}
              >
                Crear Zona
              </span>
            </button>
          </div>
        </form>
        <div class=" rounded-lg border border-gray-20 mt-4 mx-auto w-full">
          <table class="min-w-full divide-y-2 divide-gray-200 dark:text-gray-100 text-sm">
            <thead class="ltr:text-left rtl:text-right">
              <tr class="text-center">
                <th class="whitespace-nowrap px-4 py-2 text-gray-700 dark:text-gray-100">
                  Nombre
                </th>
                <th class="whitespace-nowrap px-4 py-2 text-gray-700 dark:text-gray-100">
                  Modelo
                </th>
                <th class="whitespace-nowrap px-4 py-2 text-gray-700 dark:text-gray-100">
                  Acciones
                </th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
              {zones.map((zone) => (
                <tr key={zone._id}>
                  <td class="whitespace-nowrap px-2 py-1 font-medium text-gray-900 dark:text-gray-200">
                    {editingZoneId === zone._id ? (
                      <input
                        type="text"
                        name="name"
                        value={editZoneData.name}
                        onChange={handleEditInputChange}
                        class="w-full px-2 py-1 border rounded dark:text-gray-200 dark:bg-gray-600"
                      />
                    ) : (
                      <span>{zone.name}</span>
                    )}
                  </td>
                  <td class="px-2 py-1">
                    <select
                      name="selectedModel"
                      value={editZoneData.selectedModel}
                      onChange={handleEditInputChange}
                      class="w-full px-2 py-1 border rounded dark:text-gray-200 dark:bg-gray-700"
                    >
                      <option value="" disabled>
                        List of models
                      </option>
                      {models
                        .filter((model) => model.zoneID === zone._id)
                        .map((filteredModel) => (
                          <option
                            class="dark:text-gray-100"
                            key={filteredModel._id}
                            value={filteredModel._id}
                            disabled
                          >
                            {filteredModel.name}
                          </option>
                        ))}
                    </select>
                  </td>
                  <td class="">
                    {editingZoneId === zone._id ? (
                      <div class="flex md:flex-row flex-col justify-center">
                        <div className="text-sm md:px-2 md:py-1 m-1">
                          <span
                            onClick={() => editZone(zone._id)}
                            className="fold-bold relative inline-block w-full h-full dark:bg-green-400 rounded border-2 border-black md:px-2 md:py-1 sm:text-sm font-bold text-black transition duration-100 bg-green-400 hover:text-gray-900 dark:bg-transparent"
                          >
                            Guardar
                          </span>
                        </div>
                        <div className="text-sm md:px-2 md:py-1 m-1">
                          <span
                            onClick={() => setEditingZoneId(null)}
                            className="fold-bold relative inline-block w-full h-full rounded border-2 border-black md:px-2 md:py-1 sm:text-sm font-bold text-black transition duration-100 bg-red-400 hover:text-gray-900 dark-bg-transparent"
                          >
                            Cancel
                          </span>{" "}
                        </div>
                      </div>
                    ) : (
                      <div class="flex flex-col lg:flex-row justify-center">
                        <div class="text-sm md:px-2 md:py-1 m-1">
                          <a class="flex items-center justify-center">
                            <span
                              onClick={() => enableEdit(zone._id)}
                              class="fold-bold relative inline-block w-full h-full dark:bg-green-400 rounded border-2 border-black md:px-2 md:py-1 sm:text-sm font-bold text-black transition duration-100 bg-green-400 hover:text-gray-900 dark:bg-transparent"
                            >
                              Editar
                            </span>
                          </a>
                        </div>
                        <div class="text-sm md:px-2 md:py-1 m-1">
                          <a
                            onClick={() => deleteZone(zone._id)}
                            class="flex items-center justify-center"
                          >
                            <span class="fold-bold relative inline-block w-full h-full rounded border-2 border-black md:px-2 md:py-1 sm:text-sm font-bold text-black transition duration-100 bg-red-400 hover:text-gray-900 dark-bg-transparent">
                              Eliminar
                            </span>
                          </a>
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default CreateZone;
