import React, { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCamera,
  faTimes,
  faCameraRetro,
  faCheck,
  faTrash,
  faExchangeAlt,
} from "@fortawesome/free-solid-svg-icons";

function PhotoCapture({ handlePhotoURL }) {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [photoURL, setPhotoURL] = useState(null);
  const [cameraOpen, setCameraOpen] = useState(false);
  const [showPhoto, setShowPhoto] = useState(false);
  const [captured, setCaptured] = useState(false);
  const [selectedCamera, setSelectedCamera] = useState(null);
  const [stream, setStream] = useState(null);
  const [isDarkMode, setIsDarkMode] = useState(true);

  const openCamera = async (deviceId) => {
    try {
      if (stream) {
        const tracks = stream.getTracks();
        tracks.forEach((track) => {
          track.stop();
        });
      }

      const newStream = await navigator.mediaDevices.getUserMedia({
        video: { deviceId },
      });
      videoRef.current.srcObject = newStream;
      setStream(newStream);
      setCameraOpen(true);
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };

  const closeCamera = () => {
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach((track) => {
        track.stop();
      });
    }
    setCameraOpen(false);
  };

  const toggleCamera = async () => {
    if (cameraOpen) {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(
          (device) => device.kind === "videoinput"
        );

        if (videoDevices.length > 1) {
          const nextCameraIndex =
            selectedCamera === null
              ? 0
              : (selectedCamera + 1) % videoDevices.length;
          const selectedDevice = videoDevices[nextCameraIndex];
          setSelectedCamera(nextCameraIndex);
          openCamera(selectedDevice.deviceId);
        }
      } catch (error) {
        console.error("Error accessing camera:", error);
      }
    } else {
      openCamera(true);
    }
  };

  const capturePhoto = () => {
    if (cameraOpen) {
      const context = canvasRef.current.getContext("2d");
      const video = videoRef.current;
      const canvas = canvasRef.current;

      // Asegura que las dimensiones del canvas coincidan con las del video
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      const capturedPhotoURL = canvas.toDataURL("image/png");
      setPhotoURL(capturedPhotoURL);
      setCaptured(true);
      setShowPhoto(true);
    }
  };

  const handleAcceptPhoto = () => {
    if (showPhoto) {
      // alert("Enviar esta foto base64 a Amazon S3");

      if (typeof handlePhotoURL === "function") {
        handlePhotoURL(photoURL);
      }

      setShowPhoto(false);
      setCaptured(false);
      closeCamera();
    }
  };

  const handleDeletePhoto = () => {
    if (showPhoto) {
      setPhotoURL(null);
      setShowPhoto(false);
      setCaptured(false);
    }
  };

  return (
    <div className="flex flex-col w-full item-center justify-center ">
      <video
        ref={videoRef}
        // width="260" // Cambiar el ancho del canvas
        // height="180" // Cambiar el alto del canvas
        autoPlay
        style={{
          display: cameraOpen && !captured ? "flex" : "none",
          // maxWidth: "76%",
          // height: "80%",
        }}
        className="h-32"
      ></video>
      <canvas
        ref={canvasRef}
        style={{ display: "none" }}
        // width="260" // Cambiar el ancho del canvas
        // height="180" // Cambiar el alto del canvas
      ></canvas>
      {showPhoto ? (
        <div className="flex flex-col justify-center items-center">
          <img
            src={photoURL}
            alt="Capturada"
            className=" max-w-100 h-32 md:h-auto flex"
          />
        </div>
      ) : (
        <div></div>
      )}

      {captured ? (
        <div className="flex flex-row text-sm justify-evenly mt-1 md:mb-1">
   
            <div
              className={`flex flex-col text-xs items-center justify-center ${
                isDarkMode ? "text-gray-100" : ""
              }`}
              onClick={handleDeletePhoto}
            >
              <div className="flex flex-row text-xs bg-blue-700 rounded justify-between px-3 py-2 items-center">
                <FontAwesomeIcon icon={faTrash} />
                <p className="ml-1">Delete</p>
              </div>
            </div>
     
       
            <div
              className={`flex flex-col text-xs items-center justify-center ${
                isDarkMode ? "text-gray-100" : ""
              }`}
              onClick={handleAcceptPhoto}
            >
              <div className="flex flex-row text-xs bg-blue-700 rounded justify-between px-3 py-2 items-center">
                <FontAwesomeIcon icon={faCheck} />
                <p className="ml-1">Save</p>
              </div>
            </div>
       
        </div>
      ) : cameraOpen ? (
        <div className="flex flex-row text-sm  justify-evenly mt-1 mb-1">
          <div className="flex flex-row	">
            <div
              className={`flex flex-col text-xs items-center justify-between w-12 h-6 cursor-pointer ${
                isDarkMode ? "text-gray-100" : ""
              }`}
              onClick={capturePhoto}
              disabled={!cameraOpen}
            >
              <div className="flex flex-row text-xs bg-blue-700 rounded justify-between px-3 py-2 items-center">
                <FontAwesomeIcon icon={faCameraRetro} className="text-sm" />
                <span className="ml-1">Take</span>
              </div>
            </div>
          </div>
          <div>
            <div
              className={`flex flex-col text-xs items-center justify-between w-12 h-6 cursor-pointer ${
                isDarkMode ? "text-gray-100" : ""
              }`}
              onClick={toggleCamera}
            >
              <div className="flex flex-row text-xs bg-blue-700 rounded justify-between px-3 py-2 items-center">
                <FontAwesomeIcon icon={faExchangeAlt} />
                <p className="ml-1">Switch</p>
              </div>
            </div>
          </div>
          <div>
            <div
              className={`flex flex-col text-xs items-center justify-between w-12 h-6 cursor-pointer ${
                isDarkMode ? "text-gray-100" : ""
              }`}
              onClick={closeCamera}
            >
              <div className="flex flex-row text-xs bg-blue-700 rounded justify-between px-3 py-2 items-center">
                <FontAwesomeIcon icon={faTimes} />
                <p className="ml-1">Close</p>
              </div>
            </div>
          </div>
          
        </div>
      ) : (
        <div className="flex flex-col text-sm  w-full cursor-pointer">
          <div
            className={`flex flex-col text-xs items-center justify-center cursor-pointer ${
              isDarkMode ? "text-gray-100" : ""
            }`}
            onClick={toggleCamera}
          >
            <div className="w-full h-11 px-6 py-3 bg-blue-700 rounded justify-center items-center gap-2.5 inline-flex text-green-400 text-lg font-normal cursor-pointer ">
            <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.111 22.8327C20.7619 22.8327 22.911 20.6837 22.911 18.0327C22.911 15.3817 20.7619 13.2327 18.111 13.2327C15.46 13.2327 13.311 15.3817 13.311 18.0327C13.311 20.6837 15.46 22.8327 18.111 22.8327Z" fill="white"/>
<path d="M13.611 3.03271L10.866 6.03271H6.11096C4.46096 6.03271 3.11096 7.38271 3.11096 9.03271V27.0327C3.11096 28.6827 4.46096 30.0327 6.11096 30.0327H30.111C31.761 30.0327 33.111 28.6827 33.111 27.0327V9.03271C33.111 7.38271 31.761 6.03271 30.111 6.03271H25.356L22.611 3.03271H13.611ZM18.111 25.5327C13.971 25.5327 10.611 22.1727 10.611 18.0327C10.611 13.8927 13.971 10.5327 18.111 10.5327C22.251 10.5327 25.611 13.8927 25.611 18.0327C25.611 22.1727 22.251 25.5327 18.111 25.5327Z" fill="white"/>
</svg>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PhotoCapture;
