import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Toaster, toast } from "sonner";
import useAuth from "./hooks/useAuth";
import CreateUser from "./components/pages/admin/CreateUser";
import Login from "./components/authComponents/Login";
import Register from "./components/authComponents/Register";
import Home from "./components/pages/Home";
import Layout from "./components/Layout";
import Dashboard from "./components/pages/manager/Dashboard";
import Admin from "./components/pages/admin/Admin";
import EditUser from "./components/pages/admin/EditUser";
import Callback from "./components/pages/admin/Callback";
import Missing from "./components/authRoles/Missing";
import Unauthorized from "./components/authRoles/Unauthorized";
import DesingAutomation from "./components/pages/user/DesingAutomation";
import DesingAutomationApproval from "./components/pages/manager/DesingAutomationApproval";
import RequireAuth from "./components/authRoles/RequireAuth";
import Comparation from "./components/pages/manager/Comparation";
import CreateModel from "./components/pages/admin/CreateModel";
import CreateZone from "./components/pages/admin/CreateZone";
import EditModel from "./components/pages/admin/EditModel";

import { Header } from "./components/Header";
import ViewerPage from "./components/ViewerPage";
import { ModelProvider } from "./context/ModelProvider"; // Importa el ModelProvider
import { CompanyProvider } from "./context/CompanyContext";
import { ZoneProvider } from "./context/ZoneContext";
import { HistoryProvider } from "./context/HistoryContext";
import { ForgeProvider } from "./context/ForgeContext";
import { ParamsProvider } from "./context/ParamsContext";
import { CacheProvider } from "./context/CacheContext";

import RelevamientoPage from "./components/RelevamientoPage";
import EditParameters from "./components/pages/admin/EditParameters";
import PersistLogin from "./components/authComponents/PersistLogin";

import ViewerPageApproval from "./components/pages/manager/ViewerPageApproval";

const ROLES = {
  User: "2001",
  Manager: "1984",
  Admin: "5150",
};

function App() {
  const { auth } = useAuth();
  const isLoggedIn = Object.keys(auth).length > 0;
  console.log("El usuario esta logeado? " + isLoggedIn)

  return (
    <div className="h-full  dark:bg-[#050505]">
      
      {isLoggedIn && <Header />}
      <CacheProvider>

      <ModelProvider>
        <ZoneProvider>
          <CompanyProvider>
            <HistoryProvider>
              <ParamsProvider>
                <ForgeProvider>
                  <Toaster richColors />
                  <Routes>
                    <Route path="/" element={<Layout />}>
                      {/* public routes */}
                      {/* <Route path="" element={<LinkPage />} /> */}

                      <Route path="/" element={<Login />} />
                      <Route path="/login" element={<Login />} />
                      <Route path="/register" element={<Register />} />

                      <Route path="/unauthorized" element={<Unauthorized />} />

                      {/* we want to protect these routes */}
                      <Route element={<PersistLogin />}>
                        <Route
                          element={<RequireAuth allowedRoles={[ROLES.User]} />}
                        >
                          <Route path="/home" element={<Home />} />

                          <Route
                            path="/desingAutomation"
                            element={<DesingAutomation />}
                          />
                          <Route
                            path="/desingAutomation/:Modelid"
                            element={<RelevamientoPage />}
                          />
                        </Route>

                        <Route
                          element={<RequireAuth allowedRoles={[ROLES.Admin]} />}
                        >
                          <Route path="/admin" element={<Admin />} />
                          <Route
                            path="/admin/createUser"
                            element={<CreateUser />}
                          />
                          <Route
                            path="/admin/editUser/:id"
                            element={<EditUser />}
                          />
                          <Route
                            path="/createModel"
                            element={<CreateModel />}
                          />
                          <Route
                            path="/editParameters"
                            element={<EditParameters />}
                          />
                          <Route path="/zones" element={<CreateZone />} />
                          <Route path="/editModel" element={<EditModel />} />
                        </Route>

                        <Route
                          element={
                            <RequireAuth
                              allowedRoles={[ROLES.Manager, ROLES.Admin]}
                            />
                          }
                        >
                                                    <Route path="/callback" element={<Callback />} />

                          <Route path="/dashboard" element={<Dashboard />} />
                          <Route
                            path="/approvalUpdate"
                            element={<DesingAutomationApproval />}
                          />
                          <Route
                            path="/comparation"
                            element={<Comparation />}
                          />
                          <Route
                            path="/project/:Modelid"
                            element={<ViewerPage />}
                          />
                          <Route
                            path="/approvalUpdate/:modelURL"
                            element={<ViewerPageApproval />}
                          />
                        </Route>

                        <Route path="*" element={<Missing />} />
                      </Route>
                    </Route>
                  </Routes>
                </ForgeProvider>
              </ParamsProvider>
            </HistoryProvider>
          </CompanyProvider>
        </ZoneProvider>
      </ModelProvider>
      </CacheProvider>

      <div className="fixed bottom-0 w-full bg-opacity-100  flex justify-between items-center text-xs ">
        <div className="text-left ml-6  "></div>
        <div className="text-center text-[#b5b5b5] flex-row flex">
          All right reserved -{" "}
          <a href="https://www.voyansi.com" className="text-[#b5b5b5]">
            <p className="text-[#b5b5b5]">Voyansi</p>
          </a>
        </div>
        <div className="text-right mr-6"></div>
      </div>{" "}
    </div>
  );
}

export default App;
