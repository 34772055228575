import React, { createContext, useContext, useState } from "react";

const ModelContext = createContext();

export const useModelContext = () => {
  return useContext(ModelContext);
};

export const ModelProvider = ({ children }) => {
  const [selectedModel, setModel] = useState(null);
  const [selectedModelCategories, setModelCategories] = useState([]);

  const setModelData = (data) => {
    setModel(data);
  };

  const setCategories = (categories) => {
    setModelCategories(categories);
  };

  return (
    <ModelContext.Provider
      value={{ selectedModel, setModelData, selectedModelCategories, setCategories }}
    >
      {children}
    </ModelContext.Provider>
  );
};
