import { useRef, useState, useEffect } from "react";
import useAuth from "./../../../hooks/useAuth";
import {  toast } from 'sonner'
import {
  faCheck,
  faTimes,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { axiosPrivate } from "../../../api/axios";
import { useNavigate } from "react-router-dom";
const USER_REGEX = /^[A-z][A-z0-9-_]{2,23}$/;
const PWD_REGEX = /^(?=.*[a-zA-Z].*[a-zA-Z].*[a-zA-Z].*[a-zA-Z])/;
const REGISTER_URL = "/auth/register";

const CreateUser = () => {
  const { auth } = useAuth();
  const navigate = useNavigate();

  const companyID = auth?.companyID;
  const userRef = useRef();
  const errRef = useRef();

  const [user, setUser] = useState("");
  const [validName, setValidName] = useState(false);
  const [userFocus, setUserFocus] = useState(false);

  const [password, setPwd] = useState("");
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const [confirmPwd, setConfirmPwd] = useState("");
  const [validConfirmPwd, setValidConfirmPwd] = useState(false);
  const [confirmPwdFocus, setConfirmPwdFocus] = useState(false);

  const [selectedRole, setSelectedRole] = useState("");
  const [validRole, setValidRole] = useState(false); // Nuevo estado para la validación del rol

  const [errMsg, setErrMsg] = useState("");
  // const [success, setSuccess] = useState(false);

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setValidName(USER_REGEX.test(user));
  }, [user]);

  useEffect(() => {
    setValidPwd(PWD_REGEX.test(password));
  }, [password]);

  useEffect(() => {
    setValidConfirmPwd(password === confirmPwd);
  }, [password, confirmPwd]);

  useEffect(() => {
    setValidRole(selectedRole !== ""); // Validación del rol
  }, [selectedRole]);

  useEffect(() => {
    setErrMsg("");
  }, [user, password, confirmPwd, selectedRole]);

  const handleRoleChange = (selectedRole) => {
    setSelectedRole(selectedRole);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(auth);
    if (!auth.companyID || companyID.trim() === "") {
      // Verifica si auth.companyID es undefined, null o una cadena vacía
      alert(
        "There is a problem with your session. Please log in again and contact support if the issue persists."
      );
      return;
    }
    const v1 = USER_REGEX.test(user);
    const v2 = PWD_REGEX.test(password);
    if (!v1 || !v2 || !validConfirmPwd || !validRole) {
      setErrMsg("Invalid Entry");
      return;
    }
    try {
      const rolesToSend = [];
      if (selectedRole === "user") {
        rolesToSend.push(2001);
      }
      if (selectedRole === "manager") {
        rolesToSend.push(1984, 2001);
      }
      if (selectedRole === "admin") {
        rolesToSend.push(5150, 1984, 2001);
      }

      const response = await axiosPrivate.post(
        REGISTER_URL,
        JSON.stringify({ user, password, roles: rolesToSend, companyID })
      );

      // setSuccess(true);
      setUser("");
      setPwd("");
      setConfirmPwd("");
      setSelectedRole("");

      toast.success('User created');
      setTimeout(function() {
        navigate("/admin")
      }, 1200); // 1000 milisegundos = 1 segundo


    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
        toast.error('No Server Response')
        setTimeout(function() {
          navigate("/admin")
        }, 1200); // 1000 milisegundos = 1 segundo
      } else if (err.response?.status === 409) {
        toast.warning('Username Taken')

        setErrMsg("Username Taken");
      } else {
        setErrMsg("Registration Failed");
        toast.error('Registration Failed')
        setTimeout(function() {
          navigate("/admin")
        }, 1200); // 1000 milisegundos = 1 segundo
      }
      if (errRef.current) {
        errRef.current.focus();
      }
    }
  };

  return (
    <div className="min-h-screen flex justify-center dark:text-gray-200  p-2">
      {/* {success ? (
        <div></div>
        
      ) : ( */}
        <section className="flex flex-col w-86 md:w-96">
          <p
            ref={errRef}
            className={errMsg ? "errmsg" : "offscreen"}
            aria-live="assertive"
          >
            {errMsg}
          </p>
          <form onSubmit={handleSubmit}>
            <h1 className="text-2xl font-semibold mb-4">Create User</h1>

            <div className="">
              <label htmlFor="username" className="block font-medium">
                Username:
              </label>
              <div className="relative">
                <input
                  type="text"
                  id="username"
                  ref={userRef}
                  autoComplete="off"
                  onChange={(e) => setUser(e.target.value)}
                  value={user}
                  required
                  aria-invalid={validName ? "false" : "true"}
                  aria-describedby="uidnote"
                  onFocus={() => setUserFocus(true)}
                  onBlur={() => setUserFocus(false)}
                  className={`w-full rounded border dark:bg-gray-700 ${
                    validName ? "border-green-500" : "border-red-500"
                  } px-3 py-2 focus:outline-none focus:ring-2 focus:ring-${
                    validName ? "green-300" : "red-500"
                  } focus:border-transparent`}
                />
                <FontAwesomeIcon
                  icon={faCheck}
                  className={validName ? "valid" : "hide"}
                />
                <FontAwesomeIcon
                  icon={faTimes}
                  className={validName || !user ? "hide" : "invalid"}
                />
              </div>
              <p
                id="uidnote"
                className={
                  userFocus && user && !validName ? "instructions" : "offscreen"
                }
              >
                <FontAwesomeIcon icon={faInfoCircle} />
                4 to 24 characters.
                <br />
                Must begin with a letter.
                <br />
                Letters, numbers, underscores, hyphens allowed.
              </p>
            </div>

            <div className="">
              <label htmlFor="password" className="block font-medium">
                Password:
              </label>
              <div className="relative">
                <input
                  type="password"
                  id="password"
                  onChange={(e) => setPwd(e.target.value)}
                  value={password}
                  required
                  aria-invalid={validPwd ? "false" : "true"}
                  aria-describedby="pwdnote"
                  onFocus={() => setPwdFocus(true)}
                  onBlur={() => setPwdFocus(false)}
                  className={`w-full rounded border dark:bg-gray-700 ${
                    validPwd ? "border-green-500" : "border-red-500"
                  } px-3 py-2 focus:outline-none focus:ring-2 focus:ring-${
                    validPwd ? "green-300" : "red-500"
                  } focus:border-transparent`}
                />
                <FontAwesomeIcon
                  icon={faCheck}
                  className={validPwd ? "valid" : "hide"}
                />
                <FontAwesomeIcon
                  icon={faTimes}
                  className={validPwd || !password ? "hide" : "invalid"}
                />
              </div>

              <p
                id="pwdnote"
                className={`${
                  pwdFocus && !validPwd ? "instructions" : "offscreen"
                } max-w-[390px]`}
              >
                <FontAwesomeIcon icon={faInfoCircle} />
                8 to 24 characters.
                <br />
                Must include uppercase and lowercase letters, a number and a
                special character.
                <br />
                Allowed special characters:{" "}
                <span aria-label="exclamation mark">!</span>{" "}
                <span aria-label="at symbol">@</span>{" "}
                <span aria-label="hashtag">#</span>{" "}
                <span aria-label="dollar sign">$</span>{" "}
                <span aria-label="percent">%</span>
              </p>
            </div>

            <div className="">
              <label htmlFor="confirm_pwd" className="block font-medium">
                Confirm Password:
              </label>
              <div className="relative">
                <input
                  type="password"
                  id="confirm_pwd"
                  onChange={(e) => setConfirmPwd(e.target.value)}
                  value={confirmPwd}
                  required
                  aria-invalid={validConfirmPwd ? "false" : "true"}
                  aria-describedby="confirmnote"
                  onFocus={() => setConfirmPwdFocus(true)}
                  onBlur={() => setConfirmPwdFocus(false)}
                  className={`w-full rounded border dark:bg-gray-700 ${
                    validConfirmPwd && confirmPwd
                      ? "border-green-500"
                      : "border-red-500"
                  } px-3 py-2 focus:outline-none focus:ring-2 focus:ring-${
                    validConfirmPwd && confirmPwd ? "green-300" : "red-500"
                  } focus:border-transparent`}
                />
                <FontAwesomeIcon
                  icon={faCheck}
                  className={validConfirmPwd && confirmPwd ? "valid" : "hide"}
                />
                <FontAwesomeIcon
                  icon={faTimes}
                  className={
                    validConfirmPwd || !confirmPwd ? "hide" : "invalid"
                  }
                />
              </div>
              <p
                id="confirmnote"
                className={
                  confirmPwdFocus && !validConfirmPwd
                    ? "instructions"
                    : "offscreen"
                }
              >
                <FontAwesomeIcon icon={faInfoCircle} />
                Must match the password input field.
              </p>
            </div>
            <div>
              <ul className="grid w-full">
                <li>
                  <input
                    type="radio"
                    id="role-user"
                    name="role"
                    value="user"
                    className="hidden peer"
                    checked={selectedRole === "user"}
                    onChange={() => handleRoleChange("user")}
                    required
                  />
                  <label
                    htmlFor="role-user"
                    className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-green-400 peer-checked:border-green-400 peer-checked:text-green-400 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-200 dark:bg-gray-800 dark:hover:bg-gray-700 mb-4"
                  >
                    <div className="block">
                      <div className="w-full text-lg font-semibold">User</div>
                      <div className="w-full">
                        Los usuarios pueden editar modelos con desing
                      </div>
                    </div>
                    <svg
                      className="w-5 h-5 ms-3 rtl:rotate-180"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 10"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M1 5h12m0 0L9 1m4 4L9 9"
                      />
                    </svg>
                  </label>
                </li>
                <li>
                  <input
                    type="radio"
                    id="role-manager"
                    name="role"
                    value="manager"
                    className="hidden peer"
                    checked={selectedRole === "manager"}
                    onChange={() => handleRoleChange("manager")}
                    required
                  />
                  <label
                    htmlFor="role-manager"
                    className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-green-400 peer-checked:border-green-400 peer-checked:text-green-400 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-200 dark:bg-gray-800 dark:hover:bg-gray-700 mb-4"
                  >
                    <div className="block">
                      <div className="w-full text-lg font-semibold">
                        Manager
                      </div>
                      <div className="w-full">
                        Manager pueden vizualizar dashboard , comparacion y las
                        acciones de los usuarios
                      </div>
                    </div>
                    <svg
                      className="w-5 h-5 ms-3 rtl:rotate-180"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 10"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M1 5h12m0 0L9 1m4 4L9 9"
                      />
                    </svg>
                  </label>
                </li>
                <li>
                  <input
                    type="radio"
                    id="role-admin"
                    name="role"
                    value="admin"
                    className="hidden peer"
                    checked={selectedRole === "admin"}
                    onChange={() => handleRoleChange("admin")}
                    required
                  />
                  <label
                    htmlFor="role-admin"
                    className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-green-400 peer-checked:border-green-400 peer-checked:text-green-400 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-200 dark:bg-gray-800 dark:hover:bg-gray-700 mb-4"
                  >
                    <div className="block">
                      <div className="w-full text-lg font-semibold">Admin</div>
                      <div className="w-full">
                        Los administradores pueden editar usuarios , modelos ,
                        zonas y todas las acciones de los demas roles
                      </div>
                    </div>
                    <svg
                      className="w-5 h-5 ms-3 rtl:rotate-180"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 10"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M1 5h12m0 0L9 1m4 4L9 9"
                      />
                    </svg>
                  </label>
                </li>
              </ul>
            </div>

      
            <label htmlFor="submitButton" className="relative">
              <span className="absolute top-0 left-0 mt-1 ml-1 h-full w-full rounded bg-black"></span>
              <span
                disabled={
                  !validName || !validPwd || !validConfirmPwd || !validRole
                }
                className={`fold-bold relative inline-block h-full w-full rounded border-2 border-black bg-white px-3 py-1 text-base font-bold text-black transition duration-100 hover:bg-yellow-400 hover:text-gray-900 dark:bg-gray-400 ${
                  (!validName || !validPwd || !validConfirmPwd || !validRole) &&
                  "cursor-not-allowed fold-bold relative inline-block h-full w-full rounded border-2 border-black bg-white px-3 py-1 text-base font-bold text-black transition duration-100 hover:bg-yellow-400 hover:text-gray-900 dark:bg-gray-400"
                }`}
              >
                Create User
              </span>
              {/* Botón de envío del formulario, oculto para el usuario */}
              <button
                id="submitButton"
                type="submit"
                style={{ display: "none" }}
              ></button>
            </label>
          </form>
        </section>
      {/* ) */}
      {/* } */}
    </div>
  );
};

export default CreateUser;
