import React, { useState, useEffect } from "react";
import { AreaChart, Metric, Title, BadgeDelta } from "@tremor/react";
import "../../../../utils/styles/card.css";
const GeneralStateChart = ({ data, zones }) => {
  const [zoneData, setZoneData] = useState([]);
  const [currentGeneralState, setCurrentGeneralState] = useState(0); // Estado general actual
  const [deltaType, setDeltaType] = useState("unchanged");

  useEffect(() => {
    let filteredData = data;

    // Verificar si hay zonas seleccionadas para aplicar el filtro
    if (zones.length > 0) {
      // Filtrar los datos solo si hay zonas seleccionadas
      filteredData = data.filter((item) => zones.includes(item.zoneID));
    }

    // Obtener el año actual
    const currentYear = new Date().getFullYear();

// Crear un array de objetos para representar cada mes del año actual
const chartData = [];
for (let month = 1; month <= 12; month++) {
  // Obtener los valores de "generalState" para el mes actual en todas las zonas filtradas
  const generalStates = filteredData.map((item) => {
    const yearData = item.historyState[currentYear.toString()];
    const monthData = yearData ? yearData[`${month}`] : null;
    return monthData ? monthData["generalState"] : null;
  });
  
  // Filtrar los valores nulos (null) del array
  const filteredGeneralStates = generalStates.filter((value) => value !== null);

  // Calcular el promedio de "generalState" para el mes actual sin contar los null
  const sum = filteredGeneralStates.reduce((acc, val) => acc + val, 0);
  const averageGeneralState = filteredGeneralStates.length > 0 ? sum / filteredGeneralStates.length : 0;

  chartData.push({
    month: `${month}`,
    generalState: averageGeneralState
  });
}

console.log(chartData);


    setZoneData(chartData);

    // Obtener el valor del estado general actual para el mes actual
    const currentMonth = new Date().getMonth() + 1; // Mes actual
    const currentMonthData = chartData.find(
      (item) => item.month === currentMonth.toString()
    );
    if (currentMonthData) {
      setCurrentGeneralState(currentMonthData.generalState);
    }

    // Calcular el tipo de delta
    const prevMonth = currentMonth === 1 ? 12 : currentMonth - 1; // Mes anterior
    const prevMonthData = chartData.find(
      (item) => item.month === prevMonth.toString()
    );
    if (prevMonthData) {
      const delta = currentMonthData.generalState - prevMonthData.generalState;
      if (delta > 10) {
        setDeltaType("increase");
      } else if (delta > 0) {
        setDeltaType("moderateIncrease");
      } else if (delta < -10) {
        setDeltaType("decrease");
      } else if (delta < 0) {
        setDeltaType("moderateDecrease");
      }
    }
  }, [data, zones]);

  const badgeText =
    deltaType === "increase"
      ? "Increase"
      : deltaType === "moderateIncrease"
      ? "Moderate Increase"
      : deltaType === "decrease"
      ? "Decrease"
      : deltaType === "moderateDecrease"
      ? "Moderate Decrease"
      : "Unchanged";

  return (
    <div className=" h-fit w-full z-10 m-1">
    <div class="shadow border-2 border-blue-700 bg-white rounded-xl   h-fit w-full  z-10  ">
    <div className="  p-1 h-44  z-10">
        <div className="flex flex-col justify-center items-center  z-10">
            <div className="flex justify-between  w-full z-10">
              <div className={`p-2 bg-${deltaType}`}>
                <BadgeDelta deltaType={deltaType}>{badgeText}</BadgeDelta>
              </div>
              <Metric>{currentGeneralState.toFixed()}%</Metric>
            </div>

            {zoneData.length > 0 && (
              <AreaChart
                className="h-28 mt-3  z-10"
                data={zoneData}
                index="month"
                categories={["generalState"]}
                colors={["emerald"]}
                yAxisWidth={30}
                yAxisMin={0}
                yAxisMax={100}
                showLegend={false}
                showAnimation={true}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralStateChart;
