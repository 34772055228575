import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useAuth from "./../../../hooks/useAuth";
import { LRUCache } from "lru-cache";
import { useCompanyContext } from "../../../context/CompanyContext";
import { useZoneContext } from "../../../context/ZoneContext";
import { useHistoryContext } from "../../../context/HistoryContext";
import { ForgeContext } from "../../../context/ForgeContext";
import WrappedMap from "../../utils/functions/Map"; // Importa el componente de mapa
import Search from "../../utils/functions/Search";
import CardModel from "./dashboardComponents/CardModel";
import ProjectCount from "./dashboardComponents/tiles/ProjectCount";
import GeneralState from "./dashboardComponents/tiles/GeneralState";
import EdilicialState from "./dashboardComponents/tiles/EdilicialState";
import ElectricalState from "./dashboardComponents/tiles/ElectricalState";
import StructuralState from "./dashboardComponents/tiles/StructuralState";
import WaterState from "./dashboardComponents/tiles/WaterState";

// Importa el componente de tabla
import ModelTable from "./dashboardComponents/Table";

const Dashboard = () => {
  const axiosPrivate = useAxiosPrivate();
  const { auth, persist } = useAuth();
  const { ZoneData } = useZoneContext();
  const { companyData } = useCompanyContext();
  const { companyHistory } = useHistoryContext();
  const { setData } = useContext(ForgeContext);
// Crear una instancia de la caché LRU
const imageCache = new LRUCache({
  max: 100, // Número máximo de elementos en caché
  maxAge: 1000 * 60 * 60, // Duración máxima de almacenamiento en milisegundos (1 hora en este caso)
});

  const companyID = auth?.companyID;

  let companyLayout;
  if (persist) {
    let companyFromStorage = JSON.parse(sessionStorage.getItem("companyData"));
    companyLayout = companyFromStorage.layout;
  } else {
    companyLayout = companyData.layout;
  }

  const [models, setModels] = useState([]);
  const [selectedZones, setSelectedZones] = useState([]); // Categorías seleccionadas
  const [selectedMapZones, setselectedMapZones] = useState(new Set()); // Categorías seleccionadas para el mapa
  const [searchTerm, setSearchTerm] = useState(""); // Término de búsqueda
  const [numberOfModels, setNumberOfModels] = useState(null);

  const [firstDiv, setFirstDiv] = useState([]);
  const [secondDiv, setSecondDiv] = useState([]);

  const currentYear = new Date().getFullYear(); // Obtiene el año actual
  const currentMonth = new Date().getMonth() + 1; // Obtiene el mes actual (los meses en JavaScript son de 0 a 11, por eso sumamos 1)

  // URL de la API de Google Maps
  const googleMapURL = `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;

  // Manejar imageURLs
  const [modelImageURLs, setModelImageURLs] = useState({});
  // Agrega un nuevo estado showAllModels que determine si se deben mostrar todos los modelos o no. Inicialízalo en false.
  const [showAllModels, setShowAllModels] = useState(false);

  const [modelsToShow, setModelsToShow] = useState(4); // Número inicial de modelos a mostrar

  // Función para ajustar el número de modelos a mostrar según el ancho de la ventana
  const adjustModelsToShow = () => {
    const windowWidth = window.innerWidth;

    if (windowWidth >= 1920) {
      // Pantalla 2XL (Ancho >= 1920px)
      setModelsToShow(4);
    } else if (windowWidth >= 1024) {
      // Pantalla XL (Ancho >= 1280px)
      setModelsToShow(4);
    } else if (windowWidth >= 816) {
      // Pantalla LG (Ancho >= 1024px)
      setModelsToShow(3);
    } else if (windowWidth >= 109) {
      // Pantalla MD (Ancho >= 768px)
      setModelsToShow(2);
    } else {
      // Pantalla SM (Ancho < 768px)
      setModelsToShow(1);
    }
  };

  // Ejecutar la función de ajuste cuando el tamaño de la ventana cambie
  useEffect(() => {
    adjustModelsToShow();

    // Agregar un event listener para el cambio de tamaño de la ventana
    window.addEventListener("resize", adjustModelsToShow);

    // Retirar el event listener cuando el componente se desmonte
    return () => {
      window.removeEventListener("resize", adjustModelsToShow);
    };
  }, []);

  useEffect(() => {
    if (models.length > 0) {
      const fetchImageURLs = async () => {
        try {
          const newImageURLs = {};
  
          // Iterar sobre los modelos
          for (const model of models) {
            const cacheKey = model.thumbnail;
  
            // Comprobar si la URL está en caché
            const cachedURL = imageCache.get(cacheKey);
            console.log(cacheKey)
  
            if (cachedURL) {
              console.error("Imagen cacheada")
              // Si la URL está en caché, usar la URL almacenada en caché
              newImageURLs[model._id] = cachedURL;
            } else {
              console.error("Imagenes no cacheadas")

              // Si la URL no está en caché, realizar la solicitud HTTP
              const response = await axiosPrivate.get("/s3/get-image-url", {
                params: {
                  folderName: "thumbnails",
                  originalName: model.thumbnail,
                },
              });
  
              const imageURL = response.data.url;
  
              // Guardar la URL en caché
              imageCache.set(cacheKey, imageURL);
  
              // Almacenar la URL en el objeto newImageURLs
              newImageURLs[model._id] = imageURL;
            }
          }
  
          setModelImageURLs(newImageURLs);
        } catch (error) {
          console.error("Error fetching image URLs", error);
        }
      };
  
      fetchImageURLs();
    }
  }, [models]);

  // Maneja cambios en el término de búsqueda
  const handleSearchChange = (event) => {
    setSearchTerm((event.target.value).toLowerCase());
  };

  // Función para alternar la selección de una categoría
  const toggleCategory = (category) => {
    const updatedCategories = new Set(selectedZones);
    const updatedMapCategories = new Set(selectedMapZones);
    if (updatedCategories.has(category)) {
      updatedCategories.delete(category);
      updatedMapCategories.delete(category);
    } else {
      updatedCategories.add(category);
      updatedMapCategories.add(category);
    }

    setSelectedZones(Array.from(updatedCategories));
    setselectedMapZones(updatedMapCategories);
  };

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getModels = async () => {
      try {
        const response = await axiosPrivate.get(
          `/models?companyID=${companyID}`,
          {
            signal: controller.signal,
          }
        );
        isMounted && setModels(response.data);
      } catch (err) {
        console.error(err);
      }
    };

    getModels();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  useEffect(() => {
    if (companyLayout) {
      const components = {
        ProjectCount: (
          <ProjectCount
            numProjects={numberOfModels}
            // companyID={companyID}
          />
        ),
        GeneralState: (
          <GeneralState data={companyHistory} zones={selectedZones} />
        ),
        ElectricalState: (
          <ElectricalState data={companyHistory} zones={selectedZones} />
        ),
        EdilicialState: (
          <EdilicialState data={companyHistory} zones={selectedZones} />
        ),
        StructuralState: (
          <StructuralState data={companyHistory} zones={selectedZones} />
        ),
        WaterState: <WaterState data={companyHistory} zones={selectedZones} />,
      };
      setFirstDiv(
        companyLayout.firstDiv.map((element) => {
          return components[element];
        })
      );
      setSecondDiv(
        companyLayout.secondDiv.map((element) => {
          return components[element];
        })
      );
    }
  }, [numberOfModels]);

  const handleCardClick = (model) => {
    // Cuando se hace clic en una tarjeta, actualiza el modelo seleccionado en el contexto.
    setData(model, "selectedModel");
  };

  useEffect(() => {
    const calculateNumberOfModels = () => {
      const count =
        selectedZones.length > 0
          ? models.filter((model) => selectedZones.includes(model.zoneID))
              .length
          : models.length;
      setNumberOfModels(count);
    };

    calculateNumberOfModels();
  }, [selectedZones, models]);

  console.log(models);
  // console.log(ZoneData);
  // console.log(companyData);
  // console.log("Actualmente hay " + numberOfModels + " modelos");
  console.log(companyHistory);

  return (
    <div className="flex flex-col justify-between items-center h-screen mt-4 md:mt-16  p-4 lg:p-0">
      <div className=" flex flex-col w-full lg:max-w-screen-lg">
        <div className="absolute  mt-16 inset-0 bg-gradient-to-b from-green-400 to-blue-400 h-96  z-0"></div>
        <div className="text-indigo-950 text-4xl font-extrabold z-10 justify-start item-start w-full">
          <div className="text-left">General dashboard</div>
        </div>

        <div className="flex flex-col  w-full h-full max-w-screen-lg">
          <div className="w-full  2xl:w-full md:w-3/10  h-48 md:h-full  md:p-2 flex flex-col items-center justify-center md:items-end">
            <div className="w-full h-96 m-1">
              <WrappedMap
                googleMapURL={googleMapURL}
                models={models}
                selectedMapZones={selectedMapZones}
              />
            </div>
          </div>
          <div className="w-full 2xl:w-full flex flex-col items-center justify-center h-fit  md:p-2 ">
            {!companyLayout.length > 0 ? (
              <>
                {firstDiv.length > 0 && (
                  <div className="lg:flex md:flex-row w-full sm:flex-col h-full z-10 ">
                    {firstDiv}
                  </div>
                )}
                {!companyLayout.length > 0 && (
                  <div className="lg:flex md:flex-row w-full sm:flex-col h-full z-10">
                  {secondDiv}
                  </div>
                )}
              </>
            ) : (
              // <ClipLoader size={50} color="#123abc" loading={true} />
              <div> Cargando...</div>
            )}
          </div>
        </div>

        <div className="w-full h-fit max-w-screen-lg mt-2 mb-2">
          <div className="flex flex-col md:flex-row ">
            <div className="text-indigo-950 text-4xl font-extrabold  ">
              Modelos
            </div>
            <div className="flex md:ml-8 flex-col md:flex-row justify-center md:justify-start items-center">
              {ZoneData &&
                ZoneData.map((zona) => (
                  <label
                    key={zona._id}
                    className={`md:w-20  text-[#104cf8] md:h-9 w-20 h-full mt-1 m-1 rounded-3xl justify-center items-center inline-flex cursor-pointer font-bold ${
                      selectedZones.includes(zona._id)
                        ? "bg-[#a4baef] dark:bg-red-100 dark:text-white"
                        : "bg-violet-100 text-black-900"
                    }`}
                    style={{ textAlign: "center" }} // Añade esta línea para centrar el contenido horizontalmente
                  >
                    <input
                      type="checkbox"
                      checked={selectedZones.includes(zona._id)}
                      onChange={() => toggleCategory(zona._id)}
                      style={{ display: "none" }}
                    />
                    {zona.name}
                  </label>
                ))}
            </div>
          </div>

          <div className="w-full mt-4">
            <Search
              searchTerm={searchTerm}
              onSearchChange={handleSearchChange}
            />
          </div>
        </div>

        <div className="w-fit flex flex-col justify-center max-w-screen-lg w-full">
        <div className="flex flex-row flex-wrap justify-center md:justify-start w-full">
            {" "}
            {/* Agregamos la clase 'justify-center' para centrar horizontalmente */}
            {models
              .filter((model) => {
                
                // Filtra los modelos basados en las categorías seleccionadas
                if (selectedZones.length === 0 && !searchTerm) {
                  // Si no hay categorías seleccionadas, muestra todos los modelos
                  return true;
                } else if (searchTerm && ((model.name).toLowerCase()).includes(searchTerm)) {
                  return true;
                } else if (selectedZones.includes(model.zoneID)) {
                  // Si hay categorías seleccionadas y el modelo tiene una de las categorías, devuelve true
                  return true;
                } else {
                  return false; // En todos los demás casos, devuelve false
                }
              })
              .slice(0, showAllModels ? models.length : modelsToShow) // Utiliza el valor de modelsToShow para limitar el número de tarjetas mostradas
              .map((model, index) => {
                // Verifica si hay datos en historyState para el mes y año actual
                const hasHistory = companyHistory.some((item) => {
                  return (
                    item.modelID === model._id &&
                    item.historyState[currentYear] &&
                    item.historyState[currentYear][currentMonth]
                  );
                });

                // Pasa el prop 'history' al componente CardModel
                return (
                  <Link key={model._id} to={`/project/${model._id}`}>
                    <div
                      onClick={() => handleCardClick(model)}
                      className="w-fit ml-2 flex justify-center items-center rounded mt-4"
                    >
                      <CardModel model={model} history={hasHistory}  />
                    </div>
                  </Link>
                );
              })}
          </div>
          <div>
            {!showAllModels ? (
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 w-full rounded mt-9"
                onClick={() => setShowAllModels(true)}
              >
                Ver mas
              </button>
            ) : (
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 w-full rounded mt-9"
                onClick={() => setShowAllModels(false)}
              >
               Ver menos
              </button>
            )}
          </div>
        </div>

        <div className="w-full mt-10 max-w-screen-lg mb-10">
          <ModelTable
            models={models}
            history={companyHistory}
            modelImageURLs={modelImageURLs}
            setModels={setModels}
          />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
