import React, { useEffect, useState } from "react";
import Rating from "react-rating-stars-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Lightbox from "yet-another-react-lightbox";
import 'yet-another-react-lightbox/styles.css';
import useImageLoader from '../../../../hooks/useSingleImageLoader';
import PhotoCapture from "../../../utils/functions/PhotoCapture";

function ParamsInputs({
  formData,
  setFormData,
  defaultProps,
  photoURL,
  noEditableParams,
  formError,
  handlePhotoURL,
  toggleMenu,
  closeMenuOpenListOfElement,
  isMenuOpen
}) {

  const imageUrl = useImageLoader(defaultProps);
  const [lightboxOpen, setLightboxOpen] = useState(false);

  useEffect(() => {
    if (!formData & isMenuOpen) {
      toggleMenu(); // Llama a toggleMenu si formData es null o undefined
    }
  }, [formData]);

  const openLightbox = () => {
    setLightboxOpen(true);
  };

  return (
    <div className="flex flex-col h-fit ">
      {formData && (
        <div
          id="inputsDesign"
          className="flex-1 flex flex-col items-center rounded-lg"
        >
          {formData.parameters.map((paramObj, index) => (
            <div
              key={index}
              className={`text-sm p-1 ${paramObj.type === "boolean" ? "flex items-center" : "flex-col"
                } w-full  xl:mt-1`}
            >
              {paramObj.type === "boolean" ? (
                <>
                  <input
                    type="checkbox"
                    defaultChecked={
                      defaultProps.find(
                        (obj) => obj.displayName === paramObj.name
                      )?.displayValue === 1
                    }
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        parameters: formData.parameters.map((p, i) =>
                          i === index
                            ? {
                              ...p,
                              value: e.target.checked,
                            }
                            : p
                        ),
                      })
                    }
                    className="mr-2 ml-1 border rounded-md px-4 py-2 dark:bg-gray-700 dark:border-gray-800 dark:text-gray-100"
                  />
                  <p className=" dark:text-gray-100 text-lg">{paramObj.name}</p>
                </>
              ) : (
                <>
                  <p className="text-gray-800 dark:text-gray-100 font-bold">
                    {paramObj.name}
                  </p>
                  {paramObj.type === "string" ? (
                    <input
                      type="text"
                      className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      defaultValue={
                        defaultProps.find(
                          (obj) => obj.displayName === paramObj.name
                        )?.displayValue
                      }
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          parameters: formData.parameters.map((p, i) =>
                            i === index ? { ...p, value: e.target.value } : p
                          ),
                        })
                      }
                    />
                  ) : paramObj.type === "image" && photoURL? (
                    <div className="flex items-center">
                      <FontAwesomeIcon
                        icon={photoURL ? faCamera : faTimes}
                        className="text-xl mr-2"
                      />

                      <input
                        type="text"
                        className="flex w-full text-xs bg-white"
                        placeholder="No image"
                        defaultValue={photoURL}
                        disabled
                      />

                          
                    </div>
                    ) : paramObj.type === "image" && imageUrl != ""? (

                      <div className="flex justify-center">
                      <img
                        src={imageUrl}
                        alt="Imagen"
                        className="h-32  "
                        onClick={() => openLightbox()}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    

                  ) : paramObj.type === "rating" ? (
                    <Rating
                      count={5}
                      size={32}
                      color="#e7e7e7"
                      value={
                        defaultProps.find(
                          (obj) => obj.displayName === paramObj.name
                        )?.displayValue
                      }
                      activeColor="#1D4ED8"
                      edit={true}
                      onChange={(newRating) =>
                        setFormData({
                          ...formData,
                          parameters: formData.parameters.map((p, i) =>
                            i === index ? { ...p, value: newRating } : p
                          ),
                        })
                      }
                    />
                  ) : paramObj.type === "select" ? (
                    <select
                      defaultValue={
                        defaultProps.find(
                          (obj) => obj.displayName === paramObj.name
                        )?.displayValue == ""
                          ? "Seleccione una opción"
                          : defaultProps.find(
                            (obj) => obj.displayName === paramObj.name
                          )?.displayValue
                      }
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          parameters: formData.parameters.map((p, i) =>
                            i === index ? { ...p, value: e.target.value } : p
                          ),
                        })
                      }
                      className="border rounded-md px-2 py-1 w-full mt-1 dark:bg-gray-700 dark:border-gray-800 dark:text-gray-100"
                      value={paramObj.value === null ? "" : paramObj.value}
                      required
                    >
                      <option disabled selected>
                        Seleccione una opción
                      </option>
                      <option value="Bueno">Bueno</option>
                      <option value="Malo">Malo</option>
                      <option value="Regular">Regular</option>
                    </select>
                  ) : null}
                </>
              )}
            </div>
          ))}


          <div className="flex flex-row  justify-center items-center w-full  flex-col">
            <PhotoCapture handlePhotoURL={handlePhotoURL} />

            <div className="w-full flex h-px border border-zinc-300 mt-2"></div>
            <button
              className="w-full h-auto p-2 bg-blue-700 rounded text-center text-green-400 text-lg font-normal cursor-pointer my-2"
              type="submit"
              onClick={closeMenuOpenListOfElement}
            >
              <span className="text-green-400 text-sm font-normal">Save</span>
            </button>
          </div>
          {formError && (
            <p className="text-red-500 text-sm">
              Por favor, complete todos los campos y seleccione opciones
              válidas.
            </p>
          )}
        </div>
      )}
      {noEditableParams && (
        <div className="h-full flex flex-col items-center justify-center">
          <div className="border rounded-xl border-black w-full p-5">
            <div id="noEditableParams">
              This element has no parameters to edit.
            </div>
          </div>
        </div>
      )}
      <div
        className="w-full h-auto  p-2 rounded border border-green-400 text-zinc-950 text-md font-normal cursor-pointer   flex items-center justify-center"
        onClick={toggleMenu}
      >
        Cancel
      </div>
      {/* Lightbox */}
      {imageUrl && lightboxOpen && (
        <Lightbox
          open={lightboxOpen}
          close={() => setLightboxOpen(false)}
          slides={[{ src: imageUrl }]}
        />
      )}
    </div>
  );
}

export default ParamsInputs;
