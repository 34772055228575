import { lazy, useEffect, useState, Suspense, useRef, useContext } from "react";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import { Viewer } from "../components/apsComponents/viewer";
import { useCookies } from "react-cookie";
import { ForgeContext } from "../context/ForgeContext";
import axios from "axios";
import lottie from "lottie-web";

// Función para cargar componentes dinámicos de manera perezosa
const folderDynamic = "dynamicComponents";
const dynamicPanel = (company, panel) => {
  if (panel && panel !== "") {
    return lazy(() => import(`./${folderDynamic}/${company}/${panel}`));
  } else {
    return null; // Devuelve null si panel es una cadena vacía
  }
};

const folderStatic = "staticComponents";
const staticComponents = (panel) => {
  return lazy(() => import(`./${folderStatic}/${panel}`));
};

// Función para obtener el número de versión de la URN
function obtenerNumeroVersion(urn) {
  const versionIndex = urn.lastIndexOf("version=");
  if (versionIndex !== -1) {
    return urn.substring(versionIndex + 8);
  }
  return null;
}

const ViewerPage = () => {
  // const { selectedModel } = useModelContext();
  const { selectedModel, setData, coloredIds, nodeList } =
    useContext(ForgeContext);
  const lottieContainer = useRef(null);

  const [urn, setUrn] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [cookies] = useCookies(["access_token", "forge_token"]);
  const navigate = useNavigate();
  const [viewerLoading, setViewerLoading] = useState(true);

  // Estado para los paneles izquierdo y derecho
  const [leftPanel, setLeftPanel] = useState(null);
  const [rightPanel, setRightPanel] = useState(<></>);
  const [bottomSide1, setBottomSide1] = useState(<></>);
  const [bottomSide2, setBottomSide2] = useState(null);
  const [showViewer, setShowViewer] = useState(true);

  //selectVista
  const [selectedView, setSelectedView] = useState(0);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [versionesAnterioresArray, setVersionesAnterioresArray] = useState([]);

  //mostrar y ocultar paneles
  const [rightPanelOpen, setRightPanelOpen] = useState(true);
  const rightPanelButtonText = rightPanelOpen ? "Esconder Stats" : "Stats";

  // Botón para alternar entre el visor y la Point Cloud
  const handlePointCloudButtonClick = () => {
    setShowViewer(!showViewer);
    setLeftPanelOpen(false);
    setRightPanelOpen(false);
  };

  const handleRightPanelButtonClick = () => {
    setRightPanelOpen(!rightPanelOpen);
  };
  const [leftPanelOpen, setLeftPanelOpen] = useState(true);
  const leftPanelButtonText = leftPanelOpen ? "Esconder Informacion" : "Informacion";

  const handleLeftPanelButtonClick = () => {
    setLeftPanelOpen(!leftPanelOpen);
  };

  const [pdfList, setPdfList] = useState({});
  const [pdfModalOpen, setPdfModal] = useState(false);

  //URL endpoint
  const api = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
  });

  const handleViewChange = (event) => {
    setSelectedView(event.target.value);
  };

  const handleVersionChange = (event) => {
    const selectedVersionValue = event.target.value;
    setSelectedVersion(selectedVersionValue);
    console.log("Valor seleccionado:", selectedVersionValue);
    // ENVIAR LA VERSION AL VISOR
  };
  //ENDPOINT para descargar el pdf
  const handlePdfDownload = async (key) => {
    const response = await api.post(`/aps/getDownloadPdf`, {
      accessToken: accessToken,
      urn: selectedVersion,
      derivative: pdfList[key],
    });
    window.open(response.data.downloadUrl);
  };
  const handleModalClose = () => {
    setPdfModal(false);
  };
  useEffect(() => {
    if (urn) {
      const versionActual = obtenerNumeroVersion(urn);
      // Generar lista de versiones anteriores a partir de la versión 2
      const versionesAnteriores = [];
      for (let i = 2; i < versionActual; i++) {
        versionesAnteriores.push(i);
      }
      // console.log(versionesAnteriores);
      // Actualizar el estado con el número de versión y las versiones anteriores
      // console.log(versionActual);
      // setSelectedVersion(versionActual);
      setVersionesAnterioresArray(versionesAnteriores);
    }
  }, [urn]);

  //GET AccessToken
  useEffect(() => {
    const getToken = async () => {
      try {
        const response = await api.get(`/aps/token`, {
          headers: { authorization: cookies.access_token },
        });

        setAccessToken(response.data.access_token);
      } catch (err) {
        console.log(err);
      }
    };

    getToken();
  }, [selectedModel]);

  
  //GET VersionURN
  useEffect(() => {
    const getVersionUrn = async () => {
      const response = await api.post(`/aps/getTip`, {
        modelURL: selectedModel.modelURL,
        accessToken,
      });
      setUrn(response.data);
      setSelectedVersion(response.data);

      setViewerLoading(false);
      toast.loading("Loading");
    };
    if (selectedModel && accessToken) {
      getVersionUrn();
      toast.loading("Loading");
    }
  }, [selectedModel, accessToken]);

  //CARGO LAYOUT
  useEffect(() => {
    if (selectedModel) {
      const company = selectedModel.companyID;
      const layout = selectedModel.layout;

      // Función asincrónica para cargar un lado (izquierdo o derecho)
      const loadDynamicComponents = async (side) => {
        const Comp = dynamicPanel(company, layout[side]);
        if (Comp) {
          // Comprueba si Comp es un componente válido
          if (side === "leftPanel") {
            // console.log("Seteo leftPanel con " + layout[side]);
            setLeftPanel(<Comp />);
          } else {
            // console.log("Seteo rightPanel con " + layout[side]);
            setRightPanel(<Comp />);
          }
        } else {
          // Manejar el caso en el que Comp sea null (o cadena vacía)
          // Por ejemplo, puedes establecer el panel en null o <></>
          if (side === "leftPanel") {
            setLeftPanel(null); // O setLeftPanel(<></>) si prefieres
          } else {
            setRightPanel(null); // O setRightPanel(<></>) si prefieres
          }
        }
      };

      const loadStaticComponents = async (side) => {
        let componentToRender = layout[side];
        // console.log(componentToRender);
        if (componentToRender) {
          const Comp2 = staticComponents(componentToRender);
          console.log(Comp2);
          console.log(side);
          if (side === "bottomSide1") {
            setBottomSide1(<Comp2 />);
            // console.log("Seteo bottomSide1 con " + layout[side]);
          } else {
            setBottomSide1(<></>);
          }
          if (side === "bottomSide2") {
            // console.log("Seteo bottomSide2 con " + layout[side]);
            setBottomSide2(<Comp2 />);
          } else {
            setBottomSide2(<></>);
          }
        } else {
          // No hay componente para cargar, establecer a null o <></>
          if (side === "bottomSide1") {
            setBottomSide1(<></>);
          } else if (side === "bottomSide2") {
            setBottomSide2(<></>);
          }
        }
      };

      // Cargar el panel izquierdo
      try {
        loadDynamicComponents("leftPanel");
      } catch (error) {}
      // Cargar el panel derecho
      try {
        loadDynamicComponents("rightPanel");
      } catch (error) {}
      // Cargar el panel inferior 1
      try {
        loadStaticComponents("bottomSide1");
      } catch (error) {}
      // Cargar el panel inferior 2
      try {
        loadStaticComponents("bottomSide2");
      } catch (error) {}
    }
  }, [selectedModel]);

  // Función para navegar a la página "/home"
  const navigateToHome = () => {
    navigate("/home");
  };
  //ANIMACION CARGA MODELO
  useEffect(() => {
    lottie.loadAnimation({
      container: lottieContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: "https://lottie.host/78201082-d6e0-4d1b-a355-7c041088cee9/zuGM9i7AQB.json",
    });
  }, []);

  if (!selectedModel) {
    toast.error("Error with autodesk forge");

    return (
      <div className="h-screen flex flex-col justify-center items-center dark:text-white">
        <h1>Se duplico la instancia del visor de forge.</h1>
        <button
          onClick={navigateToHome}
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
        >
          Ir a /home
        </button>
      </div>
    );
  }

  console.log(selectedModel);
  // console.log(selectedVersion);
  return (
    <div>
      <Suspense
        fallback={
          <div className=" flex justify-center items-center  z-50">
            <div
              ref={lottieContainer}
              style={{ height: 120, width: 120 }}
            ></div>
          </div>
        }
      >
        {/* Encabezado */}
        <div className=" flex justify-center items-center mt-6 ">
          <div className=" flex flex-col 	max-w-screen-lg w-full">
            {/* Nombre y dirección */}
            <div className="flex flex-col m-1">
              <h2 className="text-2xl md:text-3xl font-extrabold text-gray-900 dark:text-gray-300">
                {selectedModel.name}
              </h2>
              <a
                href={`https://www.google.com/maps/search/?api=1&query=${selectedModel.address.description}`}
                target="_blank"
                rel="noreferrer"
              >
                <p className="text-sm md:text-md text-gray-500 dark:text-gray-400 font-medium">
                  {selectedModel.address.description}
                </p>
              </a>
              <div className="flex flex-row items-center w-auto justify-center md:justify-start md:w-auto gap-8">
                {/* Selector de VISTA */}
                <div className="flex flex-col md:flex-row gap-6">
                  <select
                    id="viewSelect"
                    className="px-2 py-1 rounded-xl border border-zinc-950 lg:w-48 w-32"
                    onChange={handleViewChange}
                    value={selectedView || ""}
                  >
                    {nodeList.length === 0 ? (
                      <option value="" disabled defaultValue>
                        Loading...
                      </option>
                    ) : (
                      <>
                        <option value="" disabled>
                          Seleccionar vista
                        </option>
                        {nodeList.map((node, i) => (
                          <option key={i} value={i}>
                            {node.data.name}
                          </option>
                        ))}
                      </>
                    )}
                  </select>

                  <select
                    id="version-select"
                    className="px-2 py-1 rounded-xl border border-zinc-950 lg:w-48 w-32"
                    onChange={handleVersionChange}
                    value={selectedVersion || ""}
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    {versionesAnterioresArray.map((version) => {
                      const urnVersion = urn.replace(
                        /version=\d+/,
                        `version=${version}`
                      );
                      return (
                        <option key={version} value={urnVersion}>
                          VERSION {version}
                        </option>
                      );
                    })}
                    <option key={selectedVersion} value={urn}>
                      Ultima version
                    </option>
                  </select>
                </div>
                <div className="flex flex-col md:flex-row items-center gap-4 ">
                  {/* BOTON RVT */}
                  <div
                    className="w-32 lg:w-36 h-11 px-6 py-3 bg-blue-700 rounded justify-center items-center gap-2.5 inline-flex text-green-400 text-lg font-medium cursor-pointer"
                    onClick={async () => {
                      const response = await api.post(
                        `/aps/getDownloadLink`,
                        {
                          modelURL: selectedModel.modelURL,
                          accessToken: accessToken,
                          version: selectedVersion,
                        },
                        {
                          headers: {
                            authorization: cookies.access_token,
                          },
                        }
                      );
                      window.open(response.data.url);
                      toast.success("Downloading ");
                    }}
                  >
                    <svg
                      width="29"
                      height="29"
                      viewBox="0 0 27 27"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19.361 9.06537H15.361V3.06537H9.36096V9.06537H5.36096L12.361 16.0654L19.361 9.06537ZM5.36096 18.0654V20.0654H19.361V18.0654H5.36096Z"
                        fill="#4ADE80"
                      />
                    </svg>
                    RVT
                  </div>
                  {/* BOTON PDF */}
                  <div
                    className="w-32 lg:w-36 h-11 px-6 py-3 bg-blue-700 rounded justify-center items-center gap-2.5 inline-flex text-green-400 text-lg font-medium cursor-pointer"
                    onClick={async () => {
                      const response = await api.post(
                        `/aps/getManifest`,
                        {
                          accessToken: accessToken,
                          urn: selectedVersion,
                        },
                        {
                          headers: {
                            authorization: cookies.access_token,
                          },
                        }
                      );

                      const names = {};
                      response.data.map((item) => {
                        const name = item.urn.split("/").slice(-1)[0];
                        names[name] = item;
                      });
                      setPdfList(names);
                      setPdfModal(true);
                    }}
                  >
                    <svg
                      width="29"
                      height="29"
                      viewBox="0 0 27 27"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.11096 12.5654C2.11096 9.52537 4.57096 7.06537 7.61096 7.06537H18.111C20.321 7.06537 22.111 8.85537 22.111 11.0654C22.111 13.2754 20.321 15.0654 18.111 15.0654H9.61096C8.23096 15.0654 7.11096 13.9454 7.11096 12.5654C7.11096 11.1854 8.23096 10.0654 9.61096 10.0654H17.111V12.0654H9.52096C8.97096 12.0654 8.97096 13.0654 9.52096 13.0654H18.111C19.211 13.0654 20.111 12.1654 20.111 11.0654C20.111 9.96537 19.211 9.06537 18.111 9.06537H7.61096C5.68096 9.06537 4.11096 10.6354 4.11096 12.5654C4.11096 14.4954 5.68096 16.0654 7.61096 16.0654H17.111V18.0654H7.61096C4.57096 18.0654 2.11096 15.6054 2.11096 12.5654Z"
                        fill="#4ADE80"
                      />
                    </svg>
                    PDF{" "}
                  </div>
                  {/* BOTON POINT CLOUD */}
                  {/* <div
                    className={`w-32 lg:w-36 h-11 px-6 py-3 rounded justify-center items-center gap-2.5 inline-flex text-md font-medium cursor-pointer ${
                      showViewer
                        ? "bg-green-400 text-blue-700"
                        : "bg-[#ff00ff] text-black"
                    }`}
                    onClick={handlePointCloudButtonClick}
                  >
                    {showViewer ? "Point cloud" : "Viewer"}
                  </div> */}
                </div>

                {/* MODAL START */}
                <div
                  id="defaultModal"
                  tabindex="-1"
                  aria-modal="true"
                  role="dialog"
                  class={`fixed bg-gray-800/25 top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full justify-center items-center flex ${
                    pdfModalOpen ? "" : "hidden"
                  }`}
                  onKeyUp={(e) => {
                    if (e.key === "Escape") {
                      handleModalClose();
                    }
                  }}
                  onClick={(e) => {
                    if (e.target.id === "defaultModal") {
                      handleModalClose();
                    }
                  }}
                >
                  <div class="relative w-full max-w-2xl max-h-full ">
                    <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                      <div class="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                        {/* <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                          Select a pdf file to download
                        </h3> */}
                        <button
                          type="button"
                          class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                          onClick={() => {
                            handleModalClose();
                          }}
                        >
                          <svg
                            class="w-3 h-3"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 14 14"
                          >
                            <path
                              stroke="currentColor"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                            />
                          </svg>
                        </button>
                      </div>
                      <div className="p-6 flex flex-col justify-center items-center">
                        {Object.keys(pdfList).map((item) => {
                          return (
                            <div
                              className="text-center bg-blue-700 text-[#00ff82] mx-2 min-w-[200px] cursor-pointer border-2 m-1 h-12 w-80 flex flex-row items-center hover:bg-[#00ff82] hover:text-[#050505] rounded-lg shadow-lg"
                              onClick={() => {
                                handlePdfDownload(item).then((result) => {
                                  console.log(result);
                                });
                              }}
                            >
                              <div className="w-1/3">
                                <svg
                                  width="49"
                                  height="49"
                                  viewBox="0 0 49 49"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M38.361 18.0654H30.361V6.06537H18.361V18.0654H10.361L24.361 32.0654L38.361 18.0654ZM10.361 36.0654V40.0654H38.361V36.0654H10.361Z"
                                    fill="#050505"
                                  />
                                </svg>
                              </div>

                              <div className="w-2/3 flex">{item}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>

                {/* MODAL END */}
              </div>
            </div>

            <div className="flex flex-row items-center justify-start w-full gap-4 mt-1 m-1 mb-2">
              {/* Botón izquierdo (acordeón) */}

              <div
                className={`w-30 h-11 px-6 py-3 rounded border border-blue-700 justify-center items-center gap-2.5 inline-flex cursor-pointer text-md font-medium ${
                  leftPanelOpen ? "bg-blue-700 text-white " : ""
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  handleLeftPanelButtonClick();
                }}
              >
                {leftPanelButtonText}
              </div>

              {/* Botón derecho (acordeón) */}
              {/* <div
                className={`w-30 h-11 px-6 py-3 rounded border border-blue-700 justify-center items-center gap-2.5 inline-flex cursor-pointer text-md font-medium ${
                  rightPanelOpen ? "bg-blue-700 text-white " : ""
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  handleRightPanelButtonClick();
                }}
              >
                {rightPanelButtonText}
              </div> */}
            </div>
          </div>
        </div>
        {/* Contenido principal */}

        <div className="justify-center items-center flex flex-col  w-full">
          {/* Panel izquierdo (acordeón) */}
          <div className="max-w-screen-lg  border-t  mt-2 p-1 w-full">
            <div
              className={` w-full  ${
                leftPanelOpen ? "responsive-left-panel" : "hidden"
              } `}
            >
              <div className=" h-full ">{leftPanel}</div>
            </div>
            {/* Panel derecho (acordeón) */}
            <div
              className={` w-full    ${
                rightPanelOpen ? "responsive-right-panel" : "hidden"
              } `}
            >
              <div className="   h-full ">{rightPanel}</div>
            </div>
          </div>
        </div>
        {/* Panel central */}
        <div className="relative flex-grow p-1 md:pl-10 md:pr-10 pl-2 pr-6 rounded-lg min-h-max">
          <div className="w-full h-full min-h-[72vh]  rounded-lg relative flex flex-col">
            {/* Contenido del panel central */}
            {/* Renderizar el visor o la Point Cloud según el estado showViewer */}
            {selectedVersion !== null && !viewerLoading ? (
              showViewer ? (
                <Viewer
                  urn={
                    urn
                      ? `urn:${btoa(selectedVersion)
                          .replace("/", "_")
                          .replace("=", "")}`
                      : ""
                  }
                  onViewInitialized={(nodeListViews) => {
                    setData(nodeListViews.nodes, "nodeList");
                  }}
                  selectedIndex={selectedView}
                  setData={setData}
                  runtime={{ accessToken }}
                  onSelectionChange={({ viewer, ids }) => {
                    let allProperties = [];
                    setData([], "resetProps");
                    Promise.all(
                      ids.map(
                        (id) =>
                          new Promise((resolve, reject) => {
                            viewer.getProperties(
                              id,
                              (properties) => {
                                allProperties.push(properties);
                                resolve();
                              },
                              () => reject()
                            );
                          })
                      )
                    ).then(() => {
                      setData(allProperties, "selectionProps");
                    });
                  }}
                  coloredIds={coloredIds}
                  showtoolbar ={true}
                />
              ) : (
                <div className="h-[68vh] w-full rounded-lg">
                  {selectedModel.iframeURL ? (
                    <iframe
                      className="h-full w-full"
                      src={selectedModel.iframeURL}
                    ></iframe>
                  ) : (
                    <p>This model does not have a point cloud associated.</p>
                  )}
                </div>
              )
            ) : (
              <div className="h-fit mt-12 flex flex-col justify-center items-center  z-50">
                <div
                  ref={lottieContainer}
                  style={{ height: 120, width: 120 }}
                ></div>
                <h2 className="text-2xl m font-extrabold text-gray-900 dark:text-gray-300 ">
                  Loading...
                </h2>
              </div>
            )}
          </div>
        </div>

        {/* Parte inferior 1 */}
        <div className=" md:ml-7  md:mr-7  mb-7  bg-[#e5e7eb] dark:bg-gray-700 rounded-lg shadow-xl">
          <div className="flex flex-col lg:flex-col rounded-lg  sm:text-center">
            {bottomSide1}
          </div>
        </div>

        {/* Parte inferior 2 */}
        <div className=" md:ml-7  md:mr-7   bg-[#e5e7eb] dark:bg-gray-700 rounded-lg shadow-xl">
          <div className="flex flex-col lg:flex-col  sm:text-center">
            {bottomSide2}
          </div>
        </div>
      </Suspense>
    </div>
  );
};

export default ViewerPage;
