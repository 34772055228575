

export function detectLanguage(selectedModelCategories) {
    if (Object.keys(selectedModelCategories).length > 0) {
      // Comprobar categorías en español
      for (const categoryEsp in categoryMap) {
        if (selectedModelCategories.hasOwnProperty(categoryEsp)) {
          return "Español";
        }
      }
  
      // Comprobar categorías en inglés
      for (const categoryEsp in categoryMap) {
        const categoryEng = categoryMap[categoryEsp];
  
        if (selectedModelCategories.hasOwnProperty(categoryEng)) {
          return "English";
        }
      }
  
      // Si no se encontró ninguna categoría en ninguno de los dos idiomas, retorna 'Unknown'
      return "Unknown";
    }
  }
  
  export function translateCategory(categoryEsp) {
    // Traduce la categoría en español a inglés utilizando el diccionario categoryMap
    return categoryMap[categoryEsp] || categoryEsp;
  }
  export const categoryMap = {
    "Revit Muros": "Revit Walls",
    "Revit Paneles de muro cortina": "Revit Curtain Panels",
    "Revit Montantes de muro cortina": "Revit Curtain Wall Mullions",
    "Revit Puertas": "Revit Doors",
    "Revit Cubiertas": "Revit Roofs",
    "Revit Pilares estructurales": "Revit Structural Columns",
    "Revit Ventanas": "Revit Windows",
    "Revit Modelos genéricos": "Revit Generic Models",
    "Revit Armazón estructural": "Revit Structural Framing",
    "Revit Pilares": "Revit Columns",
    "Revit Techos": "Revit Ceilings",
    "Revit Aparatos sanitarios": "Revit Plumbing Fixtures",
    "Revit Suelos": "Revit Floors",
    "Revit Barandillas": "Revit Railings",
    "Revit Escaleras": "Revit Stairs",
    "Revit Tramos": "Revit Runs",
    "Revit Descansillos": "Revit Landings",
    "Revit Muebles de obra": "Revit Casework",
    "Revit Equipos mecánicos": "Revit Mechanical Equipment",
    "Revit Estacionamiento": "Revit Parking",
    "Revit Equipos eléctricos": "Revit Electrical Equipment",
    "Revit Luminarias": "Revit Lighting Fixtures",
    "Revit Tuberías": "Revit Pipes",
    "Revit Eje": "Revit Center Line",
    "Revit Uniones de tubería": "Revit Pipe Fittings",
    "Revit Aparatos eléctricos": "Revit Electrical Fixtures",
    "Revit Equipos especializados": "Revit Specialty Equipment",
    "Revit Bandejas de cables": "Revit Cable Trays",
    "Revit Uniones de bandeja de cables": "Revit Cable Tray Fittings",
    "Revit Tubos": "Revit Conduits",
    "Revit Tuberías flexibles": "Revit Flex Pipes",
    "Revit Equipo de fontanería": "Revit Plumbing Equipment",
    "Revit Conductos": "Revit Ducts",
    "Revit Uniones de conducto": "Revit Duct Fittings",
    "Revit Uniones de tubo": "Revit Conduit Fittings",
    "Revit Soportes": "Revit Supports",
    "Revit Terminales de aire": "Revit Air Terminals",
    "Revit <Separación de habitación>": "Revit Room Separation",
    "Revit Sitio": "Revit Site",
  };
  