import React, { createContext, useContext, useState, useEffect } from "react";

const ParamsContext = createContext();

export const useParamsContext = () => {
  return useContext(ParamsContext);
};

export const ParamsProvider = ({ children }) => {
  const [paramsData, setParamsData] = useState(null);

  // Cargar los datos de Session Storage al inicializar el componente
  useEffect(() => {
    const storedParamsData = sessionStorage.getItem("paramsData");
    if (storedParamsData) {
      setParamsData(JSON.parse(storedParamsData));
    }
  }, []);

  // Guardar los datos en Session Storage cada vez que cambien
  useEffect(() => {
    if (paramsData) {
      sessionStorage.setItem("paramsData", JSON.stringify(paramsData));
    }
  }, [paramsData]);

  const setParams = (data) => {
    setParamsData(data);
  };

  return (
    <ParamsContext.Provider value={{ paramsData, setParams }}>
      {children}
    </ParamsContext.Provider>
  );
};

export default ParamsContext; // Agrega esta línea para exportar el contexto
