import React, { useEffect, useState, useContext, useRef } from "react";
import { ForgeContext } from "../context/ForgeContext";
import { Viewer } from "../components/apsComponents/viewer";
import { ViewerStates } from "../components/apsComponents/viewerStates";
import { toast } from "sonner";
import lottie from "lottie-web";

import useAxiosPrivate from "../../src/hooks/useAxiosPrivate";
import {
  categoryMap,
  detectLanguage,
  translateCategory,
} from "../components/utils/functions/TrasladeCategories";
import useAuth from "../hooks/useAuth";
import ParamsInputs from "../components/pages/user/RelevamientoComponents/ParamsInputs";
import ListOfElements from "../components/pages/user/RelevamientoComponents/ListOfElements";
import SubmitElement from "../components/pages/user/RelevamientoComponents/SubmitElements";

const RelevamientoPage = () => {
  const { selectedModel, setData, coloredIds, nodeList } =
    useContext(ForgeContext);
  const { auth } = useAuth();
  const [urn, setUrn] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isViewMenuOpen, setIsViewMenuOpen] = useState(false);
  const [RelevateSuccessModalOpen, setRelevateSuccessModalOpen] =
    useState(false);
  const [viewerLoading, setViewerLoading] = useState(true);
  const [selectProps, setSelectProps] = useState([]);
  const [defaultProps, setDefaultProps] = useState([]);
  const [formData, setFormData] = useState(null);
  const [noEditableParams, setNoEditableParams] = useState(false);
  const [isUploadingPhotos, setisUploadingPhotos] = useState(false);

  const [elements, setElements] = useState({}); // Estado para almacenar los elementos seleccionados
  const [formError, setFormError] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedView, setSelectedView] = useState(0);
  const [viewerInstance, setViewerInstance] = useState(null);
  const [photoURL, setPhotoURL] = useState(null);
  const lottieContainer = useRef(null);
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    lottie.loadAnimation({
      container: lottieContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: "https://lottie.host/78201082-d6e0-4d1b-a355-7c041088cee9/zuGM9i7AQB.json",
    });
  }, [viewerLoading]);

  const api = axiosPrivate;

  const handleViewChange = (event) => {
    setSelectedView(event.target.value);
  };

  useEffect(() => {
    const getToken = async () => {
      try {
        const response = await api.get(`/aps/token`);
        setAccessToken(response.data.access_token);
      } catch (err) {
        console.log(err);
      }
    };
    getToken();
  }, [selectedModel]);

  useEffect(() => {
    const getVersionUrn = async () => {
      try {
        const response = await api.post(`/aps/getTip`, {
          modelURL: selectedModel.modelURL,
          accessToken,
        });
        setUrn(response.data);
        setViewerLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    if (selectedModel && accessToken) {
      console.log("solicitud para conseguir la urn");

      getVersionUrn();
    }
  }, [selectedModel, accessToken]);

  useEffect(() => {
    const handleForm = (data) => {
      setFormData(data);
    };
    
    if (photoURL){
      setPhotoURL(null)
    }

    if (!viewerLoading && urn !== "" && selectProps.length > 0) {
      elementsSelected(selectProps, selectedModel, (result) => {
        if (result) {
          console.log(
            "El elemento seleciconado matchea con la categoria a editar , abrir menu"
          );
          if (isMenuOpen === false) {
            setIsMenuOpen(!isMenuOpen);
          }
          setFormData(result);
          setNoEditableParams(false);
          const nameElementDiv = document.getElementById("nameElement");

          if (nameElementDiv) {
            // El elemento con el id "nameElement" existe, puedes establecer su contenido de texto.
            nameElementDiv.innerText = selectProps[0].name;
          } else {
            console.error(
              "El elemento con el id 'nameElement' no se encuentra en el DOM."
            );
          }
        } else {
          console.log("Este elemento no tiene elementos a editar");
          setFormData(null);
          setNoEditableParams(true);

          // Verifica si el elemento con el ID "nameElement" existe
          const nameElementDiv = document.getElementById("nameElement");
          if (nameElementDiv) {
            nameElementDiv.innerText = "";
          } else {
            console.error(
              "El elemento con el ID 'nameElement' no se encontró en el DOM."
            );
          }
        }
        if (result && selectProps.length > 0) {
          const propValues = selectProps[0].properties.filter((prop) =>
            result.parameters.some((param) => param.name === prop.displayName)
          );
          setDefaultProps(propValues);
          propValues.forEach((prop) => {
            const pElement = document.createElement("p");
            pElement.innerHTML = `<span class="font-bold">${prop.displayName}:</span> ${prop.displayValue}`;
            pElement.classList.add(
              "text-gray-700",
              "mt-1",
              "dark:text-gray-200",
              "text-sm"
            );
          });
        }
      });
    }
  }, [viewerLoading, urn, selectProps]);

  // useEffect(() => {
  //   console.log("defaultProps has been updated , OPEN MENU ", defaultProps);

  // }, [defaultProps]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleViewMenu = () => {
    setIsViewMenuOpen(!isViewMenuOpen);
  };

  const toggleViewMenuCloseMenuRelevate = () => {
    console.log(isMenuOpen);
    handleModalClose("RelevateSuccessModalOpen");

    if (isMenuOpen) {
      setIsMenuOpen(!isMenuOpen);
      setIsViewMenuOpen(true);
    } else {
      setIsViewMenuOpen(true);
    }
  };

  const closeMenuOpenListOfElement = () => {
    setRelevateSuccessModalOpen(true);
  };

  const handlePhotoURL = (url) => {
    console.log("Valor de photoURL recibido:", url);
    setPhotoURL(url);
  };

  const handleViewerReady = (viewer) => {
    setViewerInstance(viewer);
  };

  const elementsSelected = (selectedProps, model, callback) => {
    if (!selectedProps || !model) {
      return;
    }

    const selectedCategory = selectedProps[0]?.properties.find(
      (prop) => prop.displayName === "Category"
    )?.displayValue;

    // Definir translatedCategory inicialmente como null
    let translatedCategory = null;

    // Detecta el idioma y traduce la categoría si es necesario
    if (detectLanguage(categoryMap) === "Español" && selectedCategory) {
      translatedCategory = translateCategory(selectedCategory);
    } else {
      console.log(`Categoría seleccionada: ${selectedCategory}`);
    }

    const editableParameters = model.params[translatedCategory];
    console.log(editableParameters)

    if (!editableParameters) {
      // Si no hay parámetros editables, llamamos al callback con un objeto nulo para indicar que no hay parámetros.
      callback(null);
      return;
    }

    if (selectedProps && selectedProps[0]?.properties) {
      // Buscar el valor correspondiente en selectedProps
      const matchingProps = selectedProps[0]?.properties.filter((prop) => {
        return editableParameters.some(
          (param) => param.name === prop.displayName
        );
      });
      console.log("<------------------------------------------------>");
      console.log(
        "Categorias que matchean con los params seleccionados para este modelo"
      );
      console.log(matchingProps);
      console.log("<------------------------------------------------>");

      const result = {
        category: selectedProps[0].properties.find(
          (prop) => prop.displayName === "Category"
        ).displayValue,
        elementId: selectedProps[0].properties.find(
          (prop) => prop.displayName === "ElementId"
        ).displayValue,
        dbId: selectedProps[0].dbId,
        name: selectedProps[0].name,

        parameters: editableParameters.map((paramObj) => {
          const matchingProp = matchingProps.find(
            (prop) => prop.displayName === paramObj.name
          );
          const displayValue = matchingProp ? matchingProp.displayValue : null;
          return {
            name: paramObj.name,
            type: paramObj.dataType,
            value: displayValue,
          };
        }),
      };
      console.log("El resultado que va a desing para este elemento:");
      console.log(result);
      console.log("<------------------------------------------------>");

      callback(result);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    if (formData) {
      console.log(formData.parameters);

      const hasEmptyFields = formData.parameters.some((paramObj) => {
        console.log(paramObj);
        return paramObj.value === null;
      });

      if (hasEmptyFields) {
        console.error("Tiene campos vacios");
        setFormError(true);
        toast.error("Error");
      } else {
        const hasDisabledOption = formData.parameters.some(
          (paramObj) => paramObj.value === null || paramObj.value === undefined
        );
        if (hasDisabledOption) {
          setFormError(true);
          toast.error("Error");
        } else {
          setFormError(false);
          // console.log(photoURL);
          const imageParameter = formData.parameters.find(
            (parameter) => parameter.name === "ImageURL"
          );
          if (imageParameter && photoURL !== null && photoURL !== "") {
            // Actualizar el valor de "Image" con photoURL
            imageParameter.value = photoURL;
          }

          console.log(formData);
          const cleanFormData = {
            category: formData.category,
            elementId: formData.elementId,
            dbId: formData.dbId,
            name: formData.name,
            parameters: formData.parameters.map(({ name, value }) => ({
              name,
              value: value === true ? 1 : value === false ? 0 : value,
            })),
          };

          setElements((prevElements) => ({
            ...prevElements,
            [cleanFormData.elementId]: cleanFormData,
          }));
          console.log("reseteoPhotoURL");
          setPhotoURL("");
          toast.success("Element added");
        }
      }
    }
  };

  const handleModalClose = (mId) => {
    if (mId === "confirmModal") {
      setConfirmModal(false);
    } else if (mId === "RelevateSuccessModalOpen") {
      setRelevateSuccessModalOpen(false);
    }
  };

  const handleClick = async () => {
    try {
      setisUploadingPhotos(true)
      const modelID = selectedModel._id;
      const record = Object.values(elements);
      const date = new Date();
      const timestamp = date.getTime(); 
      console.log({timestamp})
      const userName = auth.name;
  
      for (const item of record) { 
        console.log({item})
        for (const param of item.parameters) {
          console.log({param})
          if (param.name === "ImageURL" && param.value.startsWith("data")) {
            const imageBlob = dataURLtoBlob(param.value); // Convertir base64 a Blob
            console.log(`${modelID}/${item.elementId}`, `${timestamp}.jpeg`)
            const formData2 = new FormData();
            formData2.append('file', new File([imageBlob], `${timestamp}.jpeg`));
            formData2.append('folderName', `operate-uploads/${modelID}/${item.elementId}`); // Reemplazar con el nombre de tu carpeta
  
            // Hacer la solicitud de carga
            console.log(formData2)
            const uploadResponse = await api.put('/s3/upload-image', formData2,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            });
            if (uploadResponse.status === 200) {
              param.value = `${modelID}/${item.elementId}/${uploadResponse.data.fileName}`; // Actualizar con la URL de la imagen
            }
          }
        }
      }
  
      // Realizar la solicitud a la base de datos
      const response = await api.post("/history/updateHistoryParams", {
        modelID,
        record,
        date,
        userName,
      });
  
      if (response.status === 200) {
        setisUploadingPhotos(false)

        toast.success("Data sent for approval");
      } else {
        toast.error("Failed to send data for approval");
      }
    } catch (error) {
      setisUploadingPhotos(false)
      toast.error("Error, check the console.log");
      console.error("Error:", error);
    }
  };
  
  function dataURLtoBlob(dataurl) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], {type:mime});
  }

  // console.log("elements", elements);
  // console.log("accesToken " + accessToken);
  // console.log("urn " + urn);
  // console.log(defaultProps);
  // console.log(formData);

  return (
    <div className="h-max-h-full">
      {/* Modal de confirmacion para enviar la lista */}
      <div
        id="confirmModal"
        tabIndex="-1"
        aria-modal="true"
        role="dialog"
        class={`fixed bg-gray-800/25 top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full justify-center items-center flex ${
          confirmModal ? "" : "hidden"
        }`}
        onKeyUp={(e) => {
          if (e.key === "Escape") {
            handleModalClose("confirmModal");
          }
        }}
        onClick={(e) => {
          if (e.target.id === "confirmModal") {
            handleModalClose("confirmModal");
          }
        }}
      >
        <SubmitElement
          handleModalClose={handleModalClose}
          handleClick={handleClick}
          elements={elements}
          isUploadingPhotos={isUploadingPhotos}
        />
      </div>
      {/* Modal de confirmacion cuando relevo un elemento*/}

      {RelevateSuccessModalOpen && (
        <div
          id="RelevateSuccessModalOpen"
          tabIndex="-1"
          aria-modal="true"
          role="dialog"
          className={`fixed bg-gray-800/25 top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full justify-center items-center flex `}
          onKeyUp={(e) => {
            if (e.key === "Escape") {
              handleModalClose("RelevateSuccessModalOpen");
            }
          }}
          onClick={(e) => {
            if (e.target.id === "RelevateSuccessModalOpen") {
              setRelevateSuccessModalOpen(false);
              handleModalClose("RelevateSuccessModalOpen");
            }
          }}
        >
          <div className="w-full lg:w-2/4 2xl:w-1/4 md:w-2/4 h-fit p-6 bg-white rounded shadow-xl flex flex-col">
            <div className="h-fit flex-col gap-6 flex w-full">
              <div className="w-full h-24 px-4 py-7 bg-green-400 rounded-xl border border-zinc-950 justify-center items-center gap-20 inline-flex">
                <div className="text-center text-black text-lg font-normal">
                  Changes saved in "Vistas & cambios”
                </div>
              </div>
              <div className="h-full flex justify-normal	 items-center w-full">
                <div className="flex flex-row justify-center items-center w-full">
                  <div
                    className="w-fit h-auto p-2 bg-blue-700 rounded text-center text-green-400 text-lg font-normal cursor-pointer my-2 m-1 "
                    onClick={() => toggleViewMenuCloseMenuRelevate()}
                  >
                    Vistas & cambios
                  </div>
                  <div
                    className="w-24 h-auto p-2 rounded border border-green-400 text-zinc-950 text-lg font-normal cursor-pointer my-2 m-1 flex items-center justify-center"
                    onClick={() => handleModalClose("RelevateSuccessModalOpen")}
                  >
                    <p>Cerrar</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* icono de carga del viewer */}
      {viewerLoading ? (
        <>
          <div className=" h-screen   mt-12 z-60">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "40vh",
              }}
            >
              {" "}
              <div
                ref={lottieContainer}
                style={{ height: 120, width: 120 }}
              ></div>
              <div className="text-center text-2xl font-bold mb-4 dark:text-white">
                Cargando el modelo
              </div>
            </div>
          </div>
        </>
      ) : (
        // renderizado del viewer
        urn !== null && (
          <div className="w-full  min-h-[91vh] rounded-lg absolute fixed  z-2">
            <Viewer
              urn={
                urn ? `urn:${btoa(urn).replace("/", "_").replace("=", "")}` : ""
              }
              onViewInitialized={(nodeListViews) => {
                setData(nodeListViews.nodes, "nodeList");
              }}
              showtoolbar ={false}
              selectedIndex={selectedView}
              setData={setData}
              runtime={{ accessToken }}
              onSelectionChange={({ viewer, ids }) => {
                setFormData(null);
                let allProperties = []; // Array para acumular las propiedades de todos los ids
                setData([], "resetProps"); // Reseteo previo de las propiedades
                //  Promise.all para asegurar que todas las propiedades se recopilan antes de llamar a setData
                Promise.all(
                  ids.map(
                    (id) =>
                      new Promise((resolve, reject) => {
                        viewer.getProperties(
                          id,
                          (properties) => {
                            allProperties.push(properties); // Acumula las propiedades en el array
                            resolve();
                          },
                          () => reject() // Manejo de errores
                        );
                      })
                  )
                ).then(() => {
                  setSelectProps(allProperties);
                  setData(allProperties, "selectionProps"); // Pasa todas las propiedades acumuladas
                });
              }}
              coloredIds={coloredIds}
              onViewerReady={handleViewerReady}
            />
          </div>
        )
      )}
      {/* botones menus */}
      <div
        className={`flex flex-row z-30 ${
          isViewMenuOpen || isMenuOpen ? "hidden" : "block"
        }`}
      >
        {/* boton lista de elementos */}
        <div className="z-30 flex fixed bottom-16 mb-3 left-3 md:static md:mt-0 md:top-14  mt-6 md:mt-6 md:ml-1 lg:ml-6 cursor-pointer ">
          <div
            className="text-green-400 text-lg font-bold bg-blue-700 rounded justify-center items-center gap-2.5 inline-flex w-40 h-11 "
            onClick={toggleViewMenu}
          >
            Vistas & Cambios
          </div>
        </div>

        {/* Botón para abrir el menú */}
        <div className="z-30 flex fixed bottom-16 mb-3 right-3 md:static md:mt-0 md:top-14 lg:ml-8 md:ml-1  md:mt-6 cursor-pointer ">
          <div
            className="text-green-400 text-lg font-bold bg-blue-700 rounded justify-center items-center gap-2.5 inline-flex w-40 h-11 "
            onClick={toggleMenu}
          >
            Parametros{" "}
          </div>
        </div>
        <div className="fixed    md:mt-2 left-1/2 transform -translate-x-1/2 cursor-pointer z-30">
          <ViewerStates
            viewer={viewerInstance}
            selectedModel={selectedModel}
          ></ViewerStates>
        </div>
      </div>

      {/* menu lista de elementos */}
      <div
  className={`${
    isViewMenuOpen ? "flex " : "hidden"
  } overflow-auto flex top-16 w-full h-full md:left-14 md:w-72 md:mt-10 md:h-5/6 fixed z-30 bg-white rounded shadow-xl flex-col`}
>
        <div className="flex-col p-6 flex fixed z-30  h-full w-full md:h-fit md:w-72">
          <div className="flex-col justify-start items-start  flex">
            <div className=" w-full">
              <div className="text-center text-black text-lg font-bold ">
                Vista  del modelo
              </div>

              <select
                id="viewSelect"
                className="bg-gray-50 w-full border border-gray-900 text-gray-900 text-xs p-2 rounded-xl focus:ring-[#493E97] focus:border-[#493E97] dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 
                dark:text-white dark:focus:ring-[#493E97] dark:focus:border-[#493E97]"
                onChange={handleViewChange}
                value={selectedView}
              >
                {nodeList.length === 0 ? (
                  <option value="" disabled defaultValue>
                    Cargando...
                  </option>
                ) : (
                  <>
                    <option value="" disabled>
                      Selecciona
                    </option>
                    {nodeList.map((node, i) => (
                      <option key={i} value={i}>
                        {node.data.name}
                      </option>
                    ))}
                  </>
                )}
              </select>
            </div>

            <div className="w-full mt-3 h-px border border-zinc-300"></div>

            <ListOfElements
              elements={elements}
              setElements={setElements}
              setData={setData}
              selectProps={selectProps}
            />
            <div className="w-full mb-2 h-px border border-zinc-300"></div>

            <div className="flex flex-row  justify-center items-center w-full ">
              <button
                id="submitButton"
                type="submit"
                disabled={Object.keys(elements).length === 0}
                onClick={() => {
                  setConfirmModal(true);
                }}
                className={`${
                  Object.keys(elements).length === 0
                    ? "cursor-not-allowed "
                    : ""
                } bg-blue-700 rounded justify-center items-center w-full   m-1 `}
              >
                <span className="text-green-400 text-lg font-normal">
                  Enviar
                </span>
              </button>

              <button
                className="text-zinc-950 text-lg font-normal rounded border border-green-400 justify-center items-center w-full m-1"
                onClick={toggleViewMenu}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* menu relevar elemento */}
      <div
  className={`${
    isMenuOpen ? "flex " : "hidden"
  } overflow-auto flex top-16 w-full h-full md:left-14 md:w-72 md:mt-10 md:h-5/6 fixed z-30 bg-white rounded shadow-xl flex-col`}
>
        <div className=" p-3">
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col ">
              <div
                id="nameElement"
                className="flex items-center  font-bold  justify-center dark:text-white "
              ></div>
              <ParamsInputs
                formData={formData}
                setFormData={setFormData}
                defaultProps={defaultProps} //Propiedades del forge de los parametros seleccionados
                formError={formError}
                photoURL={photoURL} // Foto tomada
                noEditableParams={noEditableParams}
                handlePhotoURL={handlePhotoURL}
                toggleMenu={toggleMenu}
                closeMenuOpenListOfElement={closeMenuOpenListOfElement}
                isMenuOpen={isMenuOpen}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RelevamientoPage;
