import React, { useState, useEffect } from "react";
import useAuth from "../../../hooks/useAuth";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import {  toast } from 'sonner'
import { useNavigate } from "react-router-dom";

const EditModel = () => {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  const companyID = auth?.companyID;
  const [models, setModels] = useState([]);
  const [zones, setZones] = useState([]); // Agregamos un estado para las zonas

  const [selectedModel, setSelectedModel] = useState(null);
  const [formData, setFormData] = useState({
    zoneID: "",
    name: "",
    modelURL: "",
    revitYear: "",
  });

  useEffect(() => {
    const controller = new AbortController();
    const getModels = async () => {
      try {
        const response = await axiosPrivate.get(
          `/models?companyID=${companyID}`
        );
        setModels(response.data);
      } catch (err) {
        console.error(err);
      }
    };
    const getZones = async () => {
      try {
        const response = await axiosPrivate.get(
          `/zones/?companyID=${companyID}`,
          {
            signal: controller.signal,
          }
        );
        if (!controller.signal.aborted) {
          setZones(response.data);
        }
      } catch (err) {
        console.error(err);
      }
    };
    let isMounted = true;
    getModels();
    getZones();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [companyID]);

  const handleModelSelect = (model) => {
    setSelectedModel(model);
    if (model) {
      setFormData({
        zoneID: model.zoneID || "",
        name: model.name || "",
        modelURL: model.modelURL || "",
        revitYear: model.revitYear || "",
      });
    } else {
      setFormData({
        zoneID: "",
        name: "",
        modelURL: "",
        revitYear: "",
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleEditModel = async () => {
    if (!selectedModel) return;
    console.log("entramos");
    try {
      const response = await axiosPrivate.patch(
        `/models/updateModels/?id=${selectedModel._id}`,
        formData
      );
      console.log(response.data);
  
      // Alerta si la solicitud fue exitosa
      toast.success('Model edited');
      setTimeout(function() {
        navigate("/home")
      }, 1200); // 1000 milisegundos = 1 segundo  
      const updatedModels = models.map((model) =>
        model._id === selectedModel._id ? { ...model, ...formData } : model
      );
      setModels(updatedModels);
    } catch (err) {
      console.error(err);
  
      // Alerta si ocurrió un error
      toast.success('Error');
      setTimeout(function() {
        navigate("/home")
      }, 1200); // 1000 milisegundos = 1 segundo   
       }
  };
  

  return (
    <section className="flex justify-center items-center ">
      <div className="dark:text-gray-200 p-6 rounded  w-full max-w-md">
        <h2 className="text-2xl mb-4">Select and Edit Model</h2>
        <select
          className="w-full py-2 px-3 border rounded dark:bg-gray-600 dark:text-gray-200"
          onChange={(e) =>
            handleModelSelect(
              models.find((model) => model._id === e.target.value)
            )
          }
        >
          <option value="">Select a Model</option>
          {models.map((model) => (
            <option key={model._id} value={model._id}>
              {model.name}
            </option>
          ))}
        </select>
        {selectedModel && (
          <div className="mt-4">
            <h3 className="text-lg mb-2">Edit Model Details</h3>
            <select
              className="w-full py-2 px-3 border rounded  dark:bg-gray-600 dark:text-gray-200"
              onChange={(e) =>
                setFormData({ ...formData, zoneID: e.target.value })
              }
              value={formData.zoneID}
            >
              <option value="">Select a Zone</option>
              {zones.map((zone) => (
                <option key={zone._id} value={zone._id}>
                  {zone.name}
                </option>
              ))}
            </select>
            <div className="mb-3">
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                className="w-full py-2 px-3 border rounded  dark:bg-gray-600 dark:text-gray-200"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="modelURL">Model URL:</label>
              <input
                type="text"
                id="modelURL"
                name="modelURL"
                value={formData.modelURL}
                onChange={handleInputChange}
                className="w-full py-2 px-3 border rounded  dark:bg-gray-600 dark:text-gray-200"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="revitYear">Revit Year:</label>
              <input
                type="text"
                id="revitYear"
                name="revitYear"
                value={formData.revitYear}
                onChange={handleInputChange}
                className="w-full py-2 px-3 border rounded  dark:bg-gray-600 dark:text-gray-200"
              />
            </div>

            <div className="flex justify-center">
              <label htmlFor="submitButton" className="relative">
                <span className="absolute top-0 left-0 mt-1 ml-1 h-full w-full rounded bg-black"></span>
                <span
                  onClick={handleEditModel}
                  className="fold-bold relative inline-block h-full w-full rounded border-2 border-black bg-white px-3 py-1 text-base font-bold text-black transition duration-100 hover:bg-yellow-400 hover:text-gray-900 dark:bg-gray-400"
                >
                  Edit Model
                </span>
                {/* Botón de envío del formulario, oculto para el usuario */}
                <button style={{ display: "none" }}></button>
              </label>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default EditModel;
