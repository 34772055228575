import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useCompanyContext } from "../../context/CompanyContext";
import { useZoneContext } from "../../context/ZoneContext";
import { useHistoryContext } from "../../context/HistoryContext";

import useAuth from "../../hooks/useAuth";
import "../../input.css";
import "../utils/styles/home.css";

import team from "../utils/img/icons/team.svg";
import create from "../utils/img/icons/create.svg";
import edit from "../utils/img/icons/edit.svg";
import dashboard from "../utils/img/icons/dashboard.svg";
import check from "../utils/img/icons/check.svg";
import operate from "../utils/img/icons/operate.svg";

const Home = () => {
  const { auth } = useAuth();
  const { setCompanyInfo } = useCompanyContext();
  const { setZoneInfo } = useZoneContext();
  const { setCompanyHistory } = useHistoryContext();

  const axiosPrivate = useAxiosPrivate();
  const [activeH1, setActiveH1] = useState(null);
  const [totalHistoryParams, setTotalHistoryParams] = useState(0); // Estado para almacenar el total de historyParams

  const companyID = auth?.companyID;

  const getData = useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const getCompanies = async () => {
      try {
        const response = await axiosPrivate.get(
          `/companies/getCompanyByID?companyID=${companyID}`,
          {
            signal: controller.signal,
          }
        );
        isMounted && setCompanyInfo(response.data);
      } catch (err) {
        console.error(err);
      }
    };
    const getZones = async () => {
      try {
        const response = await axiosPrivate.get(
          `/zones?companyID=${companyID}`,
          {
            signal: controller.signal,
          }
        );
        isMounted && setZoneInfo(response.data);
      } catch (err) {
        console.error(err);
      }
    };
    const getHistory = async () => {
      try {
        const response = await axiosPrivate.get(
          `/history?companyID=${companyID}`,
          {
            signal: controller.signal,
          }
        );
        // Calcula el total de historyParams en response.data
        let totalParams = 0;

        response.data.forEach((obj) => {
          // Verifica si el objeto tiene 'historyParams'
          if (obj.historyParams) {
            // Itera sobre cada entrada en 'historyParams'
            obj.historyParams.forEach((historyParam) => {
              // Verifica si el 'status' de 'historyParam' es 'pending'
              if (historyParam.status === "pending") {
                console.log(historyParam.record.length);
                // Suma la longitud de 'record' a 'totalParams' si 'status' es 'pending'
                totalParams++;
              }
            });
          }
        });

        // Actualiza el estado con el total de 'record' de 'historyParams' con 'status' 'pending'
        setTotalHistoryParams(totalParams);

        isMounted && setCompanyHistory(response.data);
      } catch (err) {
        console.error(err);
      }
    };

    getCompanies();
    getZones();
    getHistory();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  const handleMouseEnter = (h1Name) => {
    setActiveH1(h1Name);
  };

  return (
    <div className="flex flex-col md:flex-row  h-fit dark:text-gray-400 justify-center   ">
      {/* Fondo degrade */}
      <div className="absolute  mt-16 inset-0 bg-gradient-to-b from-green-400 to-blue-400 h-96  z-0"></div>
      <div className="w-full h-full z-10 max-w-screen-lg ">
        <div className="flex flex-col">
          <h1
            className="text-center text-6xl	text-[#1b1646] mt-12"
            id="tittleHome"
          >
            Digital Twin Portal{" "}
          </h1>
        </div>
        <div className="flex flex-col lg:flex-row w-full justify-center items-center mt-8 ">
        <div className="w-full flex justify-center">
            {auth.roles && auth.roles.includes("5150") && (
              <div className=" bg-[#e7e7e7] dark:bg-[#4e4e4e] rounded-lg text-center shadow-xl  w-4/5 md:w-2/5 lg:w-4/5	xl:w-11/12 flex flex-col mt-1">
              <div className="w-full h-12 p-2.5 bg-zinc-950 rounded-tl-xl rounded-tr-xl justify-center items-center flex flex-col ">
                  <div className="text-center text-white text-2xl font-bold font-['SF Pro Text']">
                    Administrator
                  </div>
                </div>

                <div className="p-6 flex h-80 flex flex-col items-start ">
                  {[
                    { title: "Team", icon: team, link: "/admin" },
                    {
                      title: "Create Model",
                      icon: create,
                      link: "/createModel",
                    },
                    {
                      title: "Edit parameters",
                      icon: edit,
                      link: "/editParameters",
                    },
                    { title: "Edit zones", icon: edit, link: "/zones" },
                    { title: "Edit Model", icon: edit, link: "/editModel" },
                  ].map((item) => (
                    <div
                      key={item.title}
                      onMouseEnter={() => handleMouseEnter(item.title)}
                      className={`${
                        activeH1 === item.title ? "resaltado-container" : ""
                      } mt-2`}
                    >
                      {/* <Link to={item.link} className="text-black"> */}
                      <h1
                        className={`text-2xl text-black dark:text-gray-200 ${
                          activeH1 === item.title ? "resaltado" : ""
                        } text-justify`}
                      >
                        <div className="flex flex-row ">
                          <img src={item.icon} className="mr-1"></img>
                          <p className="mt-1">{item.title}</p>
                        </div>
                      </h1>
                      {/* </Link> */}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          <div className="w-full flex justify-center">
            {auth.roles && auth.roles.includes("1984") && (
              <div className=" bg-[#e7e7e7] dark:bg-[#4e4e4e] rounded-lg text-center shadow-xl  w-4/5 md:w-2/5 lg:w-4/5	xl:w-11/12 flex flex-col mt-1">
              <div className="w-full h-12 p-2.5 bg-zinc-950 rounded-tl-xl rounded-tr-xl justify-center items-center gap-2.5 inline-flex">
                  <div className="text-center text-white text-2xl font-bold font-['SF Pro Text']">
                    Manager
                  </div>
                </div>
                <div className="p-6 flex h-80 flex flex-col items-start ">
                  {[
                    {
                      title: "Dashboard",
                      icon: dashboard,
                      link: "/dashboard",
                    },
                    {
                      title: "Aprobar actualizaciones",
                      icon: check,
                      link: "/approvalUpdate",
                    },
                  ].map((item) => (
                    <div
                      key={item.title}
                      onMouseEnter={() => handleMouseEnter(item.title)}
                      className={`${
                        activeH1 === item.title ? "resaltado-container" : ""
                      } mt-2`}
                    >
                      <Link to={item.link} className="text-black">
                        <h1
                          className={`text-2xl text-black dark:text-gray-200 ${
                            activeH1 === item.title ? "resaltado" : ""
                          } text-justify`}
                        >
                          <div className="flex flex-row">
                            <img src={item.icon} className="mr-1"></img>
                            <p className="mt-1">{item.title}</p>
                            {totalHistoryParams > 0 &&
                              item.title === "Updates approval" && (
                                <span className="w-8 h-8  mt-1 bg-red-500 rounded-2xl flex-col justify-center items-center gap-2.5 inline-flex ml-2 ">
                                  <div className="text-white text-lg font-medium">
                                    {totalHistoryParams}
                                  </div>
                                </span>
                              )}
                          </div>{" "}
                        </h1>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className="w-full flex justify-center">
            {auth.roles && auth.roles.includes("2001") && (
              <div className=" bg-[#e7e7e7] dark:bg-[#4e4e4e] rounded-lg text-center shadow-xl  w-4/5 md:w-2/5 lg:w-4/5	xl:w-11/12 flex flex-col mt-1">
              <div className="w-full h-12 p-2.5 bg-zinc-950 rounded-tl-xl rounded-tr-xl justify-center items-center gap-2.5 inline-flex">
                  <div className="text-center text-white text-2xl font-bold font-['SF Pro Text']">
Operario                  </div>
                </div>
                <div className="p-6 flex h-80 flex flex-col items-start ">
                  {[
                    {
                      title: "Operaciones",
                      icon: operate,
                      link: "/desingAutomation",
                      description: "Descripción de Design",
                    },
                  ].map((item) => (
                    <div
                      key={item.title}
                      onMouseEnter={() => handleMouseEnter(item.title)}
                      className={`${
                        activeH1 === item.title ? "resaltado-container" : ""
                      }`}
                    >
                      <Link to={item.link} className="text-black">
                        <h1
                          className={`text-2xl text-black dark:text-gray-200 ${
                            activeH1 === item.title ? "resaltado" : ""
                          }`}
                        >
                          <div className="flex flex-row ">
                            <img src={item.icon} className="mr-1 "></img>
                            <p className="mt-1">{item.title}</p>
                          </div>
                        </h1>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        {!auth.roles && (
          <h1 className="text-lg font-calibri dark:text-white text-black">
            You are logged in, but you don't have access to any specific pages.
          </h1>
        )}
      </div>
    </div>
  );
};

export default Home;
