import React, { useState, useEffect } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { LRUCache } from "lru-cache";
const imageCache = new LRUCache({ max: 100 });

const CardDesign = ({ model }) => {
  const [isHovered, setIsHovered] = useState(false);
  const cachedImageURL = imageCache.get(model.thumbnail);
  const [imageURL, setImageURL] = useState(""); // Inicialmente, imageURL está vacío
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
 

    if (cachedImageURL) {
      console.error("la imagen esta cacheada")
      // Si la URL de la imagen está en caché, asignarla directamente
      setImageURL(cachedImageURL);
    } else {
      console.error("la imagen no esta cacheada")

      // Si no está en caché, obtener la URL de la imagen usando el gancho
      async function fetchImageURL() {
        try {
          const response = await axiosPrivate.get("/s3/get-image-url", {
            params: {
              folderName: "thumbnails",
              originalName: model.thumbnail,
            },
          });
          const newImageUrl = response.data.url;

          imageCache.set(model.thumbnail, newImageUrl);

          setImageURL(newImageUrl);
        } catch (error) {
          console.error("Error fetching image URL:", error);
        }
      }

      if (model) {
        fetchImageURL();
      }
    }
  }, [ model]);

  const cardClasses = `m-1 flex justify-center items-center shadow-xl ${
    isHovered
      ? "border-2 border-blue-500 rounded-2xl  transform scale-105 transition-transform duration-300"
      : "border rounded-2xl"
  }`;
  return (

    <div
    className={`w-56 h-72 p-4 bg-white rounded-2xl shadow flex-col justify-start items-start gap-3 inline-flex ${cardClasses}`}
    onMouseEnter={() => setIsHovered(true)}
    onMouseLeave={() => setIsHovered(false)}
  >
        <div className="self-stretch h-40 flex-col justify-start items-start gap-6 flex">
          <img src={imageURL} alt="card image" className="w-48 h-48" />
        </div>

        <div className="self-stretch h-28 flex-col justify-start items-start gap-6 flex">
          <div className="self-stretch h-20 flex-col justify-start items-start gap-2 flex">
            <div className="text-blue-700 text-xl font-bold">{model.name}</div>
            <div className="text-zinc-950 text-xs font-normal line-clamp-2">
              {model.address.description}
            </div>
          </div>
        </div>
      </div>
  );
};

export default CardDesign;
