// Importa los componentes necesarios
import React, { useState, useEffect } from "react";
import AdskLogin from "./designApprovalComponents/AdskLogin";
import TableRow from "./designApprovalComponents/TableRow";
import useAuth from "./../../../hooks/useAuth";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useApsRefreshToken from "../../../hooks/useApsRefreshToken";

const DesingAutomationApproval = () => {
  const [cardsData, setCardsData] = useState([]);
  const [selectedModel, setSelectedModel] = useState(null); // Estado para el modelo seleccionado
  const [filterselected, setFilterSelected] = useState("pending");
  const [triggerTable, settriggerTable] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);


  const [error, setError] = useState(null);
  const { auth } = useAuth();
  const companyID = auth?.companyID;
  const { token } = useApsRefreshToken();
  const api = useAxiosPrivate();
  const rotateAnimation = {
    animation: 'rotate 1s linear'
  };
  // Obtiene los nombres de todos los modelos para un companyID
  const getModels = async (companyID) => {
    try {
      const response = await api.get(`/models?companyID=${companyID}`);
      return response.data.map((model) => ({
        id: model._id,
        name: model.name,
        modelURL: model.modelURL,
        revitYear: model.revitYear,
      }));
    } catch (error) {
      console.error("There was an error fetching the models:", error);
      throw error;
    }
  };

  useEffect(() => {
    let isMounted = true; // Para evitar actualizaciones de estado en componentes desmontados
    setIsAnimating(true);

    const fetchCardsData = async () => {
      console.log("Fetcheo el historial");
      try {
        if (companyID) {
          const models = await getModels(companyID);
          const historyResponse = await api.get(
            `/history?companyID=${companyID}`
          );
          const historyData = historyResponse.data;
          // Crear un mapa para agrupar historyParams por modelo
          const cardsByModel = new Map();
          historyData.forEach((item) => {
            const model = models.find((model) => model.id === item.modelID);
            if (model) {
              if (!cardsByModel.has(model.id)) {
                cardsByModel.set(model.id, {
                  model: model,
                  cards: [],
                });
              }
              cardsByModel.get(model.id).cards.push(...item.historyParams);
            }
          });

          const cardsWithData = Array.from(cardsByModel.values());

          if (isMounted) {
            setCardsData(cardsWithData);
          }
        }
      } catch (err) {
        if (isMounted) {
          setError(err.message);
        }
      }
    };

    fetchCardsData();
    setTimeout(() => setIsAnimating(false), 1000); // 1000 ms = 1 segundo

    return () => {
      isMounted = false; // Limpieza para evitar fugas de memoria
    };
  }, [companyID, token, triggerTable]);

  // Función para manejar el cambio de modelo seleccionado
  const handleModelChange = (event) => {
    const selectedModelId = event.target.value;
    setSelectedModel(selectedModelId);
    settriggerTable(!triggerTable);
  };
  //Setea el valor del filtro
  const handleFilterChange = (event) => {
    setFilterSelected(event.target.value);
    settriggerTable(!triggerTable);
  };

  // Filtra las cards por el modelo seleccionado
  const filteredCards = cardsData.find(
    (model) => model.model.id === selectedModel
  );
  console.log({ filteredCards });

  function refreshTable() {
    settriggerTable(!triggerTable);
  }

  return (
    <div className="flex flex-col items-center justify-center mt-10  ">
      <div className="absolute  mt-16 inset-0 bg-gradient-to-b from-green-400 to-blue-400 h-96  z-0"></div>

      <div className="max-w-screen-lg z-10 md:mt-10 mt-2">
        <div className="text-indigo-950 text-4xl font-extrabold font-['SF Pro Text'] p-1 flex justify-center md:justify-start">
          Updates approval
        </div>
        {!selectedModel && (
          <div className="text-center text-black text-lg font-extrabold  mt-12">
            Please select a model
          </div>
        )}
        <div
          className={`flex ${
            !selectedModel ? "justify-center" : "justify-start "
          } z-10  mt-3 p-1 flex flex-col md:flex-row justify-center item-center md:justify-start`}
        >
          <div className="flex flex-row justify-center item-center md:justify-start gap-3">
            <div className="flex flex-col justify-start item-center">
              {selectedModel && (
                <div className=" ml-1 text-black text-lg font-extrabold ">
                  Model:
                </div>
              )}
              <select
                onChange={handleModelChange}
                value={selectedModel}
                className="md:w-64 w-40  px-4 py-2 bg-white rounded-xl border border-zinc-950 justify-between items-start"
              >
                <option value="">Select...</option>
                {cardsData.map((model) => (
                  <option key={model.model.id} value={model.model.id}>
                    {model.model.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col justify-content item-center">
              {selectedModel && (
                <div>
                  <div className=" ml-1  text-black text-lg font-extrabold ">
                    Status:
                  </div>
                  <select
                    className="md:w-64 w-40 px-4 py-2 bg-white rounded-xl border border-zinc-950 justify-between items-start"
                    value={filterselected}
                    onChange={handleFilterChange}
                  >
                    <option value="approved">Approved</option>
                    <option value="pending" selected>
                      Pending
                    </option>
                    <option value="inprogress">Processing</option>
                    <option value="failed">Error processing</option>
                    <option value="canceled">Canceled</option>
                  </select>
                </div>
              )}
            </div>
          </div>
          {selectedModel && (
            <div className="flex mt-6 justify-center item-center md:ml-8">
              <AdskLogin />
            </div>
          )}
          {selectedModel && (
            <div
              className="flex mt-6 justify-center item-center md:ml-6 cursor-pointer"
              onClick={refreshTable}
            >
 <svg
        width="49"
        height="49"
        viewBox="0 0 49 49"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={isAnimating ? rotateAnimation : null}
      ><path d="M35.7929 12.7327C32.8929 9.83271 28.9129 8.03271 24.4929 8.03271C15.6529 8.03271 8.51294 15.1927 8.51294 24.0327C8.51294 32.8727 15.6529 40.0327 24.4929 40.0327C31.9529 40.0327 38.1729 34.9327 39.9529 28.0327H35.7929C34.1529 32.6927 29.7129 36.0327 24.4929 36.0327C17.8729 36.0327 12.4929 30.6527 12.4929 24.0327C12.4929 17.4127 17.8729 12.0327 24.4929 12.0327C27.8129 12.0327 30.7729 13.4127 32.9329 15.5927L26.4929 22.0327H40.4929V8.03271L35.7929 12.7327Z" fill="black"/>
</svg>

            </div>
          )}
        </div>
        {selectedModel && (
          <table className="table-fixed rounded-lg w-full dark:bg-gray-800 text-black dark:text-white shadow-xl z-10 mt-8">
            <thead className="font-medium rounded-t-lg h-10 w-full">
              <tr className="bg-[#050505] dark:bg-gray-700 text-white dark:text-gray-300 text-center w-fit text-sm">

                <th>User</th>
                <th>Date</th>
                <th>Summary</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="rounded-b-lg shadow-xl">
              {filteredCards.cards.map((card, index) => (
                <TableRow
                  key={index}
                  user={card.user}
                  date={card.date}
                  record={card.record}
                  historyID={card._id}
                  modelURL={filteredCards.model.modelURL}
                  modelID={filteredCards.model.id}
                  modelYear={filteredCards.model.revitYear}
                  status={card.status}
                  filterselected={filterselected}
                  refreshTable={refreshTable}
                />
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default DesingAutomationApproval;
// Estilos globales en JSX
const styleSheet = document.styleSheets[0];
styleSheet.insertRule(`
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`, styleSheet.cssRules.length);