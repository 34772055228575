import React, { useState } from "react";
import ModelTableRow from "./ModelTableRow";

const ModelTable = ({ models, modelImageURLs, setModels ,history}) => {
  const [selectedOption, setSelectedOption] = useState("general"); // Estado para rastrear la opción seleccionada

  // Función para ordenar la tabla por un campo específico
  const sortByField = (field) => {
    const sortedModels = [...models].sort((a, b) => {
      if (a[field] < b[field]) return -1;
      if (a[field] > b[field]) return 1;
      return 0;
    });
    setModels(sortedModels);
  };

  // Función para cambiar la opción seleccionada
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div className="flex flex-wrap ">
      <div className="w-96 h-11 justify-start items-center gap-20 inline-flex">
        <div className="h-11 justify-start items-center gap-6 flex mb-7">
          <div className="text-indigo-950 text-4xl font-extrabold font-['SF Pro Text']">
            Analytics
          </div>

          <select
            className="text-black text-lg font-medium font-['Roboto'] h-10 rounded-xl border border-zinc-950 justify-center  items-start flex w-52 py-2 px-4"
            value={selectedOption}
            onChange={handleOptionChange}
          >
            <option value="general" selected>
              General
            </option>
            {/* <option value="comparacion">Comparision</option> */}
          </select>
        </div>
      </div>

      {/* Renderizar contenido condicionalmente en función de la opción seleccionada */}
      {selectedOption === "general" ? (
        <table className="table-fixed rounded-xl border border-gray-300 dark:border-gray-700 w-full dark:bg-gray-800 text-black dark:text-white shadow-xl">
          <thead className="border-b font-medium dark:border-neutral-500 rounded-t-xl">
            <tr className="bg-[#050505] dark:bg-gray-700 text-white dark:text-gray-300 text-left">
              <th></th>
              <th
                className="px-4 py-2 cursor-pointer"
                onClick={() => sortByField("name")}
              >
                NAME
              </th>
              <th className="px-4 py-2">VARIATION</th>
              <th className="px-4 py-2">CONDITION</th>
            </tr>
          </thead>
          <tbody>
            {models.map((model) => (
              <ModelTableRow
                key={model._id}
                model={model}
                history={history}
                modelImageURL={modelImageURLs[model._id]}
                sortByField={sortByField}
              />
            ))}
          </tbody>
        </table>
      ) : (
        <div className="ml-12">EN CONSTRUCCION!</div>
      )}
    </div>
  );
};

export default ModelTable;
