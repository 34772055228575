import React, { useState, useRef, useEffect } from "react";
import lottie from "lottie-web";

const SubmitElements = ({
  handleModalClose,
  handleClick,
  elements,
  isUploadingPhotos,
}) => {
  const [downloaded, setDownloaded] = useState(false);
  const lottieContainer = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      container: lottieContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: "https://lottie.host/78201082-d6e0-4d1b-a355-7c041088cee9/zuGM9i7AQB.json",
    });
  }, [isUploadingPhotos]);


  const downloadJSON = () => {
    const jsonData = JSON.stringify(elements);
    const blob = new Blob([jsonData], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "elements.json";
    a.click();
    URL.revokeObjectURL(url);
    setDownloaded(true);
  };
  console.log(isUploadingPhotos);

  return (
    <div className="w-full lg:w-2/4 2xl:w-1/4 md:w-2/4 h-fit p-6 bg-white rounded shadow-xl flex-col justify-start items-start gap-9 inline-flex">
      <div className="self-stretch h-fit flex-col justify-start items-center gap-6 flex">
        {downloaded ? (
          <div className="self-stretch h-44 w-full p-6 flex-col justify-start items-start gap-9 inline-flex">
            <div className="self-stretch h-fit w-full flex-col justify-start items-start gap-3 flex">
              <div className="w-full h-24 px-4 py-7 bg-green-400 rounded-xl border border-zinc-950 justify-center items-center gap-20 inline-flex">
                <div className="grow shrink basis-0 text-center text-black text-lg font-normal">
                  Tu archivo fue descargado
                </div>
              </div>
              <div className="justify-start items-start gap-3 inline-flex">
                <div className="justify-start items-start gap-3 flex">
                  <div
                    className="px-6 py-2 rounded border border-green-400 cursor-pointer justify-center items-center gap-2.5 flex"
                    onClick={() => setDownloaded(false)}
                  >
                    <div className="text-zinc-950 text-lg font-normal">
                      Cerrar
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : isUploadingPhotos ? (
          <div className="self-stretch h-44 p-6 w-full flex-col justify-start items-start gap-9 inline-flex">
            <div className="self-stretch w-full h-fit flex-col justify-center items-center gap-3 flex">
                <div  className=" " ref={lottieContainer} style={{ height: 100, width: 120 }}></div>
                <h1 className="font-bold text-2xl">Uploading elements...</h1>
            </div>
          </div>
        
        ) : (
          <>
            {/* <div className="self-stretch  text-center text-black text-lg font-normal">
              {isUploadingPhotos ? "Subiendo diseño, no cierre la pestaña" : "If you are not currently connected to the internet, please \"Export changes\" for later uploading."}
            </div> */}
            <div className="self-stretch h-fit flex-col justify-start items-center gap-3 flex">
              <div
                className="self-stretch h-fit px-6 py-3 bg-blue-700 rounded justify-center items-center gap-2.5 inline-flex cursor-pointer"
                onClick={handleClick}
              >
                <div className="text-green-400 text-lg font-normal">
                  Enviar cambios
                </div>
              </div>
              <div
                className="self-stretch h-11 px-6 py-3 bg-blue-700 rounded justify-center items-center gap-2.5 inline-flex cursor-pointer"
                onClick={downloadJSON}
              >
                <div className="text-green-400 text-lg font-normal">
                  Exportar Cambios
                </div>
              </div>
              <div
                className="self-stretch h-fit px-6 py-3 rounded border border-green-400 justify-center items-center gap-2.5 inline-flex cursor-pointer"
                onClick={() => handleModalClose("confirmModal")}
                disabled={isUploadingPhotos}
              >
                <div className="text-zinc-950 text-lg font-normal">Cerrar</div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SubmitElements;
