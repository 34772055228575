import React, { useState, useEffect } from "react";
import useAxiosPrivate from "../../../src/hooks/useAxiosPrivate";

export const ViewerStates = ({ viewer, selectedModel }) => {
  const axiosPrivate = useAxiosPrivate();
  const [viewerStates, setViewerStates] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [viewName, setViewName] = useState("");
  const [isFirstPerson, setIsFirstPerson] = useState(true);

  // Función para cargar los estados del visor desde el backend
  const loadViewerStates = () => {
    axiosPrivate
      .get(`/models/getViewerStates`, {
        params: { id: selectedModel._id },
      })
      .then((response) => {
        setViewerStates(response.data);
      })
      .catch((error) => {
        console.error("Error al obtener los viewerStates:", error);
      });
  };

  useEffect(() => {
    loadViewerStates();
  }, []);

  // Manejar la seleccion de un estado del visor
  const handleSelectState = (event) => {
    const viewName = event.target.value;
    const state = viewerStates.find((s) => s.viewName === viewName);
    setSelectedState(state);
    console.log({ viewerStates });
    viewer.restoreState(state);
    if (state.isFirstPerson) {
      viewer.getExtension("Autodesk.BimWalk").activate();
    } else {
      viewer.getExtension("Autodesk.BimWalk").deactivate();
    }
  };

  // Guardar el estado actual del visor en el backend
  const saveViewerState = async () => {
    const currentState = viewer.getState();
    currentState.viewName = viewName;
    currentState.isFirstPerson = isFirstPerson;

    try {
      await axiosPrivate.patch("/models/updateViewerStates", {
        id: selectedModel._id,
        viewerState: currentState,
      });

      // Recargar los viewerStates
      loadViewerStates();
    } catch (error) {
      console.error("Error al guardar el viewerState:", error);
    } finally {
      setShowModal(false); // Cerrar el modal independientemente del resultado
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  return (
    <div className="w-full h-fit ">
      {!showModal && (
        <div>
          <div className="text-start text-black md:text-lg text-md font-bold ">
            Custom views:
          </div>

          <div className="flex flex-row  gap-2 ">
            <select
              id="viewerStateSelect"
              onChange={handleSelectState}
              className="bg-gray-50 md:w-48 w-40 border h-9 border-gray-900 text-gray-900  text-sm md:text-md pl-2 rounded-xl focus:ring-[#493E97] focus:border-[#493E97] dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#493E97] dark:focus:border-[#493E97]"
            >
              {/* Opción deshabilitada y seleccionada por defecto */}
              <option value="" disabled selected hidden>
                Select..
              </option>

              {/* Resto de las opciones */}
              {viewerStates.map((state) => (
                <option key={state.viewName} value={state.viewName}>
                  {state.viewName}
                </option>
              ))}
            </select>

            <div
              className="w-9 h-9 bg-blue-700 rounded-full inline-flex items-center justify-center text-green-400 text-lg font-bold cursor-pointer"
              onClick={() => {
                setShowModal(true);
              }}
            >
              +
            </div>
          </div>
        </div>
      )}
      {/* Modal para guardar el estado del visor */}
      {showModal && (
        <div
          id="saveViewerStateModal"
          className="w-full h-full mt-4 z-30 "
          onKeyUp={(e) => {
            if (e.key === "Escape") {
              handleModalClose();
            }
          }}
          onClick={(e) => {
            if (e.target.id === "saveViewerStateModal") {
              handleModalClose();
            }
          }}
        >
          <div className=" dark:bg-gray-700 rounded shadow-xl bg-gray-100">
            <div className="flex flex-col justify-start p-2">
              <label
                htmlFor="viewName"
                className="font-bold dark:text-white mb-2"
              >
                View name:
              </label>
              <input
                id="viewName"
                type="text"
                className="border rounded-md px-2 text-sm py-2 w-full border-black md:mt-1 flex dark:bg-gray-700 dark:border-gray-800 dark:text-gray-100 bg-gray-100"
                placeholder="Enter a descriptive name                "
                value={viewName}
                onChange={(e) => setViewName(e.target.value)}
              />

              <label htmlFor="isFirstPerson" className="flex items-center mt-2">
                <input
                  id="isFirstPerson"
                  type="checkbox"
                  checked={isFirstPerson}
                  onChange={(e) => setIsFirstPerson(e.target.checked)}
                />
                <span className="ml-2 dark:text-white">First Person View</span>
              </label>

              <div className="flex flex-row justify-between mt-2 item-center ">
                <div
                  className="w-28 h-11 px-6 py-3 bg-blue-700 rounded justify-center items-center gap-2.5 inline-flex cursor-pointer"
                  onClick={saveViewerState}
                >
                  <div className="text-green-400 text-lg font-medium font-['Roboto']">
                    Create
                  </div>
                </div>

                <div
                  className="w-28 h-11 px-6 py-3 rounded border border-blue-700 justify-center items-center gap-2.5 inline-flex cursor-pointer"
                  onClick={handleModalClose}
                >
                  <div className="text-blue-700 text-lg font-medium font-['Roboto']">
                    Cancel
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
