import React, { createContext, useContext, useState, useEffect } from "react";
import useAuth from "../hooks/useAuth";

const CompanyContext = createContext();

export const useCompanyContext = () => {
  return useContext(CompanyContext);
};

export const CompanyProvider = ({ children }) => {
  const [companyData, setCompanyData] = useState(null);
  const { persist } = useAuth();

  // Cargar los datos de Session Storage al inicializar el componente
  useEffect(() => {
    if (persist) {
      const storedCompanyData = sessionStorage.getItem("companyData");
      if (storedCompanyData) {
        setCompanyData(JSON.parse(storedCompanyData));
      }
    }
  }, [persist]);

  // Guardar los datos en Session Storage cada vez que cambien
  useEffect(() => {
    if (companyData && persist) {
      sessionStorage.setItem("companyData", JSON.stringify(companyData));
    }
  }, [companyData, persist]);

  const setCompanyInfo = (data) => {
    setCompanyData(data);
  };

  return (
    <CompanyContext.Provider value={{ companyData, setCompanyInfo }}>
      {children}
    </CompanyContext.Provider>
  );
};
