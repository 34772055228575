import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from 'sonner';

import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useAuth from "./../../../hooks/useAuth";

const EditUser = () => {
  const { id } = useParams();
  const { auth } = useAuth();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [models, setModels] = useState([]);
  const [user, setUser] = useState({
    id: "",
    roles: [],
    active: "",
    password: "",
    models: [],
  });
  
  const ROLES = {
    User: 2001,
    Manager: 1984,
    Admin: 5150,
  };
  const companyID = auth?.companyID;

  // Carga los datos del usuario cuando el componente se monta
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const fetchUserData = async () => {
      try {
        const response = await axiosPrivate.get(`/users/getUserById/${id}`, {
          signal: controller.signal,
        });
        isMounted && setUser(response.data);
        setUser((prevUser) => ({
          ...prevUser,
          models: response.data.models || [],
        }));
      } catch (err) {
        console.error("Error fetching user data:", err);
      }
    };
    fetchUserData();
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [id]);

  // Carga los modelos cuando el ID de la compañía cambia
  useEffect(() => {
    if (companyID) {
      let isMounted = true;
      const controller = new AbortController();
      const getModels = async () => {
        try {
          const response = await axiosPrivate.get(
            `/models?companyID=${companyID}`,
            {
              signal: controller.signal,
            }
          );
          isMounted && setModels(response.data);
        } catch (err) {
          console.error("Error fetching models:", err);
        }
      };
      getModels();
      return () => {
        isMounted = false;
        controller.abort();
      };
    }
  }, [companyID]);

  // Maneja la selección de modelos en el formulario
  const handleCreateObject = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions);

    const selectedModels = selectedOptions.map((option) => ({
      modelID: option.value,
      modelName: option.textContent,
    }));

    setUser({
      ...user,
      models: selectedModels,
    });
  };

  // Maneja cambios en los campos de entrada
  const handleInputChange = (event) => {
    const { name, type } = event.target;
    if (type === "checkbox") {
      setUser({
        ...user,
        [name]: event.target.checked,
      });
    } else if (name === "roles") {
      const selectedRoles = Array.from(event.target.selectedOptions).map(
        (option) => option.value
      );
      setUser({
        ...user,
        roles: selectedRoles,
      });
    }
  };

  // Determina el valor predeterminado del select de roles
  const defaultRole = () => {
    if (user.roles.length === 3) {
      return "Admin";
    } else if (user.roles.length === 2) {
      return "Manager";
    } else {
      return "User";
    }
  };

  // Maneja el envío del formulario
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let roleValues = [];

      if (user.roles.length === 3) {
        roleValues = [5150, 1984, 2001];
      } else if (user.roles.length === 2) {
        roleValues = [1984, 2001];
      } else {
        roleValues = [2001];
      }
      const response = await axiosPrivate.patch(`/users/${id}`, {
        ...user,
        roles: roleValues,
      });

      toast.success('User edited successfully');
      setTimeout(function() {
        navigate("/admin")
      }, 1200); // 1000 milisegundos = 1 segundo

    } catch (error) {
      toast.error('Error')
      setTimeout(function() {
        navigate("/admin")
      }, 1200); // 1000 milisegundos = 1 segundo
      console.error("Error updating user:", error);
    }
  };

  return (
    <div className="min-h-screen flex justify-center">
      <div className="dark:text-gray-200 p-8 w-96">
        <h2 className="text-2xl font-bold mb-4">Edit User: {user.email}</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="roles"
              className="block text-sm font-medium text-gray-600 dark:text-gray-200"
            >
              Roles:
            </label>
            <select
              id="roles"
              name="roles"
              value={defaultRole()}
              onChange={handleInputChange}
              className="mt-1 p-2 w-full border rounded-md dark:text-gray-200 dark:bg-gray-600"
              required
            >
              <option value="Admin">Admin</option>
              <option value="Manager">Manager</option>
              <option value="User">User</option>
            </select>
          </div>
          <div className="mb-4">
            <label
              htmlFor="active"
              className="block text-sm font-medium text-gray-600 dark:text-gray-200"
            >
              Active:
            </label>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                id="active"
                name="active"
                checked={user.active}
                onChange={handleInputChange}
                className="sr-only peer"
              />
              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-[#493E98] dark:peer-focus:ring-[#493E98] rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#493E98]"></div>
              <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300"></span>
            </label>
          </div>
          <div className="mb-4">
            <label
              htmlFor="models"
              className="block text-sm font-medium text-gray-600 dark:text-gray-200"
            >
              Models:
            </label>
            <select
              id="models"
              name="models"
              multiple
              value={(user.models || []).map((model) => model.modelID)}
              onChange={handleCreateObject}
              className="mt-1 p-2 w-full border rounded-md dark:text-gray-200 dark:bg-gray-600"
            >
              {models.map((model) => (
                <option key={model._id} value={model._id}>
                  {model.name}
                </option>
              ))}
            </select>
          </div>
          <label htmlFor="submitButton" className="relative">
            <span className="absolute top-0 left-0 mt-1 ml-1 h-full w-full rounded bg-black"></span>
            <span className="fold-bold relative inline-block h-full w-full rounded border-2 border-black bg-white px-3 py-1 text-base font-bold text-black transition duration-100 hover:bg-yellow-400 hover:text-gray-900 dark:bg-gray-400">
              Edit User
            </span>
            {/* Botón de envío del formulario, oculto para el usuario */}
            <button
              id="submitButton"
              type="submit"
              style={{ display: "none" }}
            ></button>
          </label>
        </form>
      </div>
    </div>
  );
};

export default EditUser;
