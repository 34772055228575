import React, { useState } from "react";
import { toast } from "sonner";
import { Badge } from "@tremor/react";
import Rating from "react-rating-stars-component";

function renderCategorySVG(category) {
  const categoria = traducirCategoria(category);

  if (categoria.includes("Revit Wall")) {
    return (
      <svg
        width="40"
        height="34"
        viewBox="0 0 40 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 24H20V34H2V24ZM0 12H12V22H0V12ZM14 12H26V22H14V12ZM28 12H40V22H28V12ZM22 24H38V34H22V24ZM2 0H18V10H2V0ZM20 0H38V10H20V0Z"
          fill="black"
        />
      </svg>
    );
  } else if (categoria.includes("Revit Door")) {
    return (
      <svg
        width="36"
        height="37"
        viewBox="0 0 36 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 36.0327V32.0327H4V4.03271C4 2.93271 4.392 1.99138 5.176 1.20871C5.96 0.426048 6.90133 0.0340482 8 0.0327148H28C29.1 0.0327148 30.042 0.424715 30.826 1.20871C31.61 1.99271 32.0013 2.93405 32 4.03271V32.0327H36V36.0327H0ZM24 32.0327H28V4.03271H19V1.83272C20.4667 2.09938 21.6667 2.78272 22.6 3.88272C23.5333 4.98272 24 6.26605 24 7.73271V32.0327ZM16 20.0327C16.5667 20.0327 17.042 19.8407 17.426 19.4567C17.81 19.0727 18.0013 18.598 18 18.0327C18 17.466 17.808 16.9914 17.424 16.6087C17.04 16.226 16.5653 16.034 16 16.0327C15.4333 16.0327 14.9587 16.2247 14.576 16.6087C14.1933 16.9927 14.0013 17.4674 14 18.0327C14 18.5994 14.192 19.0747 14.576 19.4587C14.96 19.8427 15.4347 20.034 16 20.0327Z"
          fill="black"
        />
      </svg>
    );
  } else if (categoria.includes("Revit Floor")) {
    return (
      <svg
        width="40"
        height="41"
        viewBox="0 0 40 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.0070326 40.9592H18.7644V36.4253H0.0070326V40.9592ZM18.7798 29.5932C18.7798 29.5017 18.7693 29.4096 18.7616 29.2894H0V33.8423C0.0935335 33.8521 0.172299 33.8676 0.251767 33.8676C6.3223 33.869 12.3928 33.8669 18.4634 33.8746C18.7447 33.8746 18.7876 33.7677 18.7855 33.5237C18.7756 32.2135 18.7812 30.904 18.7798 29.5939V29.5932ZM4.55431 0.981748H0.0119554V19.7222H4.55431V0.981748ZM39.9902 15.1475H21.23V19.6821H39.9902V15.1475ZM18.7102 0.96698H14.1735V19.7285H18.7102V0.96698ZM11.6263 0.968386H7.09237V19.7278H11.6263V0.968386ZM18.3663 22.1787C12.3661 22.1892 6.36591 22.1864 0.364992 22.1871C0.25036 22.1871 0.136432 22.197 0.00562608 22.2026V26.7668H18.7805V26.3652C18.7805 25.1134 18.7686 23.8616 18.7883 22.6105C18.7932 22.2779 18.7102 22.178 18.3663 22.1787ZM21.2391 12.5982H40V8.06428H21.2391V12.5982ZM21.2398 0.979639V5.51707H40V0.979639H21.2398ZM35.4436 40.967H39.993V22.2089H35.4436V40.967ZM32.5806 22.1822C31.2472 22.1921 29.9138 22.1864 28.5805 22.1878C28.5003 22.1878 28.4208 22.1991 28.3189 22.2075V40.967H32.8739C32.8823 40.8706 32.895 40.7919 32.895 40.7124C32.8957 34.6419 32.895 28.5706 32.9006 22.5001C32.9006 22.2532 32.8232 22.1815 32.5806 22.1829V22.1822ZM21.6358 22.1787C21.2996 22.1731 21.2061 22.2631 21.2068 22.6035C21.2173 28.5924 21.2145 34.5814 21.2145 40.5703V40.9656H25.7934V22.1857C25.6437 22.1857 25.5171 22.1857 25.3905 22.1857C24.1387 22.1857 22.8869 22.1977 21.6358 22.178V22.1787Z"
          fill="black"
        />
      </svg>
    );
  } else if (categoria.includes("Revit Roof")) {
    return (
      <svg
        width="42"
        height="44"
        viewBox="0 0 42 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.49605 3.53732H14.1709V6.80102C16.4967 4.48324 18.7364 2.25196 20.9959 0C22.1261 1.12645 23.2331 2.22846 24.3384 3.33234C30.0101 8.99563 35.6792 14.6608 41.3567 20.3166C41.597 20.5563 41.6947 20.7923 41.6881 21.1525C41.6682 22.2451 41.6806 23.3377 41.6806 24.466H0.313703C0.313703 23.2295 0.30376 21.9978 0.32696 20.767C0.329446 20.624 0.500133 20.467 0.617792 20.3485C3.46397 17.5061 6.31098 14.6655 9.16544 11.8362C9.40324 11.6002 9.50433 11.3651 9.50185 11.0012C9.48693 8.53113 9.49439 6.06008 9.49439 3.53826L9.49605 3.53732Z"
          fill="#060606"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M39.6884 24.466H2.35229V41.9999H39.6884V24.466ZM0.352289 22.466V43.9999H41.6884V22.466H0.352289Z"
          fill="#060606"
        />
      </svg>
    );
  } else if (categoria.includes("Revit Window")) {
    return (
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.95147 2.37421C2.95147 1.26964 3.8469 0.374207 4.95147 0.374207H39.0485C40.1531 0.374207 41.0485 1.26964 41.0485 2.37421V38.0166H42.5928C43.1451 38.0166 43.5928 38.4643 43.5928 39.0166V42.5598C43.5928 43.112 43.1451 43.5598 42.5928 43.5598H1.40723C0.854941 43.5598 0.407227 43.112 0.407227 42.5598V39.0325C0.407227 38.4803 0.854942 38.0325 1.40723 38.0325H2.95147V2.37421ZM20.978 5.11995H8.1471V20.0155H20.978V5.11995ZM8.13698 22.0773V37.9519H20.967V22.0773H8.13698ZM23.0574 5.1065V20.0172H35.8622V5.1065H23.0574ZM35.8563 37.9619V22.0857H23.0557V37.9619H35.8563Z"
          fill="black"
        />
      </svg>
    );
  } else if (categoria.includes("Revit Lighting Fixtures")) {
    return (
      <svg
        width="26"
        height="38"
        viewBox="0 0 26 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.3416 34.8858H16.6584V37.3247H9.3416V34.8858ZM8.12213 31.2274H17.8779V33.6664H8.12213V31.2274ZM21.6445 4.29931C19.3527 2.00443 16.2826 0.740757 13 0.740757C11.3975 0.736938 9.81003 1.04975 8.32879 1.66124C6.84754 2.27273 5.50169 3.17083 4.36855 4.30397C3.23541 5.43711 2.3373 6.78296 1.72582 8.26421C1.11433 9.74546 0.801513 11.3329 0.805332 12.9354C0.805332 16.4886 2.16732 19.8125 4.53995 22.054L4.87225 22.3657C6.24491 23.6492 8.12213 25.4075 8.12213 26.9593V30.008H11.7805V20.032L8.40566 18.1182L9.7989 16.1152L13 17.8133L16.147 16.1068L17.6027 18.064L14.2195 20.0457V30.008H17.8779V26.9593C17.8779 25.4441 19.7345 23.6873 21.0904 22.4038L21.4646 22.0486C23.9051 19.7187 25.1947 16.5641 25.1947 12.9354C25.2027 11.3328 24.8932 9.74441 24.2838 8.2621C23.6744 6.77979 22.7774 5.43292 21.6445 4.29931Z"
          fill="black"
        />
      </svg>
    );
  } else {
    // SVG predeterminado
    return (
      <svg
        width="36"
        height="37"
        viewBox="0 0 36 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M36 32.0327V4.03271C36 1.83271 34.2 0.0327148 32 0.0327148H4C1.8 0.0327148 0 1.83271 0 4.03271V32.0327C0 34.2327 1.8 36.0327 4 36.0327H32C34.2 36.0327 36 34.2327 36 32.0327ZM11 21.0327L16 27.0527L23 18.0327L32 30.0327H4L11 21.0327Z"
          fill="#060606"
        />
      </svg>
    );
  }
}

// Función para traducir las categorías
function traducirCategoria(categoria) {
  const categoriasTraducidas = {
    "Revit Wall": ["Revit Muros", "Revit Wall", "Wall Revit"],
    "Revit Door": ["Revit Puertas ", "Revit Door", "Door Revit"],
    Windows: ["Windows", "Ventanas", "Windows"],
    Roof: ["Roof", "Techos", "Roof"],
    Floor: ["Floor", "Pisos", "Floor"],
    "Lighting Fixtures": [
      "Lighting Fixtures",
      "Accesorios de Iluminación",
      "Lighting Fixtures",
    ],
    // Puedes seguir agregando más categorías aquí si es necesario
  };

  for (const categoriaTraducida in categoriasTraducidas) {
    if (categoriasTraducidas[categoriaTraducida].includes(categoria)) {
      return categoriaTraducida;
    }
  }

  return categoria; // Si no se encuentra una traducción, se devuelve la categoría original
}

function ListOfElements({ elements, setElements, setData, selectProps }) {
  const [expandedElements, setExpandedElements] = useState({});

  const handleDeleteElement = (elementId) => {
    const updatedElements = { ...elements };
    delete updatedElements[elementId];
    toast.custom((t) => (
      <div>
        This is a custom component <button onClick={() => toast.dismiss(t)}>close</button>
      </div>
    ));
    toast.success("Element deleted");
    setElements(updatedElements);
  };

  const handleElementClick = (element) => {
    const stateParam = element.parameters.find((param) =>
      [
        "Condition",
        "State",
        "Estado",
        "state",
        "Condicion",
        "MC-Estado",
      ].includes(param.name)
    );

    if (stateParam) {
      const ObjectdelElement = {
        dbId: element.dbId,
        State: stateParam.value,
      };
      setData([ObjectdelElement], "coloredIds");
    } else {
      const ObjectdelElement = {
        dbId: element.dbId,
        State: 0,
      };
      setData([ObjectdelElement], "coloredIds");
    }
    console.log(selectProps);
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        try {
          const jsonData = JSON.parse(e.target.result);
          setElements(jsonData);
          toast.success("File loaded successfully");
        } catch (error) {
          toast.error("Invalid JSON file");
        }
      };

      reader.readAsText(file);
    }
  };

  const toggleParameters = (elementId) => {
    setExpandedElements((prevState) => ({
      ...prevState,
      [elementId]: !prevState[elementId],
    }));
  };

  console.log(elements);
  return (
    <div
      id="results"
      className=" flex flex-col md:h-[41vh] md:max-h-[41vh] h-full max-h-[48vh] w-full"
      style={{ overflowX: "hidden" }}
    >
      <div className="">
        {elements && Object.keys(elements).length > 0 ? (
          Object.values(elements).map((element, index) => (
            <div
              key={element.elementId}
              className={`w-full h-fit mt-1 mb-1 flex flex-row text-left overflow-hidden  text-sm cursor-pointer  border-b border-slate-200 p-1`}
              onClick={() => handleElementClick(element)}
            >
              <div className="flex flex-row items-center w-full">
                {/* imagenes */}
                <div className="w-1/3 flex justify-center ">
                  <div className="w-fit h-fit  ">
                    {renderCategorySVG(element.category)}
                  </div>
                </div>
                <div className="flex flex-col text-md w-2/3 ">
                  <p className="font-bold">
                    {element.category.replace(/^Revit\s*/, "")}
                  </p>
                  <p className=" text-xs">{element.name}</p>
                  {expandedElements[element.elementId] && (
                    <div className="mt-1 text-xs">
                      {element.parameters.map(
                        (param, paramIndex) =>
                          param.value !== "" && (
                            <div
                              key={paramIndex}
                              className="text-xs flex flex-col justify-center border-b items-center"
                            >
                              <p className="font-bold">{param.name}</p>
                              {param.name === "ImageURL" ||
                              (typeof param.value === "number" &&
                                (param.value === 0 || param.value === 1)) ? (
                                param.value ? (
                                  <Badge size="xs" color="green">
                                    True
                                  </Badge>
                                ) : (
                                  <Badge size="xs" color="red">
                                    False
                                  </Badge>
                                )
                              ) : param.name === "Condition" ? (
                                <Rating
                                  value={param.value}
                                  size={19}
                                  activeColor="#ffd700"
                                />
                              ) : (
                                <p>{param.value}</p>
                              )}
                            </div>
                          )
                      )}
                    </div>
                  )}
                </div>
              </div>

              {/* Boton eliminar y expandir card */}
              <div className="flex flex-col justify-center items-center h-fit">
                <div className="items-center flex font-bold">
                  <div
                    className="cursor-pointer hover:text-red-700"
                    onClick={() => handleDeleteElement(element.elementId)}
                  >
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19.111 6.47537L17.701 5.06537L12.111 10.6554L6.52096 5.06537L5.11096 6.47537L10.701 12.0654L5.11096 17.6554L6.52096 19.0654L12.111 13.4754L17.701 19.0654L19.111 17.6554L13.521 12.0654L19.111 6.47537Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                </div>

                <div className="items-center flex">
                  <div
                    className="cursor-pointer hover:text-gray-700  font-bold"
                    onClick={() => toggleParameters(element.elementId)}
                  >
                    {expandedElements[element.elementId] ? (
                      <svg
                        width="25"
                        height="25"
                        viewBox="0 0 25 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.9859 6.06146L9.57593 7.47146L14.1559 12.0615L9.57593 16.6515L10.9859 18.0615L16.9859 12.0615L10.9859 6.06146Z"
                          fill="black"
                        />
                      </svg>
                    ) : (
                      <svg
                        width="25"
                        height="25"
                        viewBox="0 0 25 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18.111 9.76651L16.701 8.35651L12.111 12.9365L7.52096 8.35651L6.11096 9.76651L12.111 15.7665L18.111 9.76651Z"
                          fill="black"
                        />
                      </svg>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="flex items-center justify-center border rounded-xl border-black w-full p-5 mt-4">
            <p>No se encontraron elementos</p>
          </div>
        )}
        <div className="flex items-center justify-center mb-2">
          <input
            type="file"
            accept=".json"
            onChange={handleFileInputChange}
            className="mr-2 hidden"
            id="fileInput"
          />
          <label
            htmlFor="fileInput"
            className="w-44 h-9 px-6 py-3  rounded justify-center items-center gap-2.5 inline-flex text-[#104cf8] text-md font-bold  cursor-pointer  border border-blue-700"
          >
            <svg
              width="37"
              height="37"
              viewBox="0 0 37 37"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.11096 18.8154C3.11096 14.2554 6.80096 10.5654 11.361 10.5654H27.111C30.426 10.5654 33.111 13.2504 33.111 16.5654C33.111 19.8804 30.426 22.5654 27.111 22.5654H14.361C12.291 22.5654 10.611 20.8854 10.611 18.8154C10.611 16.7454 12.291 15.0654 14.361 15.0654H25.611V18.0654H14.226C13.401 18.0654 13.401 19.5654 14.226 19.5654H27.111C28.761 19.5654 30.111 18.2154 30.111 16.5654C30.111 14.9154 28.761 13.5654 27.111 13.5654H11.361C8.46596 13.5654 6.11096 15.9204 6.11096 18.8154C6.11096 21.7104 8.46596 24.0654 11.361 24.0654H25.611V27.0654H11.361C6.80096 27.0654 3.11096 23.3754 3.11096 18.8154Z"
                fill="#104cf8"
              />
            </svg>
            Insertar
          </label>
        </div>
      </div>
    </div>
  );
}

export default ListOfElements;
