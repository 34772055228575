import React from "react";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";

const ConfirmModelDelete = ({
  setshowDeleteDesingWindow,
  modelID,
  historyID,
  refreshTable
}) => {
  const api = useAxiosPrivate();

  const handleNoClick = () => {
    setshowDeleteDesingWindow(false);
  };

  const handleYesClick = async () => {
    // Actualizar el historial
    let status = "canceled";
    console.log({ historyID, modelID });
    const historyResponse = await api.post(
      `${process.env.REACT_APP_BASE_URL}/history/updateHistoryParams`,
      {
        modelID,
        historyID,
        status,
      }
    );
    setshowDeleteDesingWindow(false);

    console.log({ historyResponse });
    console.log("Sending new model data:", { status, historyID });
    refreshTable()
  };

  return (
    <div className="flex h-full bg-white">
      <div className="h-full w-full flex items-center justify-center">
        <div className="bg-white p-4  ">
          <p className="text-center text-3xl font-semibold mb-4">
            Do you want to delete this survey?
          </p>
          <div className="flex justify-center gap-4">
            <button
              className="w-1/2 h-12 px-4 py-1 font-bold bg-[#084BF1] hover:bg-blue-600 rounded-md text-[#00ff82]"
              onClick={handleYesClick}
            >
              Delete
            </button>
            <button
              className="w-1/2 h-12 px-4 py-1 border-2 border-[#21FD80] hover:bg-[#21FD80] rounded-md text-black"
              onClick={handleNoClick}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModelDelete;
