import React from "react";
import { CategoryBar } from "@tremor/react";
import { Link } from "react-router-dom";
import { BadgeDelta } from "@tremor/react";

const ModelTableRow = ({ model, modelImageURL, history }) => {
  // Función para calcular la variación con respecto al mes anterior
  const calculateVariation = () => {
    const currentModelHistory = history.find((item) => item.modelID === model._id);
    if (currentModelHistory && currentModelHistory.historyState) {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1;
      const previousYear = currentMonth === 1 ? currentYear - 1 : currentYear;
      const previousMonth = currentMonth === 1 ? 12 : currentMonth - 1;
  
      const historyState = currentModelHistory.historyState;
      const currentYearStr = currentYear.toString();
      const currentMonthStr = currentMonth.toString().padStart(2, "0");
      const previousYearStr = previousYear.toString();
      const previousMonthStr = previousMonth.toString().padStart(2, "0");
  
      if (
        historyState[currentYearStr] &&
        historyState[currentYearStr][currentMonthStr] &&
        historyState[previousYearStr] &&
        historyState[previousYearStr][previousMonthStr]
      ) {
        const currentGeneralState = historyState[currentYearStr][currentMonthStr].generalState;
        const previousGeneralState = historyState[previousYearStr][previousMonthStr].generalState;
  
        return (
          ((currentGeneralState - previousGeneralState) /
            previousGeneralState) *
          100
        ).toFixed(2);
      }
    }
    return 0;
  };
  

  // Función para determinar el color y el texto según el valor de la variación
  const getBadgeDelta = (variantState) => {
    let deltaType = "unchanged";
    let text = "Unchanged";

    const variation = calculateVariation();

    if (variation > 5) {
      deltaType = "increase";
      text = "Increase";
    } else if (variation > 0) {
      deltaType = "moderateIncrease";
      text = "Moderate Increase";
    } else if (variation < -5) {
      deltaType = "decrease";
      text = "Decrease";
    } else if (variation < 0) {
      deltaType = "moderateDecrease";
      text = "Moderate Decrease";
    }

    return (
      <BadgeDelta deltaType={deltaType}>
        {`${variation}%`} {/* Mostrar la variación en porcentaje */}
      </BadgeDelta>
    );
  };

  return (
    <tr
      key={model._id}
      className={`hover:bg-gray-100 dark:hover:bg-gray-900 transition duration-300  text-black border-b`}
    >
      <td>
        <img src={modelImageURL} className="w-16 h-10 ml-5 m-2" alt={model.name} />
      </td>
      <td className="px-4 py-2 text-sm">
        <Link to={`/project/${model._id}`} key={model._id}>
          <p className="text-blue-500 dark:text-blue-300"> {model.name}</p>
        </Link>
      </td>
      <td className="px-4 py-2">
        {model && <div className="mt-1">{getBadgeDelta(model.variantState)}</div>}
      </td>
      <td className="px-4 py-2 w-48">
        <CategoryBar
          values={[40, 30, 20, 10]}
          colors={["rose", "orange", "yellow", "emerald"]}
          markerValue={model.actualState * 100}
        />
      </td>
    </tr>
  );
};

export default ModelTableRow;
