import { useRef, useState, useEffect } from "react";
import {
  faCheck,
  faTimes,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "./../../api/axios";
import {  toast } from 'sonner'
import { useNavigate } from "react-router-dom";

const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-zA-Z].*[a-zA-Z].*[a-zA-Z].*[a-zA-Z])/;
const REGISTER_URL = "/auth/register";
const CREATE_COMPANY_URL = "/companies";

const Register = () => {
  const userRef = useRef();
  const errRef = useRef();
  const navigate = useNavigate();

  const [user, setUser] = useState("");
  const [validName, setValidName] = useState(false);
  const [userFocus, setUserFocus] = useState(false);

  const [password, setPwd] = useState("");
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const [confirmPwd, setConfirmPwd] = useState("");
  const [validConfirmPwd, setValidConfirmPwd] = useState(false);
  const [confirmPwdFocus, setConfirmPwdFocus] = useState(false);

  const [companyName, setCompanyName] = useState("");
  const [companyThumbnail, setCompanyThumbnail] = useState("");

  const [errMsg, setErrMsg] = useState("");

  const [layout, setLayout] = useState({
    firstDiv: [],
    secondDiv: [],
  });

  // Definir las variables de estado para firstDiv y secondDiv
  const [firstDiv, setFirstDiv] = useState([]);
  const [secondDiv, setSecondDiv] = useState([]);

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setValidName(USER_REGEX.test(user));
  }, [user]);

  useEffect(() => {
    setValidPwd(PWD_REGEX.test(password));
  }, [password]);

  useEffect(() => {
    setValidConfirmPwd(password === confirmPwd);
  }, [password, confirmPwd]);

  useEffect(() => {
    setErrMsg("");
  }, [user, password, confirmPwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const v1 = USER_REGEX.test(user);
    const v2 = PWD_REGEX.test(password);
    if (!v1 || !v2 || !validConfirmPwd) {
      setErrMsg("Invalid Entry");
      return;
    }
    try {
      // Create the company and get the ID
      const companyResponse = await axios.post(
        CREATE_COMPANY_URL,
        JSON.stringify({
          companyName,
          companyThumbnail,
          layout: {
            firstDiv,
            secondDiv,
          },
        }),
        // {
        //   headers: { "Content-Type": "application/json" },
        //   // withCredentials: true,
        // }
      );

      const companyID = companyResponse.data._id;
      const roles = [5150, 2001, 1984];

      // Create the user with the company ID and selected options
      const response = await axios.post(
        REGISTER_URL,
        JSON.stringify({ user, password, companyID, roles }), // Envía layout como parte del objeto
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );

      console.log(JSON.stringify(response?.data));

      setUser("");
      setPwd("");
      setConfirmPwd("");
      setCompanyName("");
      setCompanyThumbnail("");
      setLayout({
        firstDiv: [],
        secondDiv: [],
      });
      // Reiniciar los valores de firstDiv y secondDiv
      setFirstDiv([]);
      setSecondDiv([]);

      toast.success('Register succesfully');
      setTimeout(function() {
        navigate("/login")
      }, 1200); // 1000 milisegundos = 1 segundo  
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");

      } else if (err.response?.status === 409) {
        setErrMsg("Username Taken");
      } else {
        setErrMsg("Registration Failed");
      }
      errRef.current.focus();
    }
  };

  return (
    <div className="flex justify-center items-center">
      <section>
        <p
          ref={errRef}
          className={errMsg ? "errmsg" : "offscreen"}
          aria-live="assertive"
        >
          {errMsg}
        </p>
        <h1 className="text-2xl font-semibold mb-4 mt-10">Register</h1>
        <form onSubmit={handleSubmit}>
          <div className="">
            <label htmlFor="username" className="block font-medium">
              Username:
            </label>
            <div className="relative">
              <input
                type="text"
                id="username"
                ref={userRef}
                autoComplete="off"
                onChange={(e) => setUser(e.target.value)}
                value={user}
                required
                aria-invalid={validName ? "false" : "true"}
                aria-describedby="uidnote"
                onFocus={() => setUserFocus(true)}
                onBlur={() => setUserFocus(false)}
                className={`w-full rounded border ${
                  validName ? "border-green-500" : "border-red-500"
                } px-3 py-2 focus:outline-none focus:ring-2 focus:ring-${
                  validName ? "green-300" : "red-500"
                } focus:border-transparent`}
              />
              <FontAwesomeIcon
                icon={faCheck}
                className={validName ? "valid" : "hide"}
              />
              <FontAwesomeIcon
                icon={faTimes}
                className={validName || !user ? "hide" : "invalid"}
              />
            </div>
            <p
              id="uidnote"
              className={
                userFocus && user && !validName ? "instructions" : "offscreen"
              }
            >
              <FontAwesomeIcon icon={faInfoCircle} />
              4 to 24 characters.
              <br />
              Must begin with a letter.
              <br />
              Letters, numbers, underscores, hyphens allowed.
            </p>
          </div>

          <div className="">
            <label htmlFor="password" className="block font-medium">
              Password:
            </label>
            <div className="relative">
              <input
                type="password"
                id="password"
                onChange={(e) => setPwd(e.target.value)}
                value={password}
                required
                aria-invalid={validPwd ? "false" : "true"}
                aria-describedby="pwdnote"
                onFocus={() => setPwdFocus(true)}
                onBlur={() => setPwdFocus(false)}
                className={`w-full rounded border ${
                  validPwd ? "border-green-500" : "border-red-500"
                } px-3 py-2 focus:outline-none focus:ring-2 focus:ring-${
                  validPwd ? "green-300" : "red-500"
                } focus:border-transparent`}
              />
              <FontAwesomeIcon
                icon={faCheck}
                className={validPwd ? "valid" : "hide"}
              />
              <FontAwesomeIcon
                icon={faTimes}
                className={validPwd || !password ? "hide" : "invalid"}
              />
              <div className="absolute top-0 right-0 mt-2 mr-2">
                <FontAwesomeIcon icon={faInfoCircle} />
              </div>
            </div>
            <p
              id="pwdnote"
              className={`${
                pwdFocus && !validPwd ? "instructions" : "offscreen"
              } max-w-[300px]`}
            >
              <FontAwesomeIcon icon={faInfoCircle} />
              8 to 24 characters.
              <br />
              Must include uppercase and lowercase letters, a number and a
              special character.
              <br />
              Allowed special characters:{" "}
              <span aria-label="exclamation mark">!</span>{" "}
              <span aria-label="at symbol">@</span>{" "}
              <span aria-label="hashtag">#</span>{" "}
              <span aria-label="dollar sign">$</span>{" "}
              <span aria-label="percent">%</span>
            </p>
          </div>

          <div className="">
            <label htmlFor="confirm_pwd" className="block font-medium">
              Confirm Password:
            </label>
            <div className="relative">
              <input
                type="password"
                id="confirm_pwd"
                onChange={(e) => setConfirmPwd(e.target.value)}
                value={confirmPwd}
                required
                aria-invalid={validConfirmPwd ? "false" : "true"}
                aria-describedby="confirmnote"
                onFocus={() => setConfirmPwdFocus(true)}
                onBlur={() => setConfirmPwdFocus(false)}
                className={`w-full rounded border ${
                  validConfirmPwd && confirmPwd
                    ? "border-green-500"
                    : "border-red-500"
                } px-3 py-2 focus:outline-none focus:ring-2 focus:ring-${
                  validConfirmPwd && confirmPwd ? "green-300" : "red-500"
                } focus:border-transparent`}
              />
              <FontAwesomeIcon
                icon={faCheck}
                className={validConfirmPwd && confirmPwd ? "valid" : "hide"}
              />
              <FontAwesomeIcon
                icon={faTimes}
                className={validConfirmPwd || !confirmPwd ? "hide" : "invalid"}
              />
            </div>
            <p
              id="confirmnote"
              className={
                confirmPwdFocus && !validConfirmPwd
                  ? "instructions"
                  : "offscreen"
              }
            >
              <FontAwesomeIcon icon={faInfoCircle} />
              Must match the password input field.
            </p>
          </div>

          <div className="">
            <label htmlFor="companyName" className="block font-medium">
              Company Name:
            </label>
            <div className="relative">
              <input
                type="text"
                id="companyName"
                onChange={(e) => setCompanyName(e.target.value)}
                value={companyName}
                required
                className="w-full rounded border px-3 py-2 focus:outline-none focus:ring-2 focus:ring-green-300 focus:border-transparent"
              />
            </div>
          </div>

          <div className="">
            <label htmlFor="companyThumbnail" className="block font-medium">
              Company Thumbnail:
            </label>
            <div className="relative">
              <input
                type="text"
                id="companyThumbnail"
                onChange={(e) => setCompanyThumbnail(e.target.value)}
                value={companyThumbnail}
                required
                className="w-full rounded border px-3 py-2 focus:outline-none focus:ring-2 focus:ring-green-300 focus:border-transparent"
              />
            </div>
          </div>

          {/* Add the first select multiple */}
          <div className="">
            <label htmlFor="firstDiv" className="block font-medium">
              First Division:
            </label>
            <div className="relative">
              <select
                id="firstDiv"
                multiple
                onChange={(e) =>
                  setFirstDiv(
                    Array.from(
                      e.target.selectedOptions,
                      (option) => option.value
                    )
                  )
                }
                value={firstDiv}
                required
                className="w-full rounded border px-3 py-2 focus:outline-none focus:ring-2 focus:ring-green-300 focus:border-transparent"
              >
                <option value="GeneralState">GeneralState</option>
                <option value="ProjectCount">ProjectCount</option>
              </select>
            </div>
          </div>

          {/* Add the second select multiple */}
          <div className="">
            <label htmlFor="secondDiv" className="block font-medium">
              Second Division:
            </label>
            <div className="relative">
              <select
                id="secondDiv"
                multiple
                onChange={(e) =>
                  setSecondDiv(
                    Array.from(
                      e.target.selectedOptions,
                      (option) => option.value
                    )
                  )
                }
                value={secondDiv}
                required
                className="w-full rounded border px-3 py-2 focus:outline-none focus:ring-2 focus:ring-green-300 focus:border-transparent"
              >
                <option value="EdilicialState">EdilicialState</option>
                <option value="ElectricalState">ElectricalState</option>
                <option value="WaterState">WaterState</option>
                <option value="StructuralState">StructuralState</option>
              </select>
            </div>
          </div>

          <button
            disabled={!validName || !validPwd || !validConfirmPwd}
            className={`bg-blue-500 hover:bg-blue-600 text-white font-semibold px-4 py-2 rounded ${
              (!validName || !validPwd || !validConfirmPwd) &&
              "opacity-50 cursor-not-allowed"
            }`}
          >
            Create User!
          </button>
        </form>
      </section>
    </div>
  );
};

export default Register;
