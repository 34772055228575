import React, { useState, useEffect } from 'react';
import useAxiosPrivate from './useAxiosPrivate';
import { useCache } from '../context/CacheContext'; 


//USADO PARA CARGAR LAS IMAGENES EN LAS ROW DE UPDATE APROVAL
//revisar funcionamiento de update aproval , la tabla , hay mejores formas de hacerlo

const useImageLoader = (records) => {
    console.log(records)
    const [elementImageURLs, setElementImageURLs] = useState({});
    const api = useAxiosPrivate();
    const imageCache = useCache(); // Usa la caché del contexto

    useEffect(() => {
        const fetchImageURL = async (image) => {
            try {
                const cacheKey = image;
                console.log(cacheKey);

                // Comprobar si la URL está en caché
                const cachedURL = imageCache.get(cacheKey);

                var imageURL = null;

                if (cachedURL) {
                    console.error("Imagen cacheada");
                    imageURL = cachedURL;
                } else {
                    console.error("Imagenes no cacheadas");
                    console.log({ image });

                    // Si la URL no está en caché, realizar la solicitud HTTP
                    if (image !== "") {
                        const response = await api.get("/s3/get-image-url", {
                            params: {
                                folderName: `operate-uploads/${image.split("/")[0]}/${image.split("/")[1]}`,
                                originalName: image.split("/")[2],
                            },
                        });
                        imageURL = response.data.url;
                        console.log({ imageURL });

                        // Guardar la URL en caché
                        imageCache.set(cacheKey, imageURL);
                    }
                }

                return imageURL;
            } catch (error) {
                console.error("Error fetching image URLs", error);
            }
        };

        const loadImage = async () => {
            const newImageURLs = {};
            for (const item of records) {
                const imageParam = item.parameters.find((param) => param.name === 'ImageURL');
        
                // Verificar si imageParam existe y tiene un valor no vacío
                if (imageParam && imageParam.value) {
                    console.log(imageParam)
                    const imageURL = await fetchImageURL(imageParam.value);
                    newImageURLs[imageParam.value] = imageURL;
                }
            }
            setElementImageURLs(newImageURLs);
        };
        

        loadImage();
    }, [records, imageCache]); 

    return elementImageURLs;
};

export default useImageLoader;
