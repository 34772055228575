import React, { useState, useEffect } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useAuth from "./../../../hooks/useAuth";
import { Divider } from "@tremor/react";
import {  toast } from 'sonner'
import { useNavigate } from "react-router-dom";

const EDITMODEL_URL = "/models/updateParams";

const EditParameters = () => {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const [models, setModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [categoryParameters, setCategoryParameters] = useState({});
  const companyID = auth?.companyID;
  const navigate = useNavigate();

  const elementosRevit = [
    "Revit Walls",
    "Revit Curtain Panels",
    "Revit Curtain Wall Mullions",
    "Revit Doors",
    "Revit Roofs",
    "Revit Structural Columns",
    "Revit Windows",
    "Revit Generic Models",
    "Revit Structural Framing",
    "Revit Columns",
    "Revit Ceilings",
    "Revit Plumbing Fixtures",
    "Revit Floors",
    "Revit Railings",
    "Revit Stairs",
    "Revit Runs",
    "Revit Landings",
    "Revit Casework",
    "Revit Mechanical Equipment",
    "Revit Parking",
    "Revit Electrical Equipment",
    "Revit Lighting Fixtures",
    "Revit Pipes",
    "Revit Center Line",
    "Revit Pipe Fittings",
    "Revit Electrical Fixtures",
    "Revit Specialty Equipment",
    "Revit Cable Trays",
    "Revit Cable Tray Fittings",
    "Revit Conduits",
    "Revit Flex Pipes",
    "Revit Plumbing Equipment",
    "Revit Ducts",
    "Revit Duct Fittings",
    "Revit Conduit Fittings",
    "Revit Supports",
    "Revit Air Terminals",
    "Revit Room Separation",
    "Revit Site",
  ];

  const handleModelChange = (e) => {
    setSelectedModel(e.target.value);
    setSelectedCategories([]);
    setCategoryParameters({});
  };

  const handleCategoryChange = (e) => {
    const category = e.target.value;
    const selectedCategoriesCopy = [...selectedCategories];

    if (e.target.checked) {
      selectedCategoriesCopy.push(category);
      setCategoryParameters((prevParameters) => ({
        ...prevParameters,
        [category]: [],
      }));
    } else {
      const index = selectedCategoriesCopy.indexOf(category);
      if (index !== -1) {
        selectedCategoriesCopy.splice(index, 1);
        const { [category]: deletedCategory, ...remainingParameters } =
          categoryParameters;
        setCategoryParameters(remainingParameters);
      }
    }

    setSelectedCategories(selectedCategoriesCopy);
  };

  const handleAddParameter = (category) => {
    const newParameter = {
      name: "",
      dataType: "string",
    };
    setCategoryParameters((prevParameters) => ({
      ...prevParameters,
      [category]: [...(prevParameters[category] || []), newParameter],
    }));
  };

  const handleParameterChange = (e, category, index) => {
    const { name, value } = e.target;
    const updatedParameters = [...categoryParameters[category]];
    updatedParameters[index][name] = value;
    setCategoryParameters((prevParameters) => ({
      ...prevParameters,
      [category]: updatedParameters,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Modelo seleccionado:", selectedModel);
    console.log("Categorías seleccionadas:", selectedCategories);
    console.log("Parámetros por categoría:", categoryParameters);
    if (!selectedModel) {
      alert(
        "There is a problem with your session. Please log in again and contact support if the issue persists."
      );
      return;
    }

    try {
      const response = await axiosPrivate.patch(EDITMODEL_URL, {
        id: selectedModel,
        params: categoryParameters,
      });

      if (response.status === 200) {
        toast.success('Model edited succesfully');
        setTimeout(function() {
          navigate("/home")
        }, 1200); // 1000 milisegundos = 1 segundo

        console.log("Modelo editado con éxito");
      } else {
        toast.error('Error')
        setTimeout(function() {
          navigate("/home")
        }, 1200); // 1000 milisegundos = 1 segundo        
        console.error("Error al editar el modelo");
      }
      console.log(JSON.stringify(response?.data));
    } catch (error) {
      toast.error('Error')
      setTimeout(function() {
        navigate("/home")
      }, 1200); // 1000 milisegundos = 1 segundo     
            console.error(error);
    }
  };

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getModels = async () => {
      try {
        const response = await axiosPrivate.get(
          `/models?companyID=${companyID}`,
          {
            signal: controller.signal,
          }
        );
        isMounted && setModels(response.data);
      } catch (err) {
        console.error(err);
      }
    };

    getModels();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  const handleRemoveParameter = (category, index) => {
    const updatedParameters = [...categoryParameters[category]];
    updatedParameters.splice(index, 1);
    setCategoryParameters((prevParameters) => ({
      ...prevParameters,
      [category]: updatedParameters,
    }));
  };

  return (
    <div className="flex justify-center ">
      <div className="flex flex-col max-w-3xl w-full dark:text-gray-200 ">
        <label htmlFor="modelSelect" className="text-lg font-bold mb-2">
          Selecciona un modelo:
        </label>
        <select
          id="modelSelect"
          className="border rounded-md px-4 py-2 mb-4 dark:bg-gray-700 dark:text-gray-200"
          onChange={handleModelChange}
          value={selectedModel}
        >
          <option value="">Selecciona un modelo</option>
          {models.map((model) => (
            <option key={model._id} value={model._id}>
              {model.name}
            </option>
          ))}
        </select>

        <div className="flex flex-wrap">
          <div className="w-full md:w-1/2 p-4">
            <div className="mt-2">
              <h2 className="text-sm font-semibold">
                Selecciona las categorías:
              </h2>
              <div className="border p-2 rounded-lg shadow-xl">
                {elementosRevit.map((category) => (
                  <label key={category} className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      value={category}
                      onChange={handleCategoryChange}
                      checked={selectedCategories.includes(category)}
                      disabled={!selectedModel}
                      className="h-6 w-6 text-indigo-600"
                    />
                    <span>{category}</span>
                  </label>
                ))}
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 p-4">
            {selectedCategories.length > 0 && (
              <div>
                {selectedCategories.map((category) => (
                  <div className="" key={category}>
                    <div className="flex items-center justify-center">
                      {" "}
                      {/* Agregamos las clases "flex", "items-center" y "justify-center" */}
                      <div>
                        <h2 className="text-xl font-semibold mr-4 dark:text-gray-100">
                          {category}
                        </h2>
                      </div>
                      <div>
                        <button
                          onClick={() => handleAddParameter(category)}
                          className="rounded-full bg-green-600 text-white hover:bg-green-700 focus:outline-none flex items-center justify-center mb-3"
                          style={{
                            fontSize: "26px",
                            lineHeight: "1",
                            width: "32px",
                            height: "32px",
                          }}
                        >
                          +
                        </button>
                      </div>
                    </div>

                    {categoryParameters[category] &&
                      categoryParameters[category].map((parameter, index) => (
                        <div
                          key={index}
                          className="flex flex-col justify-center items-center mt-1 w-full dark:text-gray-200"
                        >
                          <input
                            type="text"
                            placeholder="Nombre del parámetro"
                            name="name"
                            value={parameter.name}
                            onChange={(e) =>
                              handleParameterChange(e, category, index)
                            }
                            className="border rounded-md px-3 py-1 w-fit dark:bg-gray-700" // Input con mayor ancho (2/3)
                          />
                          <select
                            name="dataType"
                            value={parameter.dataType}
                            onChange={(e) =>
                              handleParameterChange(e, category, index)
                            }
                            className="border rounded-md px-3 py-1 w-fit mt-1 dark:bg-gray-700" // Select con menor ancho (1/3)
                          >
                            <option value="string">String</option>
                            <option value="boolean">Boolean</option>
                            <option value="rating">Rating</option>
                            <option value="image">Image</option>

                          </select>

                          <a
                            onClick={() =>
                              handleRemoveParameter(category, index)
                            }
                          >
                            <span class=" mt-1 fold-bold relative inline-block h-full rounded border-2 border-black md:px-2 md:py-1 sm:text-sm font-bold text-black transition duration-100 bg-red-400 hover:text-gray-900 dark-bg-transparent">
                              Eliminar
                            </span>
                          </a>
                          <Divider></Divider>
                        </div>
                      ))}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <div className="flex justify-center">
          <label htmlFor="submitButton" className="relative">
            <span className="absolute top-0 left-0 mt-1 ml-1 h-full w-full rounded bg-black"></span>
            <span
              onClick={handleSubmit}
              className="fold-bold relative inline-block h-full w-full rounded border-2 border-black bg-white px-3 py-1 text-base font-bold text-black transition duration-100 hover:bg-yellow-400 hover:text-gray-900 dark:bg-gray-400"
            >
              Edit Params
            </span>
            {/* Botón de envío del formulario, oculto para el usuario */}
            <button
              disabled={!selectedModel || selectedCategories.length === 0}
              style={{ display: "none" }}
            ></button>
          </label>
        </div>
      </div>
    </div>
  );
};

export default EditParameters;
