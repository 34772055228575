import React, { useState, useEffect } from "react";
import { BadgeDelta, Title, ProgressCircle } from "@tremor/react";

const EdilicialState = ({ data, zones }) => {
  const [zoneData, setZoneData] = useState([]);
  const [currentEdilicialState, setCurrentEdilicialState] = useState(0);
  const [deltaType, setDeltaType] = useState(0); // Porcentaje de aumento

  // Obtener el año actual
  const currentYear = new Date().getFullYear();


  useEffect(() => {
    let filteredData = data;

    if (zones.length > 0) {
      filteredData = data.filter((item) => zones.includes(item.zoneID));
    }

    const chartData = [];
    for (let month = 1; month <= 12; month++) {
      const edilicialStates = filteredData.map((item) => {

        const yearData = item.historyState[currentYear.toString()];
        const monthData = yearData ? yearData[`${month}`] : null;
        return monthData ? monthData["EdilicialState"] : null; // Cambio a EdilicialState
      });

      const filteredEdilicialStates = edilicialStates.filter((value) => value !== null);
      const sum = filteredEdilicialStates.reduce((acc, val) => acc + val, 0);


      const averageEdilicialState =
        edilicialStates.reduce((acc, val) => acc + val, 0) ? sum/filteredEdilicialStates.length : 0;

      // Comprobar si averageEdilicialState es NaN y asignar un valor predeterminado
      if (isNaN(averageEdilicialState)) {
        chartData.push({
          month: `${month}`,
          edilicialState: 0, // Valor predeterminado si es NaN
        });
      } else {
        chartData.push({
          month: `${month}`,
          edilicialState: averageEdilicialState * 100,
        });
      }
    }

    setZoneData(chartData);

    const currentMonth = new Date().getMonth() + 1;
    const currentMonthData = chartData.find(
      (item) => item.month === currentMonth.toString()
    );
    if (currentMonthData) {
      setCurrentEdilicialState(currentMonthData.edilicialState.toFixed());
    }

    // Calcular el porcentaje de aumento
    const prevMonth = currentMonth === 1 ? 12 : currentMonth - 1; // Mes anterior
    const prevMonthData = chartData.find((item) => item.month === prevMonth.toString());
    if (prevMonthData) {
      const delta = currentMonthData.edilicialState - prevMonthData.edilicialState;
      setDeltaType(delta.toFixed());
    }
  }, [data, zones]);

  return (
    <div className=" h-fit w-full z-10 m-1">
    <div class="shadow border-2 border-blue-700 bg-white rounded-xl   h-fit w-full z-10">
    <div className="  p-1 h-44 z-10">
        <div className="flex flex-row justify-center items-cente z-10r">
          <Title className="ml-2">Building</Title>
          <div className={`p-1 bg-indigo`}>
            <BadgeDelta>{deltaType}%</BadgeDelta>
          </div>
        </div>
        <ProgressCircle
          value={parseFloat(currentEdilicialState)}
          size="lg"
          strokeWidth="12"
          className="mt-1"
          color="indigo"
          showAnimation={true}
          tooltip="Building Value"
        >
          <span className="dark:text-white text-2xl">
            {currentEdilicialState}%
          </span>
        </ProgressCircle>
      </div>
    </div>
    </div>
  );
};

export default EdilicialState;
