import axios from "axios";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import useAxiosPrivate from "../../src/hooks/useAxiosPrivate";

export default function useApsRefreshToken() {
  const [session, setSession] = useState({});
  const [cookies, setCookie] = useCookies(["access_token", "aps_token"]);

  const axiosPrivate = useAxiosPrivate();
  
  useEffect(() => {
    //const tokenInfo = JSON.parse(cookies.aps_token)
    const tokenInfo = cookies.aps_token;
    const getRefresh = async (refresh_token) => {
      const response = await axiosPrivate.get(
        `${process.env.REACT_APP_BASE_URL}/aps/token/refresh/${refresh_token}`,
        {
          headers: { authorization: cookies.access_token },
        }
      );
      const expiry_date = new Date();
      expiry_date.setSeconds(response.data.expires_in);
      const cookieData = {
        ...tokenInfo,
        token: response.data.access_token,
        exp_data: expiry_date,
        refresh: response.data.refresh_token,
      };
      setCookie("aps_token", JSON.stringify(cookieData));
    };
    if (tokenInfo) {
      const expDate = new Date(tokenInfo.exp_data);
      const nowDate = new Date();
      if (nowDate > expDate) {
        getRefresh(tokenInfo.refresh);
      } else {
        setSession({
          token: tokenInfo.token,
          username: tokenInfo.username,
          picture: tokenInfo.picture,
        });
      }
    }
  }, [cookies.aps_token]);

  return session;
}
