import { Link } from "react-router-dom";
import Users from "./Users";

const Admin = () => {
  return (
    <section className="flex justify-center  h-screen">
      <div className="text-center">
        <h1 className="text-4xl font-bold mb-4 dark:text-gray-200">Users</h1>
        <div class="flex flex-wrap justify-center gap-6">
          <Link
            to="/admin/createUser"
          >
            <a class="relative" href="#">
              <span class="fold-bold relative inline-block h-full w-full rounded border-2 border-black bg-white dark:bg-yellow-400 px-3 py-1 text-base font-bold text-black transition duration-100 hover:bg-yellow-400 hover:text-gray-900 dark:bg-transparent">
                Create User
              </span>
            </a>
            
          </Link>
        </div>

        <br />
        <Users />
        <br />
      
      </div>
    </section>
  );
};

export default Admin;
