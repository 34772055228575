import React, { createContext, useContext, useState } from 'react';
import { LRUCache } from 'lru-cache';

// Crear el contexto
const CacheContext = createContext();

// Proveedor que inicializa y provee la caché
export const CacheProvider = ({ children }) => {
    const [cache] = useState(() => new LRUCache({
        max: 100, // Número máximo de elementos en caché
        maxAge: 1000 * 60 * 60, // Duración máxima de almacenamiento en milisegundos
    }));

    return (
        <CacheContext.Provider value={cache}>
            {children}
        </CacheContext.Provider>
    );
};

// Hook personalizado para acceder al contexto
export const useCache = () => {
    return useContext(CacheContext);
};
