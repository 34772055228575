import useApsRefreshToken from "../../../../hooks/useApsRefreshToken";

export default function AdskLogin() {
  const scope = "code:all data:create data:write";
  const link = `https://developer.api.autodesk.com/authentication/v2/authorize?response_type=code&client_id=${
    process.env.REACT_APP_ADSK_CLIENT
  }&redirect_uri=${encodeURIComponent(
    process.env.REACT_APP_ADSK_CALLBACK_URL
  )}&scope=${encodeURIComponent(scope)}`;
  const { token, username, picture } = useApsRefreshToken();

  return (
    <div className="z-10 ">
    <a
      className="items-center justify-center  px-4 py-2 text-base font-semibold text-white transition-all duration-200 bg-black border border-transparent rounded-md inline-flex hover:bg-slate-700 focus:bg-slate-700 dark:bg-red-500 dark:hover:bg-red-600 cursor-pointer"
      href={!token?link:`https://developer.api.autodesk.com/authentication/v2/logout?post_logout_redirect_uri=${encodeURIComponent(process.env.REACT_APP_ADSK_CALLBACK_URL)}`}

    >
      {!token?<svg
        class="w-8 h-5 mr-1 -ml-2"
        aria-hidden="true"
        focusable="true"
        data-prefix="fab"
        data-icon="apple"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 500 281"
      >
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M130.299 222.384L278.52 130.269H355.413C357.785 130.269 359.902 132.159 359.902 134.755C359.902 136.871 358.971 137.83 357.785 138.535L284.959 182.124C280.215 184.974 278.577 190.645 278.577 194.877L278.492 222.356H371V62.6708C371 59.5956 368.628 57 365.098 57H276.854L129 148.692V222.356H130.299V222.384Z"
          fill="white"
        />
      </svg>:<img src={picture} class="w-5 h-5 mr-1 -ml-2"/>}
      {!token?"Link Adsk Account":`${username} (Unlink ADSK Account)`}
    </a>
    </div>
  );
}
