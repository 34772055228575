import React from "react";
import { AreaChart, Card, Flex, Metric, Badge, Title } from "@tremor/react";
// import { ClockIcon } from '@heroicons/react/outline'
import "../../../../utils/styles/card.css";

const ProjectCount = ({ numProjects }) => {
  //Guardar la cantidad de modelos por mes en la db asi lo muestro en el AreaChart
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1; // Sumar 1 porque enero es 0
  return (
    <div className=" h-fit w-full z-10 m-1">
    <div class=" border-2 border-blue-700 bg-white rounded-xl   h-fit w-full z-20">
      <div className="  p-1 h-44 z-10">
      <div className="flex justify-center items-center z-10 mt-2">
          
          <div>
            <Badge size="xl" color="violet">
              {currentYear}/{currentMonth}
            </Badge>
          </div>
        </div>
      <div className="flex flex-col justify-center items-center z-10  h-24">
      
      <div className="flex flex-col items-center justify-center h-full">
  <div><Metric>{numProjects}</Metric></div>
  <div><h1 className="text-2xl">Models</h1></div>
</div>




        </div>
      </div>
    </div>
    </div>  );
};

export default ProjectCount;
