import { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import {  toast } from 'sonner'
import { Badge } from "@tremor/react";
import useAuth from "./../../../hooks/useAuth";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";


const Users = () => {
  const [users, setUsers] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const { auth } = useAuth();

  // Define un objeto para mapear identificadores de roles a nombres de roles
  const ROLES = {
    2001: "User",
    1984: "Manager",
    5150: "Admin",
  };

  // Función para cargar la lista de usuarios
  const getUsers = async () => {
    try {
      const response = await axiosPrivate.get(
        `/users?companyID=${auth?.companyID}`
      );
      console.log(response);

      // Mapea los roles de los usuarios a nombres de roles y modelos que pueden editar
      const usersWithRoleNames = response.data.map((user) => ({
        ...user,
        roles: Array.isArray(user.roles)
          ? user.roles.map((roleID) => ROLES[roleID] || roleID).join(", ")
          : user.roles, // Mantener los roles tal como están si no es una matriz
      }));

      setUsers(usersWithRoleNames);
    } catch (err) {
      console.error(err);
      navigate("/login", { state: { from: location }, replace: true });
    }
  };

  // Función para eliminar un usuario
  const handleDeleteUser = async (id) => {
    console.log(id);
    try {
      const response = await axiosPrivate.delete(`/users?id=${id}`);
      console.log(response);
      console.log(`Eliminar usuario con ID: ${id}`);

      toast.success('User deleted');
      setTimeout(function() {
        navigate("/admin")
      }, 1200); // 1000 milisegundos = 1 segundo

      // Después de eliminar con éxito, recarga la lista de usuarios
      getUsers();
    } catch (error) {
      toast.error('Error')
      setTimeout(function() {
        navigate("/admin")
      }, 1200); // 1000 milisegundos = 1 segundo
      console.error("Error al eliminar usuario:", error);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <article className="flex flex-col">
      {/* <ToastContainer /> */}
      <div className="rounded-lg border border-gray-20">
        {users?.length ? (
          <table className="table-fixed divide-gray-200 dark:text-gray-200 text-sm sm:w-fit md:w-max ">
            <thead className="">
              <tr>
                <th className="text-gray-900 dark:text-gray-200  md:text-xl">
                  Email
                </th>
                <th className="text-gray-900 dark:text-gray-200  md:text-xl">
                  Active
                </th>
                <th className="text-gray-900  dark:text-gray-200 md:text-xl">
                  Roles
                </th>
                <th className="text-gray-900 dark:text-gray-200  md:text-xl">
                  Models
                </th>{" "}
                {/* Nueva columna */}
                <th className="text-gray-900 dark:text-gray-200  md:text-xl"></th>
                <th className="text-gray-900 dark:text-gray-200 md:text-xl"></th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 ">
              {users.map((user, i) => (
                <tr key={i}>
                  <td className="font-medium text-gray-900 dark:text-gray-200  md:px-2 md:py-1 ">
                    {user?.email}
                  </td>
                  <td
                    className={`${
                      user.active
                        ? "text-green-500 md:px-2 md:py-1 "
                        : "text-red-500 md:px-2 md:py-1 "
                    }`}
                  >
                    {user.active ? "SI" : "NO"}
                  </td>
                  <td className="text-sm md:px-2 md:py-1 ">
                    <Badge>
                      {user?.roles.length === 20
                        ? "Admin"
                        : user?.roles.length === 13
                        ? "Manager"
                        : "User"}
                    </Badge>
                  </td>
                  {user.models ? (
                    <td className="text-sm md:px-2 md:py-1 ">
                      <select
                        className="w-24 md:w-48 rounded-lg border-gray-300 text-gray-700 sm:text-sm dark:bg-gray-600 dark:text-gray-200"
                        onChange={(e) => {
                          // Agrega lógica para manejar el cambio de selección si es necesario
                          const selectedModelID = e.target.value;
                          // Puedes realizar acciones en función de la selección
                          console.log(
                            `Usuario ${user._id} seleccionó el modelo con ID: ${selectedModelID}`
                          );
                        }}
                      >
                        {user.models.map((model, j) => (
                          <option key={j} value={model ? model.modelId : ""}>
                            {model ? model.modelName : "N/A"}
                          </option>
                        ))}
                      </select>
                    </td>
                  ) : (
                    <td className="text-sm">N/A</td> // Otra opción si no hay modelos
                  )}
                  <div className="flex flex-col lg:flex-row">
                    <td className="text-sm md:px-2 md:py-1">
                      <Link to={`/admin/editUser/${user._id}`}>
                        <a href="#">
                          <span className="fold-bold relative inline-block w-full h-full dark:bg-green-400 rounded border-2 border-black md:px-2 md:py-1 sm:text-sm font-bold text-black transition duration-100 bg-green-400 hover:text-gray-900 dark:bg-transparent">
                            Editar
                          </span>
                        </a>
                      </Link>
                    </td>
                    <td className="text-sm md:px-2 md:py-1">
                      <a onClick={() => handleDeleteUser(user._id)} href="#">
                        <span className="fold-bold relative inline-block w-full h-full rounded border-2 border-black md:px-2 md:py-1 sm:text-sm font-bold text-black transition duration-100 bg-red-400 hover:text-gray-900 dark-bg-transparent">
                          Eliminar
                        </span>
                      </a>
                    </td>
                  </div>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className="text-center text-sm">No users to display</p>
        )}
      </div>
    </article>
  );
};

export default Users;
