import React, { useState, useEffect } from "react";
import { BadgeDelta, Title, ProgressCircle } from "@tremor/react";

const StructuralStateChart = ({ data, zones }) => {
  const [zoneData, setZoneData] = useState([]);
  const [currentStructuralState, setCurrentStructuralState] = useState(0);
  const [deltaType, setDeltaType] = useState(0); // Porcentaje de aumento
  // Obtener el año actual
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    let filteredData = data;

    if (zones.length > 0) {
      filteredData = data.filter((item) => zones.includes(item.zoneID));
    }

    const chartData = [];
    for (let month = 1; month <= 12; month++) {
      const structuralStates = filteredData.map((item) => {
        const yearData = item.historyState[currentYear.toString()];
        const monthData = yearData ? yearData[`${month}`] : null;
        return monthData ? monthData["StructuralState"] : null;
      });
      const filteredStates = structuralStates.filter((value) => value !== null);
      const sum = filteredStates.reduce((acc, val) => acc + val, 0);

      const averageStructuralState =
        structuralStates.reduce((acc, val) => acc + val, 0)  ? sum/filteredStates.length : 0;

      // Comprobar si averageStructuralState es NaN y asignar un valor predeterminado
      if (isNaN(averageStructuralState)) {
        chartData.push({
          month: `${month}`,
          structuralState: 0, // Valor predeterminado si es NaN
        });
      } else {
        chartData.push({
          month: `${month}`,
          structuralState: averageStructuralState * 100,
        });
      }
    }

    setZoneData(chartData);

    const currentMonth = new Date().getMonth() + 1;
    const currentMonthData = chartData.find(
      (item) => item.month === currentMonth.toString()
    );
    if (currentMonthData) {
      setCurrentStructuralState(currentMonthData.structuralState.toFixed());
    }

    // Calcular el porcentaje de aumento
    const prevMonth = currentMonth === 1 ? 12 : currentMonth - 1; // Mes anterior
    const prevMonthData = chartData.find((item) => item.month === prevMonth.toString());
    if (prevMonthData) {
      const delta = currentMonthData.structuralState - prevMonthData.structuralState;
      setDeltaType(delta.toFixed());
    }
  }, [data, zones]);

  return (
    <div className=" h-fit w-full z-10 m-1">
    <div class="shadow border-2 border-blue-700 bg-white rounded-xl   h-fit w-full ">
    <div className="  p-1 h-44">
        <div className="flex flex-row justify-center items-center">
          <Title className="ml-2">Structural</Title>
          <div className={`p-1 bg-green`}>
            <BadgeDelta>{deltaType}%</BadgeDelta>
          </div>
        </div>
        <ProgressCircle
          value={parseFloat(currentStructuralState)}
          size="lg"
          strokeWidth="12"
          className="mt-1"
          color="green"
          showAnimation={true}
        >
          <span className="dark:text-white text-2xl">
            {currentStructuralState}%
          </span>
        </ProgressCircle>
      </div>
    </div>
    </div>
  );
};

export default StructuralStateChart;
