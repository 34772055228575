export function getAllProperties(viewer, setData) {
  var tree = viewer.model.getInstanceTree();
  if (tree === undefined) {
    setTimeout(() => {
      getAllProperties(viewer, setData);
    }, 500);
    return;
  }
  var categories = {};
  var promises = [];

  tree.enumNodeChildren(
    tree.getRootId(),
    function (dbId) {
      if (tree.getChildCount(dbId) === 0) {
        var promise = new Promise(function (resolve, reject) {
          viewer.getProperties(dbId, function (props) {
            var elementData = {
              dbId: dbId,
              name: props.name,
              externalId: props.externalId,
            };

            for (var i = 0; i < props.properties.length; i++) {
              var propDisplayName = props.properties[i].displayName;
              var propDisplayValue = props.properties[i].displayValue;

              // Si el valor es null, no lo procese
              if (propDisplayValue === null) continue;

              switch (propDisplayName) {
                case "Category":
                  elementData.categoryValue = props.properties[i].displayValue;
                  // console.log(props.properties[i].displayValue)

                  break;
                case "Width":
                  elementData.Width = props.properties[i].displayValue;
                  break;
                case "Height":
                  elementData.Height = props.properties[i].displayValue;
                  break;
                case "Estado":
                case "State":
                case "Score":
                case "ESTADO":
                case "MC-Estado":
                case "Condition":
                  elementData.State = props.properties[i].displayValue;
                  break;
                case "Garantía":
                  elementData.Warranty = props.properties[i].displayValue;
                  break;
                case "Eficiencia Energética de Equipos Electromecánicos":
                  elementData.Efficiency = props.properties[i].displayValue;
                  break;

                case "Mark":
                  elementData.Mark = props.properties[i].displayValue;
                  break;
                case "LIMPIEZA":
                  elementData.LIMPIEZA = props.properties[i].displayValue;
                  break;
                case "SOPORTE":
                  elementData.SOPORTE = props.properties[i].displayValue;
                  break;

                case "PINTURA":
                  elementData.PINTURA = props.properties[i].displayValue;
                  break;

                case "POSTE":
                  elementData.POSTE = props.properties[i].displayValue;
                  break;
                case "BRAZO":
                  elementData.BRAZO = props.properties[i].displayValue;
                  break;
                case "Area":
                  elementData.Area = props.properties[i].displayValue;
                  break;
                case "Vidrios Faltantes":
                  elementData.MissingGlass = props.properties[i].displayValue;
                  break;
                case "Cartelería de Evacuación":
                  elementData.EvacuationSignage =
                    props.properties[i].displayValue;
                  break;
                case "Cumplimiento Normativa":
                  elementData.ComplianceWithStandards =
                    props.properties[i].displayValue;
                  break;
                case "Type IfcGUID":
                  elementData.TypeIfcGUID = props.properties[i].displayValue;
                  break;
                case "Baldosas Faltantes":
                  elementData.MissingTiles = props.properties[i].displayValue;
                  break;
                case "Grietas, Rajadura o Fisuras":
                case "MC-Grietas, Rajaduras o Fisuras":
                case "Cracks":
                  elementData.CracksOrFissures =
                    props.properties[i].displayValue;
                  break;
                case "Apto para Recolección de Agua de Lluvia":
                  elementData.SuitableForRainwaterHarvesting =
                    props.properties[i].displayValue;
                  break;
                case "Zócalos Faltantes":
                case "MC-Zócalo":
                  elementData.MissingBaseboards =
                    props.properties[i].displayValue;
                  break;

                case "Painted":
                  elementData.Painted = props.properties[i].displayValue;
                  break;
                case "Pintura Faltante":
                  elementData.MissingPaint = props.properties[i].displayValue;
                  break;
                case "Capacidad":
                  elementData.Capacity = props.properties[i].displayValue;
                  break;
                case "Fecha de Vencimiento":
                  elementData.ExpiryDate = props.properties[i].displayValue;
                  break;
                case "Herrajería":
                case "MC-Herrajería":
                  elementData.Ironwork = props.properties[i].displayValue;
                  break;
                case "Funciona":
                  elementData.Works = props.properties[i].displayValue;
                  break;
                case "Bajo Consumo":
                  elementData.LowConsumption = props.properties[i].displayValue;
                  break;
                case "Type IfcGUID":
                  elementData.ifcGUID = props.properties[i].displayValue;
                  break;
                case "Comments":
                case "Comentarios":
                  elementData.Comments = props.properties[i].displayValue;
                  break;
                case "Humedad":
                case "Humidity":
                case "MC-Humedad":
                  elementData.Humity = props.properties[i].displayValue;
                  break;
              }
            }

            var allPropertiesNotNull = Object.values(elementData).every(
              (value) => value !== null
            );

            if (elementData.categoryValue !== null && allPropertiesNotNull) {
              if (!categories[elementData.categoryValue]) {
                categories[elementData.categoryValue] = [];
              }
              categories[elementData.categoryValue].push(elementData);
            }
            resolve();
          });
        });
        promises.push(promise);
      }
    },
    true
  );

  Promise.all(promises).then(function () {
    if (categories) {
      // Llama a la función detectLanguage con el objeto categories
      const language = detectLanguage(categories);

      // Si el idioma es "Español", traduce las categorías al inglés
      if (language === "Español") {
        const translatedCategories = {};
        for (const categoryEsp in categories) {
          const categoryEng = categoryMap[categoryEsp];
          if (categoryEng) {
            translatedCategories[categoryEng] = categories[categoryEsp];
          }
        }
        // Utiliza translatedCategories en lugar de categories si es necesario
        categories = translatedCategories;
      }

      console.log(categories);
      setData(categories, "selectedModelCategories");
    }
    window.categories = categories;
    console.log(categories);
  });
}

function detectLanguage(selectedModelCategories) {
  if (Object.keys(selectedModelCategories).length > 0) {
    // Comprobar categorías en español
    for (const categoryEsp in categoryMap) {
      if (selectedModelCategories.hasOwnProperty(categoryEsp)) {
        return "Español";
      }
    }

    // Comprobar categorías en inglés
    for (const categoryEsp in categoryMap) {
      const categoryEng = categoryMap[categoryEsp];

      if (selectedModelCategories.hasOwnProperty(categoryEng)) {
        return "English";
      }
    }

    // Si no se encontró ninguna categoría en ninguno de los dos idiomas, retorna 'Unknown'
    return "Unknown";
  }
}

function translateCategory(categoryEsp) {
  // Traduce la categoría en español a inglés utilizando el diccionario categoryMap
  return categoryMap[categoryEsp] || categoryEsp;
}

const categoryMap = {
  "Revit Muros": "Revit Walls",
  "Revit Paneles de muro cortina": "Revit Curtain Panels",
  "Revit Montantes de muro cortina": "Revit Curtain Wall Mullions",
  "Revit Puertas": "Revit Doors",
  "Revit Cubiertas": "Revit Roofs",
  "Revit Pilares estructurales": "Revit Structural Columns",
  "Revit Ventanas": "Revit Windows",
  "Revit Modelos genéricos": "Revit Generic Models",
  "Revit Armazón estructural": "Revit Structural Framing",
  "Revit Pilares": "Revit Columns",
  "Revit Techos": "Revit Ceilings",
  "Revit Aparatos sanitarios": "Revit Plumbing Fixtures",
  "Revit Suelos": "Revit Floors",
  "Revit Barandillas": "Revit Railings",
  "Revit Escaleras": "Revit Stairs",
  "Revit Tramos": "Revit Runs",
  "Revit Descansillos": "Revit Landings",
  "Revit Muebles de obra": "Revit Casework",
  "Revit Equipos mecánicos": "Revit Mechanical Equipment",
  "Revit Estacionamiento": "Revit Parking",
  "Revit Equipos eléctricos": "Revit Electrical Equipment",
  "Revit Luminarias": "Revit Lighting Fixtures",
  "Revit Tuberías": "Revit Pipes",
  "Revit Eje": "Revit Center Line",
  "Revit Uniones de tubería": "Revit Pipe Fittings",
  "Revit Aparatos eléctricos": "Revit Electrical Fixtures",
  "Revit Equipos especializados": "Revit Specialty Equipment",
  "Revit Bandejas de cables": "Revit Cable Trays",
  "Revit Uniones de bandeja de cables": "Revit Cable Tray Fittings",
  "Revit Tubos": "Revit Conduits",
  "Revit Tuberías flexibles": "Revit Flex Pipes",
  "Revit Equipo de fontanería": "Revit Plumbing Equipment",
  "Revit Conductos": "Revit Ducts",
  "Revit Uniones de conducto": "Revit Duct Fittings",
  "Revit Uniones de tubo": "Revit Conduit Fittings",
  "Revit Soportes": "Revit Supports",
  "Revit Terminales de aire": "Revit Air Terminals",
  "Revit <Separación de habitación>": "Revit Room Separation",
  "Revit Sitio": "Revit Site",
};
