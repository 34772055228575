import React, { createContext, useContext, useState } from "react";

const ZoneContext = createContext();

export const useZoneContext = () => {
  return useContext(ZoneContext);
};

export const ZoneProvider = ({ children }) => {
  const [ZoneData, setZoneData] = useState(null);

  const setZoneInfo = (data) => {
    setZoneData(data);
    console.log(data)

  };

  return (
    <ZoneContext.Provider value={{ ZoneData, setZoneInfo }}>
      {children}
    </ZoneContext.Provider>
  );
};
