import axios from '../api/axios';
import useAuth from './useAuth';

const useRefreshToken = () => {
    const { setAuth } = useAuth();
    const refresh = async () => {
        const response = await axios.get('/auth/refresh');
        setAuth(prev => {
            console.log(JSON.stringify(prev));
            console.log(response.data);
            console.log(response.data.name)
            return {
                 ...prev,
                 roles:response.data.roles,
                 companyID:response.data.companyID,
                 accessToken: response.data.accessToken,
                 name: response.data.name,
                 _id: response.data._id

            }
        });
        // console.log(response.data.accessToken)
        return response.data.accessToken;
    }
    return refresh;
};

export default useRefreshToken;
