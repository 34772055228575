import React, { useState, useEffect } from "react";
import { BadgeDelta, Metric, Title, ProgressCircle } from "@tremor/react";

const WaterState = ({ data, zones }) => {
  const [zoneData, setZoneData] = useState([]);
  const [currentWaterState, setCurrentWaterState] = useState(0); // Estado de WaterState actual
  const [deltaType, setDeltaType] = useState(0); // Porcentaje de aumento
  // Obtener el año actual
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    let filteredData = data;

    // Verificar si hay zonas seleccionadas para aplicar el filtro
    if (zones.length > 0) {
      // Filtrar los datos solo si hay zonas seleccionadas
      filteredData = data.filter((item) => zones.includes(item.zoneID));
    }

    // Crear un array de objetos para representar cada mes del año
    const chartData = [];
    for (let month = 1; month <= 12; month++) {
      const waterStates = filteredData.map((item) => {
        const yearData = item.historyState[currentYear.toString()];
        const monthData = yearData ? yearData[`${month}`] : null;
        return monthData ? monthData["WaterState"] : null; // Cambio a WaterState
      });
      const filteredStates = waterStates.filter((value) => value !== null);
      const sum = filteredStates.reduce((acc, val) => acc + val, 0);

      const averageWaterState =
        waterStates.reduce((acc, val) => acc + val, 0) ? sum/filteredStates.length : 0;

      // Comprobar si averageWaterState es NaN y asignar un valor predeterminado
      if (isNaN(averageWaterState)) {
        chartData.push({
          month: `${month}`,
          waterState: 0, // Valor predeterminado si es NaN
        });
      } else {
        chartData.push({
          month: `${month}`,
          waterState: averageWaterState * 100, // Multiplicar por 100 si es necesario
        });
      }
    }

    setZoneData(chartData);

    // Obtener el valor del estado WaterState actual para el mes actual
    const currentMonth = new Date().getMonth() + 1; // Mes actual
    const currentMonthData = chartData.find(
      (item) => item.month === currentMonth.toString()
    );
    if (currentMonthData) {
      setCurrentWaterState(currentMonthData.waterState.toFixed());
    }

    // Calcular el porcentaje de aumento
    const prevMonth = currentMonth === 1 ? 12 : currentMonth - 1; // Mes anterior
    const prevMonthData = chartData.find((item) => item.month === prevMonth.toString());
    if (prevMonthData) {
      const delta = currentMonthData.waterState - prevMonthData.waterState;
      setDeltaType(delta.toFixed());
    }
  }, [data, zones]);

  return (
    <div className=" h-fit w-full z-10 m-1">
    <div class="shadow border-2 border-blue-700 bg-white rounded-xl   h-fit w-full ">
    <div className="  p-1 h-44">
        <div className="flex flex-row justify-center items-center">
          <Title className="ml-2">Sanitary</Title>
          <div className={`p-1 bg-fuchsia`}>
            <BadgeDelta>{deltaType}%</BadgeDelta>
          </div>
        </div>
        <ProgressCircle
          value={parseFloat(currentWaterState)}
          size="lg"
          strokeWidth="12"
          className="mt-1"
          color="fuchsia"
          showAnimation={true}
        >
          <span className="dark:text-white text-2xl">
            {currentWaterState}%
          </span>
        </ProgressCircle>
      </div>
    </div>
    </div>
  );
};

export default WaterState;
