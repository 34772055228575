import { useState, useEffect } from 'react';
import { useCache } from '../context/CacheContext'; 
import useAxiosPrivate from "./useAxiosPrivate";





const useFetchImageURL = (model) => {
    const axiosPrivate = useAxiosPrivate();
    const [imageURL, setImageURL] = useState('');
    const imageCache = useCache(); // Usa la caché del contexto

    useEffect(() => {
        async function fetchImageURL() {
            try {
                const cachedImageURL = imageCache.get(model.thumbnail);
                if (cachedImageURL) {
                    setImageURL(cachedImageURL);
                } else {
                    const response = await axiosPrivate.get("/s3/get-image-url", {
                        params: {
                            folderName: "thumbnails",
                            originalName: model.thumbnail,
                        },
                    });
                    const newImageUrl = response.data.url;

                    imageCache.set(model.thumbnail, newImageUrl);

                    setImageURL(newImageUrl);
                }
            } catch (error) {
                console.error("Error fetching image URL:", error);
            }
        }

        if (model) {
            fetchImageURL();
        }
    }, [model]);

    return imageURL;
};

export default useFetchImageURL;
