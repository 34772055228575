import { useRef, useState, useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import lottie from "lottie-web";
import axios from "../../api/axios";
import logo from "../../components/utils/img/icons/205.png";

const LOGIN_URL = "/auth/login";

const Login = () => {
  const { setAuth, persist, setPersist } = useAuth();

  const navigate = useNavigate();
  const userRef = useRef(null);
  const errRef = useRef(null);

  const [email, setUser] = useState("");
  const [password, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [success, setShowSuccess] = useState(false);
  const lottieContainer = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      container: lottieContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: "https://lottie.host/78201082-d6e0-4d1b-a355-7c041088cee9/zuGM9i7AQB.json",
    });
  }, [success]);

  useEffect(() => {
    if (userRef.current) {
      userRef.current.focus();
    }
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [email, password]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        LOGIN_URL,
        JSON.stringify({ email, password }),
      );
      console.log(response);
      // const emailDatabase = response?.data?.email;
      const accessToken = response?.data?.accessToken;
      const roles = response?.data?.roles;
      const companyID = response?.data?.companyID;
      const _id = response?.data?._id;
      const name = response?.data?.name;

      setAuth({ roles, accessToken, companyID, _id, name });
      //si es persist guardar esto en session storage
      //y hacer que todo el ciclo de

      setUser("");
      setPwd("");
      setShowSuccess(true);

      const promise = () =>
        new Promise((resolve) => setTimeout(resolve, 1000)).then(() => {
          // Utiliza navigate para redirigir a la página de inicio
          navigate("/home"); // Reemplaza '/home' con la ruta real de tu página de inicio
        });

      toast.promise(promise, {
        loading: "Loading...",
        error: "Error",
      });
    } catch (err) {
      console.log(err);
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("Missing Username or Password");
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Login Failed");
      }
      if (errRef.current) {
        errRef.current.focus();
      }
    }
  };
  const togglePersist = () => {
    setPersist((prev) => !prev);
  };

  useEffect(() => {
    localStorage.setItem("persist", persist);
  }, [persist]);

  const handleContactSupportClick = () => {
    const email = "acapra@voyansi.com";
    const mailtoLink = `mailto:${email}`;

    // Abrir el cliente de correo predeterminado con la dirección de correo ya prellenada
    window.location.href = mailtoLink;
  };
  return (
    <div className="flex flex-col  h-screen">
      {success ? (
        <>
          <div className=" h-full mt-12">
            <div className="absolute  inset-0 bg-gradient-to-b from-green-400 to-blue-400 h-96  z-0"></div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "50vh",
              }}
            >
              {" "}
              <div
                ref={lottieContainer}
                style={{ height: 120, width: 120 }}
              ></div>
              <div className="text-center text-2xl font-bold mb-4 dark:text-white z-10">
                Cargando los modelos..
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="flex justify-center items-center dark:bg-gray-900  h-screen">
          <div className="absolute  inset-0 bg-gradient-to-b from-green-400 to-blue-400 h-96  z-0"></div>

          <div className="w-full md:w-2/3 xl:w-3/6 lg:mx-auto rounded-2xl shadow-2xl h-3/5 overflow-hidden flex justify-center items-center  bg-white dark:bg-gray-700 dark:border-gray-800 m-4 md:m-1  z-10 ">
            <div className="flex md:w-full h-full z-10">
              {/* Columna Derecha (Texto) */}
              <div className="md:flex lg:w-1/2 md:w-full  dark:bg-gray-700 dark:border-gray-800 xl:p-4 flex justify-center items-center  z-10">
                {" "}
                {/* Añadir las clases para centrar */}
                <div className="md:max-w-md xl:p-0 mx-1 text-center dark:text-gray-200">
                  {" "}
                  {/* Añadir text-center para centrar el contenido */}
                  <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                    <img src={logo} className="h-16 mx-auto"></img>
                    <form onSubmit={handleSubmit}>
                      <div class="username-group mb-2 ">
                        <label
                          htmlFor="username"
                          class="flex flex-col-reverse relative focus group"
                        >
                          <input
                            type="text"
                            id="username"
                            ref={userRef}
                            autoComplete="off"
                            onChange={(e) => setUser(e.target.value)}
                            value={email}
                            required
                            className="border-2 border-black px-3 py-1 leading-9 dark:bg-gray-600"
                          ></input>
                          <span
                            htmlFor="username"
                            className="absolute text-md transform -translate-y-2 left-1 transition leading-8 dark:text-gray-400"
                          >
                            Usuario
                          </span>
                        </label>
                      </div>
                      <div class="password-group mb-2 mt-2">
                        <label
                          htmlFor="password"
                          class="flex flex-col-reverse relative focus group"
                        >
                          <input
                            type="password"
                            id="password"
                            onChange={(e) => setPwd(e.target.value)}
                            value={password}
                            required
                            className="border-2 border-black px-3 py-1 leading-9 dark:bg-gray-600"
                          ></input>
                          <span
                            htmlFor="password"
                            className="absolute text-md transform -translate-y-2 left-1 transition leading-8 dark:text-gray-400"
                          >
                            Contraseña:
                          </span>
                        </label>
                      </div>
                      <div className="flex w-full">
                        <button
                          type="submit"
                          className="w-full h-12 p-1 font-bold   transform bg-[#084BF1] hover:bg-blue-600 rounded-md text-[#00ff82]"
                        >
                          Ingresar
                        </button>
                        {/* <button className="w-32 h-12 p-1  border-2 border-[#21FD80] hover:bg-[#21FD80] rounded-md text-black">
                          Cancel
                        </button> */}
                      </div>
                      <div className="mb-1 mt-4 text-center dark:text-gray-200 flex flex-row text-xs justify-between items-center">
                        {/* Centrar Trust this device */}
                        <div className="flex items-center">
                          <input
                            type="checkbox"
                            id="persist"
                            onChange={togglePersist}
                            checked={persist}
                            className="transform scale-105 mr-1"
                            style={{
                              // Establece el color de fondo cuando el checkbox esté marcado
                              backgroundColor: persist
                                ? "#493E98 !important"
                                : "",
                            }}
                          />
                          <p htmlFor="persist" className="dark:text-gray-400">
                            Confiar en este dispositivo
                          </p>
                        </div>
                        <div>
                          <p className="dark:text-gray-400">
                            Olvide mi contraseña
                          </p>
                        </div>
                      </div>
                      <div className="justify-evenly	 flex ">
                        <span className="text-black text-xs font-normal">
                          Tienes problemas?{" "}
                        </span>
                        <span
                          className="text-blue-700 text-xs font-bold cursor-pointer dark:text-gray-400"
                          onClick={handleContactSupportClick}
                        >
                          Contactar soporte.
                        </span>
                      </div>{" "}
                    </form>
                    {/* <p className="mt-4 text-lg dark:text-gray-200"> 
              Need an Account?
            </p> */}
                  </div>
                </div>
              </div>
              {/* Columna Izquierda (Imagen) - Ancho establecido en "md" */}
              <div className="hidden md:flex lg:w-1/2 md:w-full ">
                <div className="lg:w-4/5 h-fit hidden lg:block relative"></div>
                <video
                  src="https://www.voyansi.com/hubfs/MKT-EN-Top%20Video-mp4-021323-rev03.mp4"
                  autoPlay
                  loop
                  className="object-cover w-full h-full"
                >
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
