import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useLocation, useNavigate } from "react-router";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

export default function Callback() {
  const queryStringParams = useLocation();
  const [cookies, setCookie, removeCookie] = useCookies([
    "access_token",
    "aps_token",
  ]);
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  

  useEffect(() => {
    const queryParams = new URLSearchParams(queryStringParams.search);
    const code = queryParams.get("code");
    console.log(code)
    if (code) {
      const setToken = async () => {
        try {
          const response = await axiosPrivate.get(
            `${process.env.REACT_APP_BASE_URL}/aps/token/${code}`,
          );
          console.log(response.data);
          const expiry_date = new Date();
          expiry_date.setSeconds(response.data.expires_in);
          const cookieData = {
            token: response.data.access_token,
            exp_data: expiry_date,
            refresh: response.data.refresh_token,
            username: response.data.userInfo.name,
            picture: response.data.userInfo.picture,
          };
          setCookie("aps_token", JSON.stringify(cookieData));
        } catch (err) {
          console.log(err);
        }
      };
      setToken();
    } else {
      removeCookie("aps_token");
    }
    navigate("/approvalUpdate");
  }, [cookies.access_token]);

  return <div>Callback</div>;
}
