import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useAuth from "./../../../hooks/useAuth";
import { ForgeContext } from "../../../context/ForgeContext";
import CardDesign from "./CardDesign";

const DesingAutomation = () => {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const { setData } = useContext(ForgeContext);
  const [models, setModels] = useState([]);
  const userId = auth?._id;

  const [searchTerm, setSearchTerm] = useState(""); // Término de búsqueda
  // Maneja cambios en el término de búsqueda
  const onSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  // Agrega un nuevo estado showAllModels que determine si se deben mostrar todos los modelos o no. Inicialízalo en false.
  const [showAllModels, setShowAllModels] = useState(false);

  const [modelsToShow, setModelsToShow] = useState(4); // Número inicial de modelos a mostrar

  // Función para ajustar el número de modelos a mostrar según el ancho de la ventana
  const adjustModelsToShow = () => {
    const windowWidth = window.innerWidth;

    if (windowWidth >= 1920) {
      // Pantalla 2XL (Ancho >= 1920px)
      setModelsToShow(4);
    } else if (windowWidth >= 1024) {
      // Pantalla XL (Ancho >= 1280px)
      setModelsToShow(4);
    } else if (windowWidth >= 816) {
      // Pantalla LG (Ancho >= 1024px)
      setModelsToShow(3);
    } else if (windowWidth >= 109) {
      // Pantalla MD (Ancho >= 768px)
      setModelsToShow(2);
    } else {
      // Pantalla SM (Ancho < 768px)
      setModelsToShow(1);
    }
  };

  // Ejecutar la función de ajuste cuando el tamaño de la ventana cambie
  useEffect(() => {
    adjustModelsToShow();

    // Agregar un event listener para el cambio de tamaño de la ventana
    window.addEventListener("resize", adjustModelsToShow);

    // Retirar el event listener cuando el componente se desmonte
    return () => {
      window.removeEventListener("resize", adjustModelsToShow);
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const signal = controller.signal;

    const getModels = async () => {
      try {
        const response = await axiosPrivate.get(
          `/users/getModelsById/${userId}`,
          {
            signal: signal,
          }
        );

        if (isMounted) {
          setModels(response.data);
        }
      } catch (err) {
        console.error(err);
      }
    };
    getModels();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [userId]);
  console.log(models);

  const handleCardClick = (model) => {
    // Cuando se hace clic en una tarjeta, actualiza el modelo seleccionado en el contexto.
    setData(model, "selectedModel");
  };

  return (
    <div className=" flex items-center flex-col justify-center ">
      <div className="absolute  mt-16 inset-0 bg-gradient-to-b from-green-400 to-blue-400 h-96  z-0"></div>
      <div className="text-center text-indigo-950 text-5xl font-black z-10 mt-8">
        Digital Twin for Operate
      </div>
      <div className="max-w-screen-lg w-full p-4 sm:p-6 md:p-10 z-10 ">
        <div class="w-full">
          <div class=" flex items-center w-full h-12 rounded-lg focus-within:shadow-lg bg-white overflow-hidden border border-black">
            <div class="grid place-items-center h-full w-12 text-gray-300">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </div>

            <input
              class="peer h-full w-full outline-none text-sm text-gray-700 pr-2"
              type="text"
              id="search"
              placeholder="Search ..."
              value={searchTerm} // Agrega el valor del estado de búsqueda
              onChange={onSearchChange} // Agrega la función de cambio
            />
          </div>
        </div>
        <div className="w-fit flex flex-col justify-center max-w-screen-lg w-full">
          <div className="flex flex-row flex-wrap justify-center md:justify-start w-full">
            {" "}
            {models
              .filter((model) => {
                if (
                  searchTerm &&
                  model.name.toLowerCase().includes(searchTerm.toLowerCase())
                ) {
                  return true;
                } else if (searchTerm === "") {
                  return true; // Puedes ajustar esta lógica según tus necesidades
                }
              })
              .slice(0, showAllModels ? models.length : modelsToShow)
              .map((model) => (
                // Aquí debes renderizar cada modelo como un elemento React válido
                <div className="p-2" key={model._id}>
                  <Link to={`/desingautomation/${model._id}`}>
                    <div
                      onClick={() => handleCardClick(model)}
                      className="w-full"
                    >
                      <CardDesign model={model} />
                    </div>
                  </Link>
                </div>
              ))}
          </div>
        </div>

        <div>
          {!showAllModels ? (
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 w-full rounded mt-9"
              onClick={() => setShowAllModels(true)}
            >
              See more
            </button>
          ) : (
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 w-full rounded mt-9"
              onClick={() => setShowAllModels(false)}
            >
              See less
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default DesingAutomation;
