import useAxiosPrivate from "./useAxiosPrivate";
import useAuth from "./useAuth";


//no esta temrinado

const useLogout = () => {
    const { setAuth } = useAuth();
    const axiosPrivate = useAxiosPrivate();

    const logout = async () => {
        setAuth({});
        try {
            const response = await axiosPrivate('auth/logout', {
                withCredentials: true
            });
            console.log(response.data)
        } catch (err) {
            console.error(err);
        }
    }

    return logout;
}

export default useLogout