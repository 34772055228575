import { useState, useEffect } from 'react';
import useAxiosPrivate from './useAxiosPrivate';


//USADO PARA CARGAR LA IMAGEN DE UN ELEMENTO EN RELEVAMIENTO- OPERATE
// NO UTILIZAMOS EL CACHE AQUI

const useSingleImageLoader = (defaultProps) => {
    const [imageUrl, setImageUrl] = useState("");
    const api = useAxiosPrivate();
    const photoUrl = defaultProps.find(
        (obj) => obj.displayName === "ImageURL"
    )?.displayValue

    useEffect(() => {
        const fetchImageUrl = async () => {
            try {
                if (photoUrl) {
                    const response = await api.get('/s3/get-image-url', {
                        params: {
                            folderName: `operate-uploads/${photoUrl.split('/')[0]}/${photoUrl.split('/')[1]}`,
                            originalName: photoUrl.split('/')[2],
                        },
                    });
                    setImageUrl(response.data.url);
                } else {
                    setImageUrl("")
                }
            } catch (error) {
                console.error('Error fetching image URL', error);
            }
        };

        fetchImageUrl();
    }, [photoUrl]);

    return imageUrl;
};

export default useSingleImageLoader;
